.propertyDetails {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  position: relative;
  padding-bottom: 20px;
}

.propertyDetails .wpsbl {
  width: 100%;
  margin: auto;
  padding: 6px 20px;
  display: flex;
  justify-content: right;
}

.propertyDetails .details {
  width: 96%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  padding: 12px 10px;
  row-gap: 10px;
  position: relative;
  background: #ffffff;
  animation: showFromBottom 0.16s ease;
}

@keyframes showFromBottom {
  0% {
    transform: translatey(100%);
  }

  100% {
    transform: translatey(0);
  }
}

.propertyDetails .details .detailsTag {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 45px;
  flex: 50%;
}

.propertyDetails .details .detailsTag .reqInfo {
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: 0.8px;
  color: #797979;
}

.propertyDetails .details .detailsTag input,
.propertyDetails .details .detailsTag .detailsTagStatus {
  padding: 4px 8px;
  font-size: 14px;
  background-color: #f3f3f3;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.11);
  border: none;
  border-radius: 4px;
  min-width: 200px;
  width: 90%;
  line-height: 18px;
  height: 21px;
}

.propertyDetails .details .detailsTag .detailsTagStatus {
  display: flex;
  align-items: center;
}

.propertyDetails .details .detailsTag input:focus {
  outline: 1px solid var(--bg-plus-txt-color);
}

.propertyDetails .details button {
  background-color: var(--bg-plus-txt-color);
  border: none;
  padding: 5px 12px;
  color: var(--txt-color);
  border-radius: 5px;
  opacity: 0.9;
  align-self: flex-end;
}

.propertyDetails .details button:hover {
  opacity: 1;
  transform: scale(0.97);
}

.propertyDetails .boxes {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 25px;
}

.propertyDetails .pbContainer {
  animation: showToUp 0.18s ease;
}

@keyframes showToUp {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

.propertyDetails .eBLine button {
  background-color: var(--bg-plus-txt-color);
  border: none;
  padding: 5px;
  color: var(--txt-color);
  border-radius: 5px;
  cursor: pointer;
  opacity: 0.9;
  position: absolute;
  top: -44px;
  z-index: 800;
  width: 70px;
}

.propertyDetails .eBLine button:first-child {
  right: 25px;
}

.propertyDetails .eBLine button:last-child {
  right: 110px;
}

.propertyDetails .eBLine button:hover {
  opacity: 1;
  transform: scale(0.97);
}

.propertyDetails .detailsBox {
  width: 96%;
  margin: 0 auto;
  padding: 5px 0 0 0;
  position: relative;
}

.propertyDetails .detailsBox input[type="checkbox"] {
  position: absolute;
  top: 13px;
  left: 5px;
  width: 14px;
  height: 14px;
}

.propertyDetails .detailsBox .infoBox {
  padding: 15px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  gap: 10px;
}

.propertyDetails .descriptionBox {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-bottom: 20px;
}

.propertyDetails .detailsBox .snaggingBoxHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: white;
}

.propertyDetails .snaggingButton {
  display: flex;
  align-items: center;
  padding: 30px;
  cursor: pointer;
}

.propertyDetails .detailsBox .infoBox .infoBoxHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px 0 30px;
}

.propertyDetails .detailsBox .infoBox .infoBoxHeader h2 {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  padding: 0;
}

.propertyDetails .share-button {
  border: none;
  background: transparent;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  overflow: hidden;
  cursor: pointer;
}

.propertyDetails .share-button img {
  width: 100%;
  height: 100%;
  background-size: cover;
}

.propertyDetails .descriptionBox textarea {
  resize: none;
  border: none;
  overflow-y: auto;
  font-family: inherit;
  font-size: inherit;
}

.propertyDetails .descriptionBox .description-text {
  background-color: var(--fields-color);
  /* box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.11); */
  border-radius: 5px;
  min-height: 105px;
  /* max-height: 120px; */
  overflow-y: auto;
  width: 100%;
  padding: 8px 14px;
  font-size: 14px;
}

.propertyDetails .descriptionBox textarea:focus {
  outline: 1px solid var(--bg-plus-txt-color);
}

.propertyDetails .descriptionBox pre {
  font-size: 13px;
  line-height: 19px;
  text-transform: capitalize;
  width: 100%;
  min-height: 105px;
  /* max-height: 120px; */
  background-color: var(--fields-color);
  color: #797979;
  align-self: flex-start;
  padding: 8px 10px;
  overflow-y: auto;
  word-break: break-all;
  white-space: break-spaces;
}

.propertyDetails .OwnershipDocsCardsBox,
.propertyDetails .photos .photosTile,
.propertyDetails .floorPlans .floorPlansTile,
.propertyDetails .TenancyDetails .TenancyDetailsTile,
.propertyDetails .snagging .snaggingTile {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  gap: 6px;
  padding: 5px 10px 5px;
  overflow-x: auto;
  min-height: 100px;
}

.propertyDetails .OwnershipDocsCardsBox.scrbrd::-webkit-scrollbar,
.propertyDetails .photos .photosTile.scrbrd::-webkit-scrollbar,
.propertyDetails .floorPlans .floorPlansTile.scrbrd::-webkit-scrollbar,
.propertyDetails .TenancyDetails .TenancyDetailsTile.scrbrd::-webkit-scrollbar,
.propertyDetails .snagging .snaggingTile.scrbrd::-webkit-scrollbar {
  width: 12px;
}

.propertyDetails .OwnershipDocsCardsBox.scrbrd::-webkit-scrollbar-track,
.propertyDetails .photos .photosTile.scrbrd::-webkit-scrollbar-track,
.propertyDetails .floorPlans .floorPlansTile.scrbrd::-webkit-scrollbar-track,
.propertyDetails
  .TenancyDetails
  .TenancyDetailsTile.scrbrd::-webkit-scrollbar-track,
.propertyDetails .snagging .snaggingTile.scrbrd::-webkit-scrollbar-track {
  background-color: transparent;
}

.propertyDetails .OwnershipDocsCardsBox.scrbrd::-webkit-scrollbar-thumb,
.propertyDetails .photos .photosTile.scrbrd::-webkit-scrollbar-thumb,
.propertyDetails .floorPlans .floorPlansTile.scrbrd::-webkit-scrollbar-thumb,
.propertyDetails
  .TenancyDetails
  .TenancyDetailsTile.scrbrd::-webkit-scrollbar-thumb,
.propertyDetails .snagging .snaggingTile.scrbrd::-webkit-scrollbar-thumb {
  background-color: #a5a5a5;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.propertyDetails .OwnershipDocsCardsBox.scrbrd::-webkit-scrollbar-thumb:hover,
.propertyDetails .photos .photosTile.scrbrd::-webkit-scrollbar-thumb:hover,
.propertyDetails
  .floorPlans
  .floorPlansTile.scrbrd::-webkit-scrollbar-thumb:hover,
.propertyDetails
  .TenancyDetails
  .TenancyDetailsTile.scrbrd::-webkit-scrollbar-thumb:hover,
.propertyDetails .snagging .snaggingTile.scrbrd::-webkit-scrollbar-thumb:hover {
  background-color: #838383;
}

.propertyDetails .OwnershipDocsCardsBox .OwnershipDocsCardAdd,
.propertyDetails .photos .photosTile .photosCard,
.propertyDetails .floorPlans .floorPlansTile .floorPlansTileCard,
.propertyDetails .TenancyDetails .TenancyDetailsTile .TenancyDetailsTileCard,
.propertyDetails .snagging .snaggingTile .snaggingTileCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  flex: 0 0 115px;
  height: 125px;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding-bottom: 5px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.propertyDetails .photos .photosTile .photosCard,
.propertyDetails .snagging .snaggingTile .snaggingTileCard {
  padding-bottom: 0 !important;
}

.propertyDetails .OwnershipDocsCardsBox .OwnershipDocsCardAdd .docsPic,
.propertyDetails
  .floorPlans
  .floorPlansTile
  .floorPlansTileCard
  .floorPlansTileCardPic,
.propertyDetails
  .TenancyDetails
  .TenancyDetailsTile
  .TenancyDetailsTileCard
  .TenancyDetailsTileCardPic {
  width: 115px;
  min-height: 100px;
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
  aspect-ratio: 4/3;
  background-color: #dcdcdc;
}

.propertyDetails
  .OwnershipDocsCardsBox
  .OwnershipDocsCardAdd
  .docsPic
  .react-pdf__Page__canvas,
.propertyDetails
  .floorPlans
  .floorPlansTile
  .floorPlansTileCard
  .floorPlansTileCardPic
  .react-pdf__Page__canvas,
.propertyDetails
  .TenancyDetails
  .TenancyDetailsTile
  .TenancyDetailsTileCard
  .TenancyDetailsTileCardPic
  .react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

.propertyDetails .photos .photosTile .photoTilePic,
.propertyDetails
  .snagging
  .snaggingTile
  .snaggingTileCard
  .snaggingTileCardPic {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #dcdcdc;
}

.propertyDetails
  .OwnershipDocsCardsBox
  .OwnershipDocsCardAdd
  .docsPic
  .rpv-core__inner-pages,
.propertyDetails
  .floorPlans
  .floorPlansTile
  .floorPlansTileCard
  .floorPlansTileCardPic
  .rpv-core__inner-pages,
.propertyDetails
  .TenancyDetails
  .TenancyDetailsTile
  .TenancyDetailsTileCard
  .TenancyDetailsTileCardPic
  .rpv-core__inner-pages {
  overflow: hidden;
}

.propertyDetails
  .OwnershipDocsCardsBox
  .OwnershipDocsCardAdd
  .docsPic
  .rpv-core__page-layer--single,
.propertyDetails
  .floorPlans
  .floorPlansTile
  .floorPlansTileCard
  .floorPlansTileCardPic
  .rpv-core__page-layer--single,
.propertyDetails
  .TenancyDetails
  .TenancyDetailsTile
  .TenancyDetailsTileCard
  .TenancyDetailsTileCardPic
  .rpv-core__page-layer--single {
  margin: 20px auto 0 !important;
  transform: scale(1.5) !important;
}

.propertyDetails .OwnershipDocsCardsBox .OwnershipDocsCardAdd .docsPic img,
.propertyDetails .photos .photosTile img,
.propertyDetails
  .floorPlans
  .floorPlansTile
  .floorPlansTileCard
  .floorPlansTileCardPic
  img,
.propertyDetails
  .TenancyDetails
  .TenancyDetailsTile
  .TenancyDetailsTileCard
  .TenancyDetailsTileCardPic
  img,
.propertyDetails
  .snagging
  .snaggingTile
  .snaggingTileCard
  .snaggingTileCardPic
  img {
  width: 100%;
  height: 100%;
  background-size: cover;
  object-fit: cover !important;
  aspect-ratio: 4/3;
}

.propertyDetails .OwnershipDocsCardsBox .OwnershipDocsCardAdd .span,
.propertyDetails .floorPlans .floorPlansTile .floorPlansTileCard .span,
.propertyDetails
  .TenancyDetails
  .TenancyDetailsTile
  .TenancyDetailsTileCard
  .span {
  font-weight: 400;
  font-size: 13px;
  line-height: normal;
  flex: 1;
  width: 100%;
  height: 20px;
  text-align: center;
  overflow: hidden;
  padding: 0 5px 4px;
  word-break: break-all;
}

.propertyDetails .OwnershipDocsCardsBox .OwnershipDocsCardAdd::after,
.propertyDetails .photos .photosTile .photosCard::after,
.propertyDetails .floorPlans .floorPlansTile .floorPlansTileCard::after,
.propertyDetails
  .TenancyDetails
  .TenancyDetailsTile
  .TenancyDetailsTileCard::after,
.propertyDetails .snagging .snaggingTile .snaggingTileCard::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  opacity: 0;
  transition: all 0.25s ease;
  border-radius: 10px;
}

.propertyDetails .OwnershipDocsCardsBox .OwnershipDocsCardAdd:hover::after,
.propertyDetails .photos .photosTile .photosCard:hover::after,
.propertyDetails .floorPlans .floorPlansTile .floorPlansTileCard:hover::after,
.propertyDetails
  .TenancyDetails
  .TenancyDetailsTile
  .TenancyDetailsTileCard:hover::after,
.propertyDetails .snagging .snaggingTile .snaggingTileCard:hover::after {
  opacity: 1;
}

.propertyDetails .OwnershipDocsCardsBox .OwnershipDocsCardAdd:hover,
.propertyDetails .photos .photosTile .photosCard:hover,
.propertyDetails .floorPlans .floorPlansTile .floorPlansTileCard:hover,
.propertyDetails
  .TenancyDetails
  .TenancyDetailsTile
  .TenancyDetailsTileCard:hover,
.propertyDetails .snagging .snaggingTile .snaggingTileCard:hover {
  transform: scale(0.98);
}

.propertyDetails .lastbox {
  width: 96%;
  max-width: 50rem;
  display: flex;
  flex-direction: column;
  margin: 8px auto 0;
}

.propertyDetails .lastbox form {
  background: #f0f0f0;
  border: 1px solid var(--bg-plus-txt-color);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.propertyDetails form .textArea {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
}

.propertyDetails form .textArea label {
  display: block;
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  text-transform: capitalize;
  color: var(--bg-plus-txt-color);
  padding: 8px 8px 0 8px;
}

.propertyDetails form .textArea textarea {
  border: none;
  resize: none;
  outline: none;
  font-size: inherit;
  font-family: inherit;
}

.propertyDetails form .textArea .notes-textarea {
  width: 100%;
  height: 120px;
  padding: 0 16px 8px;
  background: transparent;
  font-size: clamp(10px, 2vw, 15px);
}

.propertyDetails .reminderBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background-color: var(--fields-color);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.49);
  border-radius: 10px;
  padding: 16px 20px;
  margin: 15px auto 0 auto;
}

.propertyDetails .reminderBox.customSyle {
  pointer-events: none;
}

.propertyDetails .reminderBox span {
  font-weight: 700;
  font-size: 17px;
  line-height: 25px;
  text-transform: capitalize;
  color: var(--bg-plus-txt-color);
}

.propertyDetails .reminderBox button {
  cursor: pointer;
  border: none;
  width: 24px;
  height: 24px;
  background: none;
  align-self: center;
}

.propertyDetails .reminderBox button svg {
  width: 24px;
  height: 24px;
}

.addDocumentBoxCover {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.addDocumentBoxCover .AddDocumentBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 10px; */
  max-width: 38rem;
  width: 38rem;
  min-height: 580px;
  height: 100%;
  z-index: 12;
  background-color: #ffffff;
  padding: 0px 12px 16px;
  position: relative;
}

.addDocumentBoxCover .AddDocumentBox .hLine {
  width: 100%;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0px;
  z-index: 222;
  padding: 5px 0;
  background-color: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}

.addDocumentBoxCover .AddDocumentBox .hLine span {
  color: var(--bg-plus-txt-color);
  font-size: clamp(14px, 3vw, 17px);
}

.addDocumentBoxCover .AddDocumentBox .hLine .closeAddDocumentBOx {
  position: absolute;
  right: 15px;
  top: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 23px;
  height: 23px;
  border: 1px solid var(--bg-plus-txt-color);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.18s all;
}

.addDocumentBoxCover .AddDocumentBox .hLine .closeAddDocumentBOx:hover,
.addDocumentBoxCover .AddDocumentBox .addDocumentsButtons button:hover {
  background-color: var(--bg-plus-txt-color);
  color: var(--txt-color);
}

.addDocumentBoxCover
  .AddDocumentBox
  .hLine
  .closeAddDocumentBOx:hover
  svg
  path {
  fill: var(--txt-color);
}

.addDocumentBoxCover .AddDocumentBox .hLine .closeAddDocumentBOx svg {
  width: 22px;
  height: 22px;
}

.addDocumentBoxCover .AddDocumentBox .hLine .closeAddDocumentBOx svg path {
  fill: var(--bg-plus-txt-color);
}

.addDocumentBoxCover .AddDocumentBox textarea {
  resize: none;
  border: none;
  font-family: inherit;
}

.addDocumentBoxCover .AddDocumentBox .cardDetailTextArea,
.addDocumentBoxCover .AddDocumentBox textarea {
  outline: 1px solid var(--bg-plus-txt-color);
  width: 100%;
  min-height: clamp(70px, 3vw, 175px);
  height: clamp(70px, 3vw, 175px);
  overflow-y: auto;
  font-family: inherit;
  padding: 4px 8px;
  font-size: 12px;
  border-radius: 6px;
  margin: 8px 0;
}

.addDocumentBoxCover .AddDocumentBox .rpv-core__viewer {
  height: 200px !important;
}

.addDocumentBoxCover .AddDocumentBox .addDocumentPlusBox {
  width: 100%;
  min-height: 400px;
  height: 100%;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-bottom: 10px;
  overflow-y: auto;
}

.addDocumentBoxCover .addDocumentPlusBoxHover {
  left: 47%;
  top: 50%;
  height: 50px;
  position: absolute;
  margin: auto;
  display: flex;
  align-items: center;
  padding-bottom: 19px;
  justify-content: center;
  font-size: clamp(60px, 10vw, 80px);
  color: var(--bg-plus-txt-color);
  transition: 0.1s all;
}

.addDocumentBoxCover
  .AddDocumentBox
  .addDocumentPlusBox
  .addDocumentPlusBoxImage {
  min-width: 100%;
  overflow: hidden;
  width: 100%;
  height: 100%;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.4));
  padding-bottom: 5px;
}

.addDocumentBoxCover .AddDocumentBox .addDocumentPlusBox img {
  width: 100%;
  height: 100%;
  background-size: cover;
  border-radius: 6px;
}

.addDocumentBoxCover .AddDocumentBox .addDocumentsButtons {
  display: flex;
  gap: 8px;
}

.addDocumentBoxCover .AddDocumentBox .addDocumentsButtons button {
  background: none;
  padding: 5px 15px;
  border-radius: 6px;
  border: 1px solid var(--bg-plus-txt-color);
  color: var(--bg-plus-txt-color);
  transition: 0.18s all;
}

.propertyDetails .infoBox .AddBox {
  cursor: pointer;
  padding: 3px 8px;
  border-radius: 5px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  background: none;
  border: 1px solid var(--bg-plus-txt-color);
  color: var(--bg-plus-txt-color);
  transition: 0.12s;
}

.propertyDetails .infoBox .AddBox:hover {
  background-color: var(--bg-plus-txt-color);
  color: var(--txt-color);
}

.addDocumentBoxCover .AddDocumentBox .imagesSection {
  width: 100%;
  min-height: 310px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow-y: auto;
  gap: 10px;
  padding: 5px;
}

.addDocumentBoxCover .AddDocumentBox .ppabl {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: sticky;
  top: 43px;
  padding: 5px 0;
  z-index: 222;
  background-color: #ffffff;
}

.addDocumentBoxCover .AddDocumentBox label {
  overflow: hidden;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: var(--component-bg-color);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  cursor: pointer;
}

.addDocumentBoxCover .AddDocumentBox label .plusBox {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  font-size: 13px;
  color: var(--bg-plus-txt-color);
  padding: 3px 10px;
  cursor: pointer;
  position: relative;
  z-index: 4;
}

.addDocumentBoxCover .AddDocumentBox label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
}

.addDocumentBoxCover .AddDocumentBox .imagesSection .PhotosImage {
  width: 106px;
  height: 106px;
  background: #e6e6e6;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.addDocumentBoxCover .AddDocumentBox .imagesSection .PhotosImage::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: pointer;
}

.addDocumentBoxCover .AddDocumentBox .imagesSection .PhotosImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.addDocumentBoxCover .AddDocumentBox .imagesSection .PhotosImage .deleteSign {
  position: absolute;
  top: 2px;
  right: 2px;
  padding: 2px;
  width: 22px;
  height: 22px;
  background-color: rgba(0, 0, 0, 0.6);
  background-color: var(--bg-plus-txt-color);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 210;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  cursor: pointer;
}

.addDocumentBoxCover
  .AddDocumentBox
  .imagesSection
  .PhotosImage
  .deleteSign
  svg {
  width: 22px;
  height: 22px;
  background: none;
}

.addDocumentBoxCover .AddDocumentBox .imagesSection .deleteSign svg path {
  fill: #ffffff;
}

.propertyDetails .modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
}

.propertyDetails .modalContent {
  position: relative;
  background-color: #fff;
  width: 550px;
  height: 550px;
}

.propertyDetails .modalContent img {
  border-radius: 0;
  width: 100%;
  height: 100%;
}

.propertyDetails .closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  color: #ffffff;
  cursor: pointer;
}

.propertyDetails .image-slider {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1250;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 2000;
}

.propertyDetails .image-gallery {
  max-width: 80% !important;
  margin: 0 auto !important;
}

/* Style for individual gallery items */
.propertyDetails .image-gallery-content img {
  max-width: 100% !important;
  /* height: 80% !important; */
}

.propertyDetails .image-gallery-slide .image-gallery-image {
  /* object-fit: cover !important; */
  height: 100% !important;
}

.propertyDetails .image-gallery-thumbnail .image-gallery-thumbnail-image {
  height: 70px !important;
  object-fit: cover;
}

.propertyDetails .close-slider {
  position: absolute;
  top: 10px;
  right: 25px;
  cursor: pointer;
  background-color: var(--bg-plus-txt-color);
  color: var(--txt-color);
  font-size: 13px;
  border-radius: 5px;
  padding: 5px 12px;
}

.propertyDetails .forPdfView {
  width: 100%;
  height: 100%;
}

.propertyDetails .react-pdf__Document,
.propertyDetails .react-pdf__Page,
.propertyDetails .react-pdf__Page .pdf-page {
  width: 100%;
  height: 100%;
}

.propertyDetails .pdfView {
  overflow-y: auto !important;
}

.propertyDetails .pdf-page {
  margin-bottom: 10px I !important;
}

.propertyDetails .react-pdf__Page__textContent,
.propertyDetails .react-pdf__Page__textContent.textLayer,
.propertyDetails .react-pdf__Page__annotations,
.propertyDetails .react-pdf__Page__annotations.annotationLayer {
  display: none !important;
}

.propertyDetails .react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
}

.propertyDetails .react-transform-wrapper,
.propertyDetails .transform-component-module_wrapper__SPB86,
.propertyDetails .react-transform-component,
.propertyDetails .transform-component-module_content__FBWxo {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 1050px) {
  .propertyDetails .OwnershipDocsCardsBox,
  .propertyDetails .photos .photosTile,
  .propertyDetails .floorPlans .floorPlansTile,
  .propertyDetails .TenancyDetails .TenancyDetailsTile,
  .propertyDetails .snagging .snaggingTile {
    min-height: 95px;
  }
}

@media only screen and (max-width: 768px) {
  .propertyDetails .wpsbl {
    position: fixed;
    right: 10px;
    top: 50px;
    z-index: 710;
    width: auto;
    padding: 5px;
  }
  .propertyDetails .eBLine button {
    position: fixed;
    top: 6px;
  }
  .propertyDetails .details {
    padding: 7px;
  }
  .propertyDetails .details .detailsTag {
    max-width: 90%;
    height: auto;
    gap: 5px;
    margin: 0 auto 5px;
  }
  .propertyDetails .details .detailsTag input,
  .propertyDetails .details .detailsTag .detailsTagStatus {
    font-size: 13px;
  }
  .propertyDetails .details button {
    padding: 5px 12px;
  }
  .propertyDetails .boxes .eBLine button {
    padding: 5px 12px;
    top: 8px;
  }
  .propertyDetails .detailsBox {
    margin: 5px auto;
    padding: 5px 0;
  }
  .propertyDetails .detailsBox input[type="checkbox"] {
    top: 12px;
    width: 12px;
    height: 12px;
  }
  /* .propertyDetails .detailsBox .infoBox {
    width: 94%;
    gap: 10px;
  } */
  .propertyDetails .detailsBox .infoBox .infoBoxHeader h2 {
    font-size: 15px;
  }
  .propertyDetails .share-button {
    width: 16px;
    height: 16px;
  }
  .propertyDetails .descriptionBox .description-text,
  .propertyDetails .descriptionBox p {
    height: 105px;
    padding: 5px 8px;
    font-size: 13px;
  }
  .propertyDetails .OwnershipDocsCardsBox,
  .propertyDetails .photos .photosTile,
  .propertyDetails .floorPlans .floorPlansTile,
  .propertyDetails .TenancyDetails .TenancyDetailsTile,
  .propertyDetails .snagging .snaggingTile {
    min-height: 105px;
  }
  .propertyDetails .descriptionBox p {
    font-size: 13.5px;
  }
  .propertyDetails .descriptionBox .description-text:focus {
    outline: 1px solid var(--bg-plus-txt-color);
  }
  .propertyDetails .OwnershipDocsCardsBox .OwnershipDocsCardAdd,
  .propertyDetails .photos .photosTile .photosCard,
  .propertyDetails .floorPlans .floorPlansTile .floorPlansTileCard,
  .propertyDetails .TenancyDetails .TenancyDetailsTile .TenancyDetailsTileCard,
  .propertyDetails .snagging .snaggingTile .snaggingTileCard {
    flex: 0 0 102px;
    height: 112px;
  }
  .propertyDetails .OwnershipDocsCardsBox .OwnershipDocsCardAdd .docsPic,
  .propertyDetails
    .floorPlans
    .floorPlansTile
    .floorPlansTileCard
    .floorPlansTileCardPic,
  .propertyDetails
    .TenancyDetails
    .TenancyDetailsTile
    .TenancyDetailsTileCard
    .TenancyDetailsTileCardPic {
    width: 102px;
    min-height: 90px;
  }
  .propertyDetails .OwnershipDocsCardsBox .OwnershipDocsCardAdd .span,
  .propertyDetails .floorPlans .floorPlansTile .floorPlansTileCard .span,
  .propertyDetails
    .TenancyDetails
    .TenancyDetailsTile
    .TenancyDetailsTileCard
    .span {
    font-size: 12.2px;
  }
  .propertyDetails form .textArea label {
    font-size: 18px;
    line-height: normal;
    padding: 6px 8px 0 8px;
  }
  .propertyDetails form .textArea .notes-textarea {
    height: 100px;
    padding: 0px 12px 8px;
  }
  .propertyDetails .reminderBox {
    padding: 15px;
    margin: 12px auto 0 auto;
  }
  .propertyDetails .reminderBox span {
    font-size: 18px;
    line-height: normal;
  }
  .addDocumentBoxCover {
    background-color: #797979;
    height: 100%;
    overflow-y: auto;
    align-items: flex-start;
    padding: 0;
  }
  .addDocumentBoxCover .AddDocumentBox {
    width: 100%;
    height: 100%;
    min-height: 580px;
    border-radius: 0;
  }
  .addDocumentBoxCover .AddDocumentBox .hLine .closeAddDocumentBOx {
    width: 20px;
    height: 20px;
    top: 12px;
  }
  .addDocumentBoxCover .AddDocumentBox .hLine .closeAddDocumentBOx svg {
    width: 20px;
    height: 20px;
  }
  .addDocumentBoxCover .AddDocumentBox .addDocumentPlusBox {
    /* width: 70%; */
    height: 70%;
  }
  .addDocumentBoxCover
    .AddDocumentBox
    .addDocumentPlusBox
    .addDocumentPlusBoxImage {
    width: 100%;
    /* min-height: 600px;
    height: 600px; */
  }
  .propertyDetails .reminderBox {
    padding: 15px;
    margin: 12px auto 0 auto;
  }
  .propertyDetails .reminderBox span {
    font-size: 18px;
    line-height: normal;
  }
  .propertyDetails .modalContent {
    width: 100%;
    height: 70%;
  }
  .addDocumentBoxCover .AddDocumentBox .imagesSection .PhotosImage {
    width: 90px;
    height: 90px;
  }
  .propertyDetails .image-gallery {
    max-width: 100% !important;
  }
  .propertyDetails .image-gallery-slide {
    aspect-ratio: 4/3;
  }
}

@media only screen and (max-width: 540px) {
  .propertyDetails .details {
    gap: 0;
    padding: 5px;
  }
  .propertyDetails .details .detailsTag {
    flex: 100%;
    max-width: 96%;
    gap: 0px;
    border-bottom: 1px solid #ebebeb;
  }
  .propertyDetails .details .detailsTag .reqInfo {
    font-size: 13.2px;
    line-height: 18px;
    letter-spacing: 0.3px;
  }
  .propertyDetails .details .detailsTag input,
  .propertyDetails .details .detailsTag .detailsTagStatus {
    padding: 0;
    font-size: 12px;
    background: none;
    box-shadow: none;
    border-radius: 0;
    width: 100%;
  }
  .propertyDetails .details .detailsTag input:focus {
    outline: none;
  }
  .propertyDetails .details button {
    font-size: 12px;
  }
  .propertyDetails .boxes .eBLine button {
    font-size: 12px;
  }
  .propertyDetails .detailsBox .infoBox {
    width: 100%;
  }
  .propertyDetails .detailsBox input[type="checkbox"] {
    left: 8px;
  }
  .propertyDetails .detailsBox .infoBox .infoBoxHeader {
    padding: 0 15px 0 20px;
  }
  .propertyDetails .detailsBox .infoBox .infoBoxHeader h2 {
    font-size: 16px;
    margin-left: 8px;
  }
  .propertyDetails .descriptionBox .description-text,
  .propertyDetails .descriptionBox p {
    height: 88px;
    padding: 5px;
    font-size: 11px;
  }
  .propertyDetails .OwnershipDocsCardsBox,
  .propertyDetails .photos .photosTile,
  .propertyDetails .floorPlans .floorPlansTile,
  .propertyDetails .TenancyDetails .TenancyDetailsTile,
  .propertyDetails .snagging .snaggingTile {
    min-height: 85px;
  }

  .propertyDetails .OwnershipDocsCardsBox .OwnershipDocsCardAdd,
  .propertyDetails .photos .photosTile .photosCard,
  .propertyDetails .floorPlans .floorPlansTile .floorPlansTileCard,
  .propertyDetails .TenancyDetails .TenancyDetailsTile .TenancyDetailsTileCard,
  .propertyDetails .snagging .snaggingTile .snaggingTileCard {
    flex: 0 0 90px;
    height: 100px;
  }
  .propertyDetails .OwnershipDocsCardsBox .OwnershipDocsCardAdd .docsPic,
  .propertyDetails
    .floorPlans
    .floorPlansTile
    .floorPlansTileCard
    .floorPlansTileCardPic,
  .propertyDetails
    .TenancyDetails
    .TenancyDetailsTile
    .TenancyDetailsTileCard
    .TenancyDetailsTileCardPic {
    width: 90px;
    min-height: 80px;
  }
  .propertyDetails .OwnershipDocsCardsBox .OwnershipDocsCardAdd .span,
  .propertyDetails .floorPlans .floorPlansTile .floorPlansTileCard .span,
  .propertyDetails
    .TenancyDetails
    .TenancyDetailsTile
    .TenancyDetailsTileCard
    .span {
    font-size: 10px;
    padding: 2px 5px 4px;
  }
  .propertyDetails form .textArea label {
    font-size: 16px;
    padding: 5px 5px 0 5px;
  }
  .propertyDetails form .textArea .notes-textarea {
    height: 80px;
    padding: 0px 10px 7px;
  }
  .propertyDetails .reminderBox {
    padding: 10px;
    margin: 8px auto 0 auto;
  }
  .propertyDetails .reminderBox span {
    font-size: 16px;
  }
  .propertyDetails .reminderBox button {
    width: 20px;
    height: 20px;
  }
  .propertyDetails .reminderBox button svg {
    width: 20px;
    height: 20px;
  }
  .propertyDetails .snaggingButton {
    padding: 30px 20px;
  }
  .addDocumentBoxCover .AddDocumentBox .addDocumentPlusBox {
    width: 95%;
  }
  .addDocumentBoxCover .AddDocumentBox .addDocumentPlusBox {
    min-height: 305px;
  }
  .addDocumentBoxCover
    .AddDocumentBox
    .addDocumentPlusBox
    .addDocumentPlusBoxImage {
    min-height: unset;
    height: 100%;
  }
  .propertyDetails .reminderBox {
    padding: 12px 10px;
    margin: 14px auto 0 auto;
  }
  .propertyDetails .image-gallery-slide .image-gallery-image {
    height: auto !important;
  }
}

@media only screen and (max-width: 440px) {
  .propertyDetails .detailsBox .infoBox .infoBoxHeader h2 {
    font-size: 14px;
  }
  .propertyDetails form .textArea label {
    font-size: 14px;
  }

  .propertyDetails .reminderBox span {
    font-size: 14px;
  }
  .AddDocumentBox input,
  .AddDocumentBox button {
    margin-bottom: 5px;
    padding: 5px;
    font-size: 11.5px;
  }
  .propertyDetails .boxes .eBLine button:first-child {
    right: 18px;
  }
  .propertyDetails .boxes .eBLine button:last-child {
    right: 100px;
  }
}

@media only screen and (max-width: 340px) {
  .AddDocumentBox {
    width: 100%;
    left: 0;
  }
}
