.notification-item {
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s;
  position: relative;
  padding: 0;
}

.notification-item .notification-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 12px;
}

.notification-content .profile-pic {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #dcdcdc;
}

.notification-content .initials {
  width: 40px;
  min-width: 40px;
  height: 40px;
  background-color: #dcdcdc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
}

.notification-content .noti-image {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #dcdcdc;
}

.notification-content .noti-image svg {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #dcdcdc;
}

.notification-content .noti-image svg path {
  fill: var(--bg-plus-txt-color);
}

.notification-content .profile-pic img {
  width: 100%;
  height: 100%;
  background-size: cover;
  object-fit: cover;
}

.notification-content .notification-details {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  overflow: hidden;
  width: 100%;
}

.notification-content .notification-details .nameOnTile {
  font-weight: 500;
  font-size: 13px;
  color: #000000;
  white-space: nowrap;
}

.notification-content .notification-title {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  color: #696969;
}

.notification-content .noti-receivedTime {
  font-size: 11px;
  min-width: 50px;
  width: 50px;
  height: 100%;
  text-align: center;
}

@media only screen and (max-width: 540px) {
  .notification-item .notification-content {
    padding: 8px 5px;
    gap: 6px;
  }
  .notification-content .notification-details strong {
    font-size: 12px;
  }
  .notification-content .notification-title {
    font-size: 11px;
    line-height: normal;
  }
  .notification-content .profile-pic,
  .notification-content .initials,
  .notification-content .noti-image,
  .notification-content .noti-image svg {
    width: 35px;
    min-width: 35px;
    height: 35px;
  }
  .notification-content .initials {
    font-size: 15px;
  }
  .notification-content .noti-receivedTime {
    font-size: 9.5px;
  }
}
