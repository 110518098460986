.incoming-notifications {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  gap: 5px;
  width: 100%;
  height: 100%;
}
.incoming-notifications span {
  font-weight: 500;
  font-size: 18px;
  line-height: normal;
  text-align: center;
  text-transform: capitalize;
  color: var(--bg-plus-txt-color);
}

.incoming-notifications ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  list-style: none;
}

.incoming-notifications ul li {
  width: 100%;
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}

.incoming-notifications ul li:hover {
  background-color: #d4d4d4;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1300;
}

.modal-content {
  background: #ffffff;
  padding: 15px 12px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 440px;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.modal-content .notification {
  width: 100%;
}

.modal-content .firstLine {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  width: 100%;
}

.modal-content .firstLine .profile-pic {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #dcdcdc;
}

.incoming-notifications .initials,
.modal-content .firstLine .noti-image {
  width: 40px;
  min-width: 40px;
  height: 40px;
  background-color: #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.modal-content .firstLine .noti-image svg {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
}

.modal-content .firstLine .noti-image svg path {
  fill: var(--bg-plus-txt-color);
}

.modal-content .firstLine .profile-pic img {
  width: 100%;
  height: 100%;
  background-size: cover;
  /* border-radius: 50%; */
}

.modal-content .firstLine .name {
  width: 92%;
  font-size: 14px;
  max-height: 40px;
  overflow: hidden;
}

.modal-content .body {
  padding: 8px;
  letter-spacing: 0.2px;
  font-size: 14px;
}

.modal-content .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 16px;
  color: var(--bg-plus-txt-color);
  width: 22px;
  height: 22px;
  border-radius: 3px;
}

.modal-content .close-button svg {
  width: 22px;
  height: 22px;
}

.modal-content .close-button:hover {
  outline: 2px solid var(--bg-plus-txt-color);
}

@media only screen and (max-width: 1000px) {
  .responsiveDisplayN {
    display: none;
  }
}

@media only screen and (max-width: 540px) {
  .modal-content {
    width: 96%;
    margin-bottom: 5rem;
  }
  .incoming-notifications .initials,
  .modal-content .firstLine .noti-image,
  .modal-content .firstLine .noti-image svg {
    width: 35px;
    min-width: 35px;
    height: 35px;
  }
}
