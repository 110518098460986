/* Place Autocomplete Component */
.search-autocomplete {
  position: relative;
  z-index: 800;
  margin: 10px auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 94%;
  /* top: 50px; */
}

.search-autocomplete .inputBar {
  position: relative;
}

.search-autocomplete .inputBar .searchIcon {
  position: absolute;
  z-index: 2000;
  top: 9px;
  left: 8px;
}

.search-autocomplete .inputBar .searchIcon svg {
  width: 13px;
  height: 13px;
  position: absolute;
}

.search-autocomplete input {
  width: 100%;
  padding: 10px 15px 10px 25px;
  background-color: #ffffff;
  border-radius: 4px;
  outline: none;
  font-size: 0.8rem;
}

.search-autocomplete ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 100%;
}

.search-autocomplete li {
  padding: 10px;
  cursor: pointer;
  font-size: 1rem;
}

.search-autocomplete li:hover {
  background-color: #f5f5f5;
}

.pac-container span {
  font-size: 12px !important;
}
.pac-container::after {
  display: none !important;
}

@media only screen and (max-width: 768px) {
  .search-autocomplete {
    position: fixed;
    top: 45px;
  }
  .search-autocomplete .inputBar .searchIcon {
    top: 10px;
  }
}
