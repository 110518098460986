.sharedViewOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  gap: 6px;
  overflow: hidden;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.2);
  z-index: 11111;
}

.propertySharedView {
  width: 60rem;
  max-width: 60rem;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 12px; */
  overflow: hidden;
  /* overflow-y: auto; */
  background-color: var(--component-bg-color);
  z-index: 11111;
}

.propertySharedView .headerLine {
  width: 100%;
  min-height: 40px;
  background-color: var(--component-bg-color);
  display: flex;
  align-items: center;
  padding: 7px 10px;
  box-shadow: 0 0 5px rgb(0, 0, 0, 0.3);
  /* margin-bottom: 6px; */
  position: sticky;
  top: 0;
  z-index: 10;
}

.propertySharedView .headerLine .movebackc {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-radius: 5px;
  width: 18px;
  height: 18px;
  position: absolute;
}

.propertySharedView .headerLine .movebackc:hover {
  outline: 2px solid var(--bg-plus-txt-color);
}

.propertySharedView .headerLine span {
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  text-transform: capitalize;
  color: var(--bg-plus-txt-color);
  margin: auto;
}

.propertySharedView .infoBox {
  /* padding: 8px; */
  width: 100%;
  padding: 10px 0 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow-y: auto;
}

.propertySharedView .descriptionBox {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0 auto;
  width: 96%;
}

.propertySharedView .snaggingBoxHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
}

.propertySharedView .snaggingButton {
  display: flex;
  align-items: center;
  padding: 30px;
  cursor: pointer;
}

.propertySharedView .infoBox .infoBoxHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 15px;
}

.propertySharedView .infoBox .infoBoxHeader h2 {
  font-weight: 500;
  font-size: 14px;
  padding: 0;
}

.propertySharedView .descriptionBox .description-text {
  background-color: var(--fields-color);
  /* box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.11); */
  border-radius: 5px;
  min-height: 105px;
  /* max-height: 120px; */
  overflow-y: auto;
  width: 100%;
  padding: 8px 14px;
  font-size: 14px;
}

.propertySharedView .descriptionBox pre {
  font-size: 13px;
  line-height: 19px;
  text-transform: capitalize;
  width: 100%;
  min-height: 105px;
  /* max-height: 120px; */
  background-color: var(--fields-color);
  color: #797979;
  align-self: flex-start;
  padding: 8px 10px;
  overflow-y: auto;
  word-break: break-all;
  white-space: break-spaces;
}

.propertySharedView .OwnershipDocsCardsBox,
.propertySharedView .floorPlans .floorPlansTile,
.propertySharedView .TenancyDetails .TenancyDetailsTile,
.propertySharedView .snagging .snaggingTile {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 8px;
}

.propertySharedView .photos .photosTile {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 5px;
  background-color: #ffffff;
  /* justify-content: center; */
  gap: 10px;
  min-height: 80px;
}

.propertySharedView .OwnershipDocsCardsBox .OwnershipDocsCardAdd,
.propertySharedView .floorPlans .floorPlansTile .floorPlansTileCard,
.propertySharedView .TenancyDetails .TenancyDetailsTile .TenancyDetailsTileCard,
.propertySharedView .snagging .snaggingTile .snaggingTileCard {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 10px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  width: 60%;
  min-height: 500px;
  max-height: 600px;
  background-color: #ffffff;
}

.propertySharedView
  .OwnershipDocsCardsBox
  .OwnershipDocsCardAdd
  .downloadPDFBtn,
.propertySharedView
  .floorPlans
  .floorPlansTile
  .floorPlansTileCard
  .downloadPDFBtn,
.propertySharedView
  .TenancyDetails
  .TenancyDetailsTile
  .TenancyDetailsTileCard
  .downloadPDFBtn {
  padding: 5px 12px;
  font-size: 12px;
  align-self: flex-end;
  border-radius: 5px;
  cursor: pointer;
}

.propertySharedView .photos .photosTile .photosCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  flex: 0 0 95px;
  height: 105px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.propertySharedView .OwnershipDocsCardsBox .OwnershipDocsCardAdd .docsPic,
.propertySharedView
  .floorPlans
  .floorPlansTile
  .floorPlansTileCard
  .floorPlansTileCardPic,
.propertySharedView
  .TenancyDetails
  .TenancyDetailsTile
  .TenancyDetailsTileCard
  .TenancyDetailsTileCardPic,
.propertySharedView
  .snagging
  .snaggingTile
  .snaggingTileCard
  .snaggingTileCardPic {
  width: 100%;
  min-width: 50%;
  min-height: 475px;
  height: 475px;
  max-height: 550px;
  overflow: hidden;
  background-color: #dcdcdc;
  display: flex;
  justify-content: center;
}

.propertySharedView .photos .photosTile .photoTilePic {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #dcdcdc;
}

.propertySharedView .OwnershipDocsCardsBox .OwnershipDocsCardAdd .docsPic img,
.propertySharedView
  .floorPlans
  .floorPlansTile
  .floorPlansTileCard
  .floorPlansTileCardPic
  img,
.propertySharedView
  .TenancyDetails
  .TenancyDetailsTile
  .TenancyDetailsTileCard
  .TenancyDetailsTileCardPic
  img,
.propertySharedView
  .snagging
  .snaggingTile
  .snaggingTileCard
  .snaggingTileCardPic
  img {
  max-width: 100%;
  height: 100%;
  background-size: cover;
  object-fit: scale-down;
}

.propertySharedView .photos .photosTile img {
  width: 100%;
  height: 100%;
  background-size: cover;
  object-fit: cover;
}

.propertySharedView .OwnershipDocsCardsBox .OwnershipDocsCardAdd .span,
.propertySharedView .floorPlans .floorPlansTile .floorPlansTileCard .span,
.propertySharedView
  .TenancyDetails
  .TenancyDetailsTile
  .TenancyDetailsTileCard
  .span,
.propertySharedView .snagging .snaggingTile .snaggingTileCard .span {
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
  width: 100%;
  height: 100%;
  max-height: 475px;
  overflow: hidden;
  overflow-y: auto;
  padding: 0 5px 4px;
  word-break: break-all;
}

.propertySharedView .photos .photosTile .photosCard::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  opacity: 0;
  transition: all 0.25s ease;
  border-radius: 10px;
}

.propertySharedView .photos .photosTile .photosCard:hover::after {
  opacity: 1;
}

.propertySharedView .photos .photosTile .photosCard:hover {
  transform: scale(0.98);
}

.propertySharedView .image-slider {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1250;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 2000;
}

.propertySharedView .image-gallery {
  max-width: 70% !important;
  margin: 0 auto !important;
}

/* Style for individual gallery items */
.propertySharedView .image-gallery-content img {
  max-width: 100% !important;
}

.propertySharedView .image-gallery-slide .image-gallery-image {
  /* object-fit: cover !important; */
  height: 100% !important;
}

.propertySharedView .image-gallery-thumbnail .image-gallery-thumbnail-image {
  height: 60px !important;
}

.propertySharedView .close-slider {
  position: absolute;
  top: 10px;
  right: 25px;
  cursor: pointer;
  background-color: var(--bg-plus-txt-color);
  color: var(--txt-color);
  font-size: 13px;
  border-radius: 5px;
  padding: 5px 12px;
}

.propertySharedView .react-transform-wrapper,
.propertySharedView .transform-component-module_wrapper__SPB86,
.propertySharedView .react-transform-component,
.propertySharedView .transform-component-module_content__FBWxo {
  width: 100%;
  height: 100%;
  justify-content: center;
}

.propertySharedView .react-pdf__Document,
.propertySharedView.react-pdf__Page,
.propertySharedView .react-pdf__Page .pdf-page {
  width: 100%;
  height: 100%;
}

.propertySharedView .react-pdf__Page__textContent,
.propertySharedView .react-pdf__Page__textContent.textLayer,
.propertySharedView .react-pdf__Page__annotations,
.propertySharedView .react-pdf__Page__annotations.annotationLayer,
.propertySharedView
  .react-pdf__Page__annotations.annotationLayer
  + .react-pdf__Page {
  display: none !important;
}

.propertySharedView .react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
  /* object-fit: contain; */
}

.react-pdf__message.react-pdf__message--error {
  display: none !important;
}

/* for responsive */
@media only screen and (max-width: 1000px) {
  .propertySharedView {
    width: 100%;
    max-width: unset;
    margin: 0;
  }
}

@media only screen and (max-width: 768px) {
  .propertySharedView .image-gallery {
    max-width: 100% !important;
  }
  .propertySharedView .image-gallery-slide {
    aspect-ratio: 4/3;
  }
  .propertySharedView .image-gallery-slide .image-gallery-image {
    height: 100% !important;
  }
  .propertySharedView .OwnershipDocsCardsBox .OwnershipDocsCardAdd,
  .propertySharedView .floorPlans .floorPlansTile .floorPlansTileCard,
  .propertySharedView
    .TenancyDetails
    .TenancyDetailsTile
    .TenancyDetailsTileCard,
  .propertySharedView .snagging .snaggingTile .snaggingTileCard {
    width: 100%;
    max-height: unset;
  }
  .propertySharedView .OwnershipDocsCardsBox .OwnershipDocsCardAdd .docsPic,
  .propertySharedView
    .floorPlans
    .floorPlansTile
    .floorPlansTileCard
    .floorPlansTileCardPic,
  .propertySharedView
    .TenancyDetails
    .TenancyDetailsTile
    .TenancyDetailsTileCard
    .TenancyDetailsTileCardPic,
  .propertySharedView
    .snagging
    .snaggingTile
    .snaggingTileCard
    .snaggingTileCardPic {
    min-width: unset;
  }
  .propertySharedView .OwnershipDocsCardsBox .OwnershipDocsCardAdd .span,
  .propertySharedView .floorPlans .floorPlansTile .floorPlansTileCard .span,
  .propertySharedView
    .TenancyDetails
    .TenancyDetailsTile
    .TenancyDetailsTileCard
    .span,
  .propertySharedView .snagging .snaggingTile .snaggingTileCard .span {
    height: auto;
    max-height: unset;
    overflow-y: unset;
  }
}
