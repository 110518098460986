.chatRoom {
  width: 100%;
  height: 480px;
  display: flex;
  flex-direction: column;
  padding: 0px;
  /* animation: chat 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards; */
  transition: 0.2s all;
  background-color: #f5f6f7;
}

/* @keyframes chat {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
} */

.chatRoom .chatContentBoxHeader {
  width: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  padding: 6px 10px;
  background-color: #ffffff;
  gap: 10px;
  position: sticky;
  top: 0;
}

.chatRoom .chatContentBoxHeader .moveback {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-radius: 5px;
  width: 18px;
  height: 18px;
}

.chatRoom .chatContentBoxHeader .moveback:hover {
  outline: 2px solid var(--bg-plus-txt-color);
}

.chatRoom .chatContentBoxHeader .profile-pic {
  width: 35px;
  min-width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #dcdcdc;
}

.chatRoom .chatContentBoxHeader .profile-pic img {
  width: 100%;
  height: 100%;
  background-size: cover;
  object-fit: cover;
  /* border-radius: 50%; */
}

.chatRoom .chatContentBoxHeader .profile-pic .initials {
  width: 35px;
  min-width: 35px;
  height: 35px;
  background-color: #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.chatRoom .chatContentBoxHeader .profile-pic .initials svg {
  width: 25px;
  height: 25px;
}

.chatRoom .chatContentBoxHeader span {
  font-size: 13px;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
}

/* Styling for individual chat messages */
.chatRoom .chat-messages {
  width: 100%;
  flex-grow: 1;
  /* height: 100%; */
  display: flex;
  align-items: flex-end;
  /* justify-content: flex-end; */
  flex-direction: column;
  /* gap: 2px; */
  padding: 10px 5px;
  /* scroll-behavior: smooth; */
  overflow-y: auto;
}

.chatRoom .chat-messages::-webkit-scrollbar {
  width: 6px;
}

.chatRoom .chat-messages::-webkit-scrollbar-thumb {
  background-color: #7f7f7f;
  border-radius: 6px;
}

.chatRoom .chat-messages::-webkit-scrollbar-thumb:hover {
  background-color: #5e5e5e;
}

/* Styling for the message input bar */
.chatRoom .message-input {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 12px 6px 8px;
  gap: 8px;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  position: sticky;
  bottom: 0;
}

.chatRoom .message-input .imageMsgSection {
  min-width: 84px;
  width: 84px;
  height: 84px;
  border-radius: 6px;
  overflow: hidden;
  align-self: flex-start;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  margin-left: 8px;
}

.chatRoom .message-input .imageMsgSection img {
  width: 100%;
  height: 100%;
  background-size: cover;
}

.chatRoom .message-input .imageMsgSection .removeImage {
  position: absolute;
  right: 3px;
  top: 3px;
  width: 20px;
  height: 20px;
  border: 1px solid var(--bg-plus-txt-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #ffffff;
  cursor: pointer;
}

.chatRoom .message-input .imageMsgSection .removeImage svg {
  width: 20px;
  height: 20px;
}

.chatRoom .message-input .imageMsgSection .removeImage svg path {
  fill: var(--bg-plus-txt-color);
}

.chatRoom .secondLine {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
}

.chatRoom .message-input textarea {
  flex: 1;
  width: 100%;
  padding: 8px;
  height: 32px;
  min-height: 29px;
  max-height: 110px;
  /* border: 1px solid #ddd; */
  border-radius: 5px;
  box-shadow: none;
  border: none;
  resize: none;
  outline: none;
  font-style: normal;
  font-family: inherit;
  font-size: 12px;
  color: #0f1828;
  background-color: #e8e8e8;
}

.chatRoom .message-input textarea:focus {
  outline: 1px solid var(--bg-plus-txt-color);
}

.chatRoom .message-input .sendMsgButton,
.chatRoom .message-input .chatPLusBox {
  padding: 5px 8px;
  /* background-color: var(--bg-plus-txt-color); */
  color: white;
  border-radius: 5px;
  /* border: 1px solid #ddd; */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
}
.chatRoom .message-input .chatPLusBox svg {
  width: 16px;
  height: 16px;
}

.chatRoom .message-input .sendMsgButton svg {
  width: 18px;
  height: 18px;
}

.chatRoom .message-input .chatPLusBox:hover svg path {
  fill: var(--bg-plus-txt-color);
  transition: 0.2s all;
}

@media only screen and (max-width: 768px) {
  .chatRoom {
    height: 100%;
  }
  .chatRoom .message-input {
    padding: 12px 6px 16px;
  }
  .chatRoom .message-input .chatPLusBox svg {
    width: 18px;
    height: 18px;
  }

  .chatRoom .message-input .sendMsgButton svg {
    width: 22px;
    height: 22px;
  }
  .chatRoom .chatContentBoxHeader .moveback {
    width: 20px;
    height: 20px;
  }
}
