/* reviews list layout */
.reviewsBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  gap: 20px;
  animation: show 0.15s ease;
}

@keyframes show {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.reviewsBox .header {
  width: 100%;
  background-color: var(--component-bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.reviewsBox .header .moveback {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-radius: 5px;
  position: absolute;
  left: 20px;
  width: 20px;
  height: 20px;
}

.reviewsBox .header .moveback:hover {
  outline: 2px solid var(--bg-plus-txt-color);
}

.reviewsBox .header span {
  height: auto;
  font-weight: 500;
  font-size: 20px;
  line-height: normal;
  text-align: center;
  text-transform: capitalize;
  color: var(--bg-plus-txt-color);
  margin: auto;
}

.reviewsBox .reviewList {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0 20px 5px;
  width: 100%;
}

.reviewsBox .reviewList .reviewCard {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 10px 10px 15px;
  flex: 1 0 300px;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.reviewsBox .reviewList .reviewCard .cardHeaderLine {
  display: flex;
  flex-direction: column;
}

.reviewsBox .reviewList .reviewCard .cardHeaderLine .cardFirstLine {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 12px;
}

.reviewsBox .reviewList .reviewCard .cardHeaderLine .userProfilePic {
  width: 28px;
  height: 28px;
  background-color: #d2d2d2;
  border-radius: 50%;
  overflow: hidden;
}

.reviewsBox .reviewList .reviewCard .cardHeaderLine .userProfilePic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.reviewsBox .reviewList .reviewCard .cardHeaderLine .userName {
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  text-transform: capitalize;
  color: #1b1b1b;
}

.reviewsBox .reviewList .reviewCard .cardHeaderLine .ratingStars {
  display: flex;
  flex-direction: row;
  gap: 2px;
}

.reviewsBox .reviewList .reviewCard .cardHeaderLine .ratingStars span {
  width: 12px;
  height: 12px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reviewsBox .reviewList .reviewCard .cardHeaderLine .ratingStars span svg {
  width: 12px;
  height: 12px;
}

.reviewsBox .reviewList .reviewCard .reviewContent {
  width: 100%;
  background: #ffffff;
  padding: 6px;
  max-height: 270px;
  overflow-y: auto;
}

.reviewsBox .reviewList .reviewCard .reviewContent p {
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
  word-break: break-all;
}

@media only screen and (max-width: 768px) {
  .reviewsBox {
    position: fixed;
    top: 0;
    background-color: var(--component-bg-color);
    z-index: 750;
    padding: 0;
    gap: 8px;
  }
  .reviewsBox .header {
    position: sticky;
    top: 0;
    z-index: 1250;
    padding: 9.5px 0;
    margin-bottom: 5px;
    background-color: var(--component-bg-color);
    box-shadow: 0 0 5px rgb(0, 0, 0, 0.3);
  }
  .reviewsBox .header .moveback {
    left: 15px;
  }
  .reviewsBox .header .moveback svg {
    width: 16px;
    height: 16px;
  }
  .reviewsBox .header span {
    height: auto;
    font-size: 18px;
  }
  .reviewsBox .reviewList {
    padding: 0 5px;
    gap: 8px;
  }
  .reviewsBox .reviewList .reviewCard {
    flex: 1 0 100%;
  }
  .reviewsBox .reviewList .reviewCard .reviewContent p {
    font-size: 13px;
    max-height: unset;
    overflow: hidden;
  }
  .reviewsBox .reviewList .reviewCard .reviewContent {
    max-height: unset;
    height: auto;
  }
}
