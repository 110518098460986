.overlayBox {
  width: 100vw;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 800;
  background: rgba(0, 0, 0, 0.25);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlayBox .contactsTileToSelect {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 10px 20px 20px;
  width: 550px;
  /* min-height: 220px; */
  height: 560px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  animation: slideFromBottomToTop 0.2s ease;
}

@keyframes slideFromBottomToTop {
  0% {
    transform: translatey(100%);
  }
  100% {
    transform: translatey(0);
  }
}

.overlayBox .contactsTileToSelect .cntslHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 10px;
  border-bottom: 1px solid var(--bg-plus-txt-color);
  margin: 0 0 5px;
}

.overlayBox .contactsTileToSelect .cntslHeader .text {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #444746;
}

.overlayBox .contactsTileToSelect .cntslHeader .text svg {
  align-self: flex-end;
  margin-left: 5px;
}

.overlayBox .contactsTileToSelect .cntslHeader .text svg path {
  fill: var(--bg-plus-txt-color);
}

.overlayBox .contactsTileToSelect .closeIcon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--bg-plus-txt-color);
  border-radius: 4px;
  cursor: pointer;
  z-index: 20;
  transition: 0.1s;
}

.overlayBox .contactsTileToSelect .closeIcon svg {
  width: 18px;
  height: 18px;
}

.overlayBox .contactsTileToSelect .closeIcon svg path {
  fill: var(--bg-plus-txt-color);
}

.overlayBox .contactsTileToSelect .closeIcon:hover {
  background-color: var(--bg-plus-txt-color);
}

.overlayBox .contactsTileToSelect .closeIcon:hover svg path {
  fill: var(--txt-color);
}

.overlayBox .contactsTileToSelect .cntsbc {
  width: 100%;
}

.overlayBox .contactsTileToSelect .cntsbc .searchBarBox {
  width: 100%;
  margin: 0;
}

.overlayBox .contactsTileToSelect .cntsbc .searchBarBox input {
  border: 1px solid var(--bg-plus-txt-color);
}

.overlayBox .contactsTileToSelect .contactsCountLine {
  padding: 0 0 3px 7px;
}

.overlayBox .contactsTileToSelect .contactsCountLine span {
  color: #444746;
  font-weight: 500;
  font-size: 13px;
  line-height: normal;
}

.overlayBox .contactsTileToSelect .addedContact {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  border-block: 1px solid var(--bg-plus-txt-color);
  padding: 0 0 20px;
}

.overlayBox .contactsTileToSelect .addedContact::-webkit-scrollbar {
  width: 8px;
}

.overlayBox .contactsTileToSelect .addedContact::-webkit-scrollbar-thumb {
  background-color: #7f7f7f;
  border-radius: 6px;
}

.overlayBox .contactsTileToSelect .addedContact::-webkit-scrollbar-thumb:hover {
  background-color: #5e5e5e;
}

.overlayBox .contactsTileToSelect .addedContact .contact-box {
  display: flex;
  align-items: center;
  padding: 5px 2px;
  width: 100%;
  min-height: 48px;
  background-color: #ffffff;
  border-bottom: 1px solid #c4c7c5;
  /* cursor: pointer; */
  transition: 0.3s all;
}

/* .overlayBox .contactsTileToSelect .addedContact .contact-box:hover {
  background-color: #dcdcdc;
} */

.overlayBox .contactsTileToSelect .addedContact .contact-box .profile-pic {
  min-width: 35px;
  width: 35px;
  height: 35px;
  border-radius: 18px;
  overflow: hidden;
  margin-right: 10px;
  background-color: #dcdcdc;
}

.overlayBox .contactsTileToSelect .addedContact .contact-box .profile-pic img {
  width: 100%;
  height: 100%;
  border-radius: 18px;
  background-size: cover;
  object-fit: cover;
}

.overlayBox .contactsTileToSelect .addedContact .contact-box .initials {
  min-width: 35px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background-color: #ccc;
  color: #fff;
}

.overlayBox .contactsTileToSelect .addedContact .contact-box .contact-details {
  display: flex;
  flex-direction: column;
  gap: 0;
  width: 100%;
  overflow: hidden;
}

.overlayBox
  .contactsTileToSelect
  .addedContact
  .contact-box
  .contact-details
  span {
  height: unset;
  padding: 0px;
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
  color: #444746;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.overlayBox .contactsTileToSelect .addedContact .contact-box .sendForShare {
  width: 30px;
  height: 25px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.overlayBox .contactsTileToSelect .addedContact .contact-box .sendForShare svg {
  width: 21px;
  height: 21px;
}

.overlayBox .contactsTileToSelect .addedContact .msgforcontacts {
  display: flex;
  justify-content: center;
  padding: 12px 10px;
  background-color: #ffffff;
}

.overlayBox .contactsTileToSelect .addedContact .msgforcontacts span {
  font-size: clamp(18px, 3vw, 23px);
  font-weight: 400;
  color: #797979;
}

.overlayBox .contactsTileToSelect .addedContact .ldtxt {
  width: 100%;
  font-size: 1.15rem;
  display: flex;
  justify-content: center;
  margin: auto;
}

@media only screen and (max-width: 1000px) {
  .overlayBox .contactsTileToSelect {
    height: 490px;
  }
}

@media only screen and (max-width: 768px) {
  .overlayBox .contactsTileToSelect {
    padding: 5px 10px 15px;
    width: 100%;
    height: 100%;
    max-height: unset;
    border-radius: 0px;
    box-shadow: none;
  }
  .overlayBox .contactsTileToSelect .cntsbc .searchBarBox {
    width: 96%;
    margin: auto;
  }
  .overlayBox
    .contactsTileToSelect
    .addedContact
    .contact-box
    .sendForShare
    svg {
    width: 20px;
    height: 20px;
  }
}

@media only screen and (max-width: 540px) {
  .overlayBox .contactsTileToSelect .cntslHeader .text {
    font-size: 13.3px;
  }
  .overlayBox .contactsTileToSelect .contactsCountLine span {
    font-size: 12px;
  }
  .overlayBox
    .contactsTileToSelect
    .addedContact
    .contact-box
    .contact-details
    span {
    line-height: normal;
    font-size: 12px;
  }
}
