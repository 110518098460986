.individualReview {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1300;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 0, 0, 0.6);
}

.individualReview .individualReviewBox {
  width: 500px;
  height: 500px;
  background-color: var(--component-bg-color);
  box-shadow: 0 10px 15px rgb(0, 0, 0, 0.5);
  border-radius: 6px;
  position: relative;
  overflow: hidden;
}

.individualReview .individualReviewBox .headerLine {
  width: 100%;
  background-color: var(--component-bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 7px 0;
  box-shadow: 0 0 5px rgb(0, 0, 0, 0.3);
}

.individualReview .headerLine .moveback {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 3px;
  border-radius: 5px;
  position: absolute;
  left: 15px;
}

.individualReview .headerLine .moveback:hover {
  outline: 2px solid var(--bg-plus-txt-color);
}

.individualReview .headerLine span {
  height: auto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: normal;
  text-align: center;
  text-transform: capitalize;
  color: var(--bg-plus-txt-color);
  margin: auto;
}

.individualReview .startsBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  gap: 10px;
}

.individualReview .startsBox .star {
  cursor: pointer;
}

.individualReview .startsBox .filled-star,
.individualReview .startsBox .unfilled-star {
  width: 40px;
  height: 40px;
}
.individualReview .startsBox .filled-star svg,
.individualReview .startsBox .unfilled-star svg {
  width: 40px;
  height: 40px;
}

.individualReview .reviewContentBox {
  padding: 5px 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.individualReview .reviewContentBox span {
  font-weight: 500;
  font-size: 16px;
  line-height: normal;
  color: var(--bg-plus-txt-color);
}

.individualReview .reviewContentBox .reviewContent {
  width: 100%;
  min-height: 80px;
  max-height: 260px;
  height: auto;
  margin: auto;
  background-color: #ffffff;
  outline: 1px solid var(--bg-plus-txt-color);
  border-radius: 8px;
  word-break: break-all;
  overflow: hidden;
  overflow-y: auto;
}

.individualReview .reviewContentBox textarea {
  resize: none;
  font-family: inherit;
  width: 100%;
  padding: 8px;
  min-height: 80px;
  max-height: 260px;
  height: auto;
  border: none;
  outline: none;
  font-size: 13px;
  background-color: #ffffff;
  outline: 1px solid var(--bg-plus-txt-color);
  border-radius: 8px;
  word-break: break-all;
  overflow: hidden;
  overflow-y: auto;
}

.individualReview .reviewContentBox .reviewContent::-webkit-scrollbar {
  width: 6px;
}

.individualReview .reviewContentBox .reviewContent::-webkit-scrollbar-thumb {
  background-color: #7f7f7f;
  border-radius: 6px;
}

.individualReview
  .reviewContentBox
  .reviewContent::-webkit-scrollbar-thumb:hover {
  background-color: #5e5e5e;
}

.individualReview .reviewContentBox .reviewContent pre {
  width: 100%;
  height: 100%;
  font-size: 12px;
  padding: 8px;
  white-space: break-spaces;
  word-break: break-all;
}

.individualReview .reviewContentBox button {
  font-size: 12px;
  align-self: center;
  padding: 5px 12px;
  background: none;
  width: 50px;
  height: 23px;
  color: var(--bg-plus-txt-color);
  border: 1px solid var(--bg-plus-txt-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.individualReview .reviewContentBox button:hover {
  background-color: var(--bg-plus-txt-color);
  color: var(--txt-color);
}

@media only screen and (max-width: 768px) {
  .individualReview .individualReviewBox {
    width: 100%;
    height: 100%;
    box-shadow: none;
    border-radius: 0;
    overflow-y: auto;
  }
  .individualReview .headerLine span {
    font-size: 18px;
  }
  .individualReview .reviewContentBox span {
    font-size: 14px;
  }
  .individualReview .reviewContentBox .reviewContent pre {
    font-size: 11px;
  }
  .individualReview .startsBox .filled-star,
  .individualReview .startsBox .unfilled-star,
  .individualReview .startsBox .filled-star svg,
  .individualReview .startsBox .unfilled-star svg {
    width: 36px;
    height: 36px;
  }
  .individualReview .individualReviewBox .headerLine {
    padding: 11px 0;
  }
}
@media only screen and (max-width: 300px) {
  .individualReview .startsBox {
    padding: 15px 10px;
    flex-wrap: wrap;
  }
}
