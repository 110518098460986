.settingContent {
  display: flex;
  flex-direction: column;
  padding: 12px 0px;
  height: 100vh;
}

/* .settingContent .header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  margin-bottom: 18px;
}

.settingContent .header span {
  width: 90px;
  height: 32px;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 32px;
  text-align: center;
  text-transform: capitalize;
  color: var(--bg-plus-txt-color);
} */

.settingContent .serviceTile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px 15px;
  gap: 12px;
  width: 100%;
}

.settingContent .serviceTile .servTile {
  width: 100%;
  background: #f0f0f0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 17px 20px;
  transition: all 0.2s ease;
  cursor: pointer;
}

.settingContent .serviceTile .servTile:hover {
  background: #d4d4d4;
}

.settingContent .serviceTile .servTile span {
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  color: #000000;
}

.overlayForSubscriptionPlan {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 15px 25px;
  /* position: absolute;
  top: 0;
  left: 0; */
  width: 100%;
  height: 100vh;
  background-color: var(--component-bg-color);
  overflow-y: auto;
}

.overlayForSubscriptionPlan .headerLine {
  width: 96%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--component-bg-color);
  padding: 9.5px 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 auto 10px;
  position: sticky;
  top: 0px;
  z-index: 25;
}

.overlayForSubscriptionPlan .headerLine .moveback {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 3px;
  border-radius: 5px;
  width: 22px;
  height: 22px;
  position: absolute;
}

.overlayForSubscriptionPlan .headerLine .moveback:hover {
  outline: 2px solid var(--bg-plus-txt-color);
}

.overlayForSubscriptionPlan .headerLine .addUserIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 3px;
  border-radius: 5px;
  width: 22px;
  height: 22px;
  position: absolute;
  right: 15px;
  cursor: pointer;
}

.overlayForSubscriptionPlan .headerLine .addUserIcon svg path {
  fill: var(--bg-plus-txt-color);
}

.overlayForSubscriptionPlan .headerLine span {
  display: block;
  width: auto;
  height: auto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: normal;
  text-align: center;
  text-transform: capitalize;
  color: var(--bg-plus-txt-color);
  margin: auto;
}

.overlayForSubscriptionPlan .subscription-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px 15px;
  gap: 12px;
  width: 100%;
}

.overlayForSubscriptionPlan .subscription-group .headingText {
  width: auto;
  margin: auto;
  color: var(--bg-plus-txt-color);
  font-size: 14px;
}

.overlayForSubscriptionPlan .subscription-group .subscription-Tile {
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  padding: 10px 20px;
  transition: all 0.2s ease;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  transition: 0.3s all;
  z-index: 10;
}
.overlayForSubscriptionPlan .subscription-group .subscription-Tile:hover {
  background: #e7e7e7;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.14);
}

.overlayForSubscriptionPlan .subscription-group .subscription-Tile span {
  font-weight: 600;
  font-size: 15px;
  line-height: normal;
  color: var(--bg-plus-txt-color);
}

.overlayForSubscriptionPlan .subscription-group .subscription-Tile p {
  font-size: 13px;
  color: #575757;
}

.overlayForSubscriptionPlan .subscription-group .onLoadMsg {
  width: 100%;
  text-align: center;
  font-size: 13px;
  color: #454545;
}

.overlayForSubscriptionPlan
  .subscription-group
  .subscription-Tile
  .activeCheck {
  position: absolute;
  right: 25px;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlayForSubscriptionPlan
  .subscription-group
  .subscription-Tile
  .activeCheck
  svg {
  width: 22px;
  height: 22px;
}

.overlayForSubscriptionPlan
  .subscription-group
  .subscription-Tile
  .activeCheck
  svg
  path {
  fill: #0f9e0f;
}

.overlayForSubscriptionPlan .expDateTag {
  position: sticky;
  bottom: 0;
  z-index: 100;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: var(--component-bg-color);
}

.overlayForSubscriptionPlan .expDateTag p {
  margin: 0;
  font-size: 14px;
  color: #797979;
}

.overlayForSubscriptionPlan .expDateTag p + p {
  margin-left: 6px;
}

/* .purlst {
  position: absolute;
} */

/* For Responsive */
@media only screen and (max-width: 768px) {
  .settingContent {
    padding-top: 8px;
    height: 90%;
  }
  .settingContent .serviceTile {
    gap: 10px;
  }
  .settingContent .serviceTile .servTile span {
    font-size: 14.5px;
  }
  .overlayForSubscriptionPlan {
    padding: 0 0 25px;
    overflow: unset;
    height: 90%;
  }
  .overlayForSubscriptionPlan .headerLine {
    width: 100%;
    top: 40px;
  }
  .overlayForSubscriptionPlan .headerLine .moveback {
    padding: 1.5px 2px;
    width: 20px;
    height: 20px;
    position: absolute;
  }
  .overlayForSubscriptionPlan .headerLine span {
    font-size: 15px;
  }
  .overlayForSubscriptionPlan .subscription-group {
    padding: 5px 12px 10px;
    gap: 8px;
  }
  .overlayForSubscriptionPlan .subscription-group .headingText {
    font-size: 13px;
  }
  .overlayForSubscriptionPlan .subscription-group .subscription-Tile {
    padding: 10px 14px;
  }
  .overlayForSubscriptionPlan .subscription-group .subscription-Tile span {
    font-size: 13.5px;
  }
  .overlayForSubscriptionPlan .subscription-group .subscription-Tile p {
    font-size: 12px;
  }
  .overlayForSubscriptionPlan .expDateTag p {
    font-size: 13px;
  }
}

@media only screen and (max-width: 540px) {
  .settingContent .serviceTile {
    gap: 5px;
  }
  .settingContent .serviceTile .servTile span {
    font-size: 13.5px;
    font-weight: bold;
    /* color: #444444; */
  }
  .settingContent .settingContentsTiles .serviceTile .servTile {
    padding: 17px 15px;
  }
  .overlayForSubscriptionPlan .subscription-group .subscription-Tile span {
    font-size: 13px;
  }

  .overlayForSubscriptionPlan .subscription-group .subscription-Tile p {
    font-size: 11.5px;
  }
}

@media only screen and (max-width: 340px) {
  .overlayForSubscriptionPlan .expDateTag {
    flex-direction: column;
  }
}
