.sendNotificationBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 13px;
  width: 100%;
}

.sendNotificationBox .posstk {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  background-color: var(--component-bg-color);
  padding: 5px 0;
}

.sendNotificationBox .listTile {
  background: #ffffff;
  width: 100%;
  transition: background-color 0.3s;
  position: relative;
  padding: 0;
  display: flex;
  align-items: flex-start;
  padding: 8px 4px;
  gap: 10px;
}

.sendNotificationBox .listTile > div {
  flex: 1;
  color: #797979;
  overflow: hidden;
}

.sendNotificationBox .listTile > div span {
  /* height: 20px; */
  font-weight: 500;
  font-size: 15px;
  line-height: normal;
  letter-spacing: 0.8px;
  color: #000000;
}

.sendNotificationBox .listTile > div p {
  height: 20px;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  color: #696969;
}

.sendNotificationBox .notifyButtons {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.sendNotificationBox .notificationActionBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid #dcdcdc;
}

.sendNotificationBox .notificationActionBox .firstline {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sendNotificationBox .notificationActionBox .firstline span {
  line-height: normal;
  font-size: 14px;
}

.sendNotificationBox .notificationActionBox .firstline .last-sent {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #797979;
  margin-right: auto;
}

.sendNotificationBox .notificationActionBox .firstline .last-sent .dot {
  width: 9px;
  min-width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #797979;
  margin: 5px 5px 0 8px;
}

.sendNotificationBox .notificationActionBox .firstline .last-sent p {
  margin-left: 5px;
}

.sendNotificationBox .notificationActionBox .firstline .flButtons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.sendNotificationBox .notificationActionBox .firstline .flButtons .notificBtn {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.sendNotificationBox
  .notificationActionBox
  .firstline
  .flButtons
  .notificBtn
  svg {
  width: 18px;
  height: 18px;
}

.sendNotificationBox
  .notificationActionBox
  .firstline
  .flButtons
  .notificBtn
  svg
  path {
  fill: var(--bg-plus-txt-color);
}

.sendNotificationBox .para {
  width: 100%;
  height: 68px;
}

.sendNotificationBox .para .notificationTitle {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 1px solid var(--bg-plus-txt-color);
  border-radius: 5px;
  font-weight: 400;
  padding: 5px 8px;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.8px;
  color: #797979;
  overflow-y: auto;
}

.sendNotificationBox .contactsTag {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: auto;
}

.sendNotificationBox .contactsTag span {
  line-height: normal;
  font-size: 14px;
}

.sendNotificationBox .contactsTag .contacts-container {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  align-items: flex-start;
  padding: 8px 10px;
  gap: 5px;
  overflow: hidden;
  overflow-x: auto;
  width: 100%;
}

.sendNotificationBox .contactsTag .contacts-container .contact-item {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 62px;
  min-width: 62px;
  /* height: 76px; */
  height: 62px;
  border-radius: 5px;
  border-radius: 50%;
  background-color: #dcdcdc;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
  overflow: hidden;
}

.sendNotificationBox .contactsTag .contacts-container .contact-item img {
  width: 100%;
  /* height: 58px; */
  height: 100%;
  border-radius: 50%;
  background-size: cover;
  object-fit: cover;
}

.sendNotificationBox .contactsTag .contacts-container .contact-item .initials {
  width: 100%;
  /* height: 58px; */
  /* border-radius: 5px; */
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: clamp(16px, 3vw, 22px);
  font-weight: bold;
  background-color: #dcdcdc;
  color: #ffffff;
}

.sendNotificationBox .contactsTag .contacts-container .contact-item p {
  padding: 3px 2px 2px;
  width: 100%;
  flex: 1;
  background: #ffffff;
  border-radius: 0px 0px 5px 5px;
  font-weight: 400;
  font-size: 8px;
  text-align: center;
  text-transform: capitalize;
  color: #797979;
  white-space: nowrap;
  overflow: hidden;
}

.notificationActionBox button {
  align-self: flex-end;
}
.sendNotificationBox button {
  text-align: center;
  padding: 6px 15px;
  font-size: 12px;
  border-radius: 5px;
}

@media only screen and (max-width: 1100px) {
  .sendNotificationBox {
    padding: 0 12px;
  }
}

@media only screen and (max-width: 768px) {
  .sendNotificationBox,
  .sendNotificationBox .notificationActionBox {
    gap: 10px;
  }
  .sendNotificationBox button {
    padding: 6px 16px;
    font-size: 13px;
  }
  .sendNotificationBox .notifyButtons {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
    padding-bottom: 5px;
  }
  .sendNotificationBox .posstk {
    position: sticky;
    top: 42px;
    z-index: 10;
    gap: 4px;
    padding: 10px 0px 0px;
  }
  .sendNotificationBox .listTile > div p {
    font-size: 13px;
  }
  .sendNotificationBox
    .notificationActionBox
    .firstline
    .flButtons
    .notificBtn {
    width: 18px;
    height: 18px;
  }
  .sendNotificationBox
    .notificationActionBox
    .firstline
    .flButtons
    .notificBtn
    svg {
    width: 18px;
    height: 18px;
  }
  .sendNotificationBox .contactsTag {
    gap: 0;
  }
}

@media only screen and (max-width: 540px) {
  .sendNotificationBox,
  .sendNotificationBox .notificationActionBox {
    gap: 5px;
  }
  .sendNotificationBox .notificationActionBox {
    gap: 5px;
    padding: 5px 0;
  }
  .sendNotificationBox .posstk {
    top: 35px;
  }
  .sendNotificationBox .listTile {
    flex-direction: column;
    padding: 4px 0;
    gap: 0;
  }
  .sendNotificationBox .listTile > div {
    width: 100%;
    flex: none;
    flex-grow: 1;
    padding: 0px 6px;
    gap: 0;
  }
  .sendNotificationBox .listTile > div span {
    height: auto;
    font-size: 13px;
    line-height: 18px;
  }
  .sendNotificationBox .listTile > div p {
    height: auto;
    font-size: 12px;
    line-height: 14px;
  }
  .sendNotificationBox .contactsTag {
    padding: 4px 6px;
    flex-wrap: wrap;
  }
  .sendNotificationBox .contactsTag span {
    font-size: 14px;
  }
  .sendNotificationBox button {
    padding: 5px 12px;
    font-size: 12px;
  }
  .sendNotificationBox .contactsTag .contacts-container .contact-item {
    width: 55px;
    min-width: 55px;
    height: 55px;
  }
  .sendNotificationBox .notificationActionBox .firstline .last-sent {
    font-size: 11px;
    margin-top: 3px;
  }

  .sendNotificationBox .notificationActionBox .firstline .last-sent .dot {
    margin: 0px 5px;
  }
}
