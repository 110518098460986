.notificationsMain {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0 15px;
}

.notificationsMain .header {
  width: 100%;
  background-color: var(--component-bg-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 10px;
}

.notificationsMain .header span {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 32px;
  text-align: center;
  text-transform: capitalize;
  color: var(--bg-plus-txt-color);
  margin: auto;
}

.notificationsMain .notificationsBox {
  width: 100%;
  display: flex;
  border-top: 2px solid #d2d2d2;
}

.notificationsMain .pstky {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--component-bg-color);
}

.notificationsMain .notificationsBox .incomingNotification,
.notificationsMain .notificationsBox .outgoingNotifications {
  width: 50%;
  position: relative;
  display: block;
  padding-bottom: 20px;
}
.notificationsMain .notificationsBox .incomingNotification {
  border-right: 2px solid #d2d2d2;
}

.notificationsMain .notificationsBoxButtons {
  display: none;
  justify-content: center;
  align-items: center;
  gap: 2px;
  width: auto;
  padding: 2px;
  margin: 4px auto;
  background-color: var(--fields2-color);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.11);
}

.notificationsMain .notificationsBoxButtons button {
  color: #292929;
  width: 150px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: none;
}

.notificationsMain .notificationsBoxButtons button.active {
  background-color: var(--bg-plus-txt-color);
  color: var(--txt-color);
}

.notificationsMain .notificationsBoxButtons button.non-active {
  color: var(--bg-plus-txt-color);
}

@media only screen and (max-width: 1000px) {
  .notificationsMain .notificationsBox {
    border: none;
  }
  .notificationsMain .notificationsBox .incomingNotification,
  .notificationsMain .notificationsBox .outgoingNotifications {
    min-width: 100%;
    width: 100%;
    border: none;
  }
  .notificationsMain .notificationsBox .incomingNotification.show,
  .notificationsMain .notificationsBox .outgoingNotifications.show {
    display: block;
  }
  .notificationsMain .notificationsBox .incomingNotification.hide,
  .notificationsMain .notificationsBox .outgoingNotifications.hide {
    display: none;
  }
  .notificationsMain .notificationsBoxButtons {
    display: flex;
  }
}

@media only screen and (max-width: 768px) {
  .notificationsMain {
    padding-top: 0;
  }
  .notificationsMain .header {
    padding: 5px 0;
  }
  .notificationsMain .header span {
    height: 28px;
    font-size: 20px;
    line-height: 28px;
  }
  .notificationsMain .notsb {
    margin: 5px auto 0px;
  }
  .notificationsMain .pstky {
    position: sticky;
    top: 40px;
    z-index: 50;
    padding: 8px 0 10px;
  }
}

@media only screen and (max-width: 540px) {
  .notificationsMain .notificationsBoxButtons button {
    width: 135px;
    height: 25px;
    font-size: 13px;
  }
}
@media only screen and (max-width: 310px) {
  .notificationsMain .notificationsBoxButtons {
    width: 70%;
    flex-direction: column;
  }
  .notificationsMain .notificationsBoxButtons button {
    flex-grow: 1;
    width: 100%;
  }
}
