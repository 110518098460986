.outgoing-notification {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  gap: 5px;
  width: 100%;
  height: 100%;
}

.outgoing-notification .customheader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
}

.outgoing-notification .customheader-small-screen {
  position: fixed;
  top: 10px;
  right: 5px;
  z-index: 710;
  width: auto;
  padding: 0 !important;
}

.outgoing-notification .customheader.scrolled {
  position: sticky;
  top: 6px;
  z-index: 750;
  width: auto;
  padding: 0;
  margin-left: auto;
  background-color: var(--component-bg-color);
}

.outgoing-notification .customheader .moveback {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-radius: 5px;
  position: absolute;
  left: 20px;
  z-index: 200;
  width: 20px;
  height: 20px;
}

.outgoing-notification .customheader .moveback:hover {
  outline: 2px solid var(--bg-plus-txt-color);
}

.outgoing-notification .customheader span {
  font-weight: 500;
  font-size: 18px;
  line-height: normal;
  text-align: center;
  text-transform: capitalize;
  color: var(--bg-plus-txt-color);
}

.outgoing-notification .customheader .addCustomNotification {
  padding: 10px;
  padding: 4.5px 10px;
  background: var(--bg-plus-txt-color);
  border-radius: 5px;
  position: absolute;
  font-size: 11.5px;
  right: 5px;
}

.outgoing-notification .customNotificationsList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 5px;
  width: 100%;
}

.outgoing-notification .customNotificationsList ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  min-width: 100%;
  list-style: none;
  transition: all 0.3s;
}

.outgoing-notification ul li {
  background: #ffffff;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s;
  position: relative;
  padding: 0;
  display: flex;
  align-items: flex-start;
  padding: 8px 15px;
  gap: 10px;
  border-bottom: 1px solid #dcdcdc;
}

/* @keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
} */

.outgoing-notification ul li:hover {
  background: #d4d4d4;
}

.outgoing-notification ul li > div {
  flex: 1;
  color: #797979;
  overflow: hidden;
  padding-right: 10px;
}

.outgoing-notification ul li > div span {
  font-weight: 500;
  font-size: 14.5px;
  line-height: 23px;
  color: #000000;
}

.outgoing-notification ul li > div p {
  font-size: 13.5px;
  white-space: nowrap;
  overflow: hidden;
  color: #696969;
}

/* for responsive */
.fullPage {
  position: fixed;
  top: 0;
  height: 100vh;
  background-color: var(--component-bg-color);
  z-index: 1150;
  overflow-y: auto;
  padding-bottom: 25px !important;
}

.fullPage .customheader {
  padding: 4px 0;
  margin: 0 0 5px !important;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.11);
}

.fullPage .customheader span {
  display: block;
}

.fullPage .btnStyle {
  margin: 0 !important;
  position: absolute !important;
  right: 6px !important;
}

@media only screen and (max-width: 1000px) {
  .outgoing-notification {
    padding: 0px;
  }
  .outgoing-notification .responsiveDisplayY {
    display: none;
  }
  .outgoing-notification .customheader {
    justify-content: flex-end !important;
  }
  .outgoing-notification .customheader .addCustomNotification {
    position: relative;
    right: 10px;
  }
  .outgoing-notification .customheader span {
    margin: auto;
  }
  .outgoing-notification .customheader .moveback {
    left: 8px;
    top: 4px;
  }
  .outgoing-notification .customheader .setPosition {
    position: relative;
    top: unset !important;
    left: 8px !important;
    margin-right: auto;
  }
}
@media only screen and (max-width: 768px) {
  .outgoing-notification .customheader {
    padding: 9.5px 0;
  }
  .outgoing-notification .customheader.forFullPage {
    position: sticky;
    top: 0;
    z-index: 20;
    background-color: var(--component-bg-color);
  }
  .outgoing-notification .customheader .moveback {
    top: unset;
  }
  .outgoing-notification .customheader .setPosition {
    margin-right: unset;
  }
  .outgoing-notification ul li > div span {
    font-size: 14px;
  }
  .outgoing-notification ul li > div p {
    font-size: 13px;
  }
}
@media only screen and (max-width: 540px) {
  .outgoing-notification .customheader span {
    font-size: 16.5px;
  }
  .outgoing-notification .customheader .moveback svg {
    width: 15px;
  }
  .outgoing-notification .customNotificationsList {
    padding: 0 6px;
  }
  .outgoing-notification .customheader .addCustomNotification {
    padding: 5px 10px;
    font-size: 11px;
  }
  .outgoing-notification ul li {
    flex-wrap: wrap;
    padding: 6px 8px;
    gap: 0px;
    border-bottom: 1px solid #c0c0c0;
  }
  /* .outgoing-notification ul li > div {
    width: 100%;
    flex: none;
    flex-grow: 1;
  } */
  .outgoing-notification ul li > div span {
    font-size: 13px;
    line-height: normal;
  }
  .outgoing-notification ul li > div p {
    font-size: 12px;
    line-height: normal;
  }
}

@media only screen and (max-width: 340px) {
  .outgoing-notification ul li > div {
    width: 100%;
    flex: none;
    flex-grow: 1;
  }
}
