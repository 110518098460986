.addAchievementDetail {
  padding: 0 10px 15px;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: show 0.15s ease;
}

@keyframes show {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.addAchievementDetail .header {
  width: 100%;
  background-color: var(--component-bg-color);
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 10px 15px;
  margin: 8px 0 5px;
  position: relative;
}

.addAchievementDetail .header .moveback {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 3px;
  border-radius: 5px;
  width: 22px;
  height: 22px;
}

.addAchievementDetail .header .moveback:hover {
  outline: 2px solid var(--bg-plus-txt-color);
}

.addAchievementDetail .header span {
  height: 32px;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 32px;
  text-align: center;
  text-transform: capitalize;
  color: var(--bg-plus-txt-color);
  margin: auto;
}

.addAchievementDetail .header button {
  position: absolute;
  right: 0px;
}

.addAchievementDetail .buttons {
  display: flex;
  justify-content: right;
  width: 100%;
  margin-bottom: 5px;
}

.addAchievementDetail .btn {
  margin: 0px 20px 0 0;
  padding: 4px 12px;
  border-radius: 5px;
  /* align-self: flex-end; */
  font-size: 13px;
  background: none;
  color: var(--bg-plus-txt-color);
  border: 1px solid var(--bg-plus-txt-color);
}

.addAchievementDetail .btn:hover,
.addAchievementDetail .header .addFilebtn:hover {
  background-color: var(--bg-plus-txt-color);
  color: var(--txt-color);
}

.addAchievementDetail .header .addFilebtn {
  color: var(--bg-plus-txt-color);
  border: 1px solid var(--bg-plus-txt-color);
  border-radius: 5px;
  transition: 0.1s all;
  cursor: pointer;
  margin: 0 90px 0 auto;
  background: none;
}

.addAchievementDetail .getAchievementBox {
  width: 96%;
  height: 85%;
  margin: 0 auto;
  padding: 12px;
  background-color: #ffffff;
  /* box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1); */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  gap: 12px;
  flex-grow: 1;
}

.addAchievementDetail .getAchievementBox .titleSection {
  width: 100%;
  border-radius: 8px;
  display: flex;
}

.addAchievementDetail .getAchievementBox .title {
  padding: 5px 12px;
  width: 100%;
  max-height: 70px;
  border-radius: 8px;
  display: flex;
  font-size: 14px;
  /* box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.11); */
  background-color: var(--component-bg-color);
  overflow-y: auto;
}

.addAchievementDetail .getAchievementBox .titleSection input {
  padding: 7px 12px;
  width: 100%;
  border-radius: 8px;
  border: none;
}
.addAchievementDetail .getAchievementBox .titleSection input:focus {
  outline: 1px solid var(--bg-plus-txt-color);
}

.addAchievementDetail .getAchievementBox label {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.addAchievementDetail .getAchievementBox .imageBox {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  min-height: 450px;
}

.addAchievementDetail .imageBox.pdfView {
  overflow-y: auto !important;
}

.addAchievementDetail .imageBox .pdf-page {
  margin-bottom: 10px I !important;
}

.addAchievementDetail .react-pdf__Page__textContent,
.addAchievementDetail .react-pdf__Page__textContent.textLayer,
.addAchievementDetail .react-pdf__Page__annotations,
.addAchievementDetail .react-pdf__Page__annotations.annotationLayer {
  display: none !important;
}

.addAchievementDetail .imageBox .react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}

.addAchievementDetail .getAchievementBox .imageBox input {
  width: 100%;
  height: 100%;
}

.addAchievementDetail .getAchievementBox .imageBox img {
  width: 100%;
  height: 100%;
  background-size: cover;
}

.addAchievementDetail .forPdfView {
  width: 100%;
  height: 100%;
}

.addAchievementDetail .getAchievementBox button {
  padding: 5px 18px;
  border-radius: 5px;
}

.addAchievementDetail .react-transform-wrapper,
.addAchievementDetail .transform-component-module_wrapper__SPB86,
.addAchievementDetail .react-transform-component,
.addAchievementDetail .transform-component-module_content__FBWxo {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 1050px) {
  .addAchievementDetail .getAchievementBox {
    padding: 12px;
    gap: 10px;
  }
  /* .addAchievementDetail .getAchievementBox .imageBox {
    height: 330px;
  } */
}

@media only screen and (max-width: 768px) {
  .addAchievementDetail {
    position: fixed;
    top: 0;
    z-index: 9999;
    background-color: var(--component-bg-color);
    height: 100%;
    padding: 0 0 20px 0;
    overflow-y: auto;
  }
  .addAchievementDetail .header {
    padding: 9px 18px;
    margin: 0px;
    box-shadow: 0 0 5px rgb(0, 0, 0, 0.3);
  }
  .addAchievementDetail .header span {
    height: auto;
    font-size: 21px;
    line-height: normal;
  }
  .addAchievementDetail .btn {
    margin: 0px 12px;
    padding: 3px 10px;
  }
  .addAchievementDetail .header .addFilebtn {
    margin: 0 60px 0 auto;
  }
  .addAchievementDetail .getAchievementBox {
    padding: 10px;
    gap: 8px;
    background: none;
  }
  /* .addAchievementDetail .getAchievementBox .imageBox {
    height: 310px;
  } */
  .addAchievementDetail .header .moveback {
    left: 12px;
  }
  .addAchievementDetail .buttons {
    margin: 10px 0;
  }
  .addAchievementDetail .getAchievementBox .imageBox {
    min-height: 360px;
    /* align-items: center;
    display: flex; */
  }
  .addAchievementDetail .getAchievementBox .titleSection .title,
  .addAchievementDetail .getAchievementBox .titleSection input {
    background: #ffffff;
    font-size: 12px;
    padding: 9px 10px;
  }
}

@media only screen and (max-width: 540px) {
  .addAchievementDetail .header .moveback svg {
    width: 16px;
  }
  .addAchievementDetail .header span {
    font-size: 20px;
    line-height: normal;
  }
  .addAchievementDetail .getAchievementBox {
    width: 100%;
    box-shadow: none;
  }
  .addAchievementDetail .buttons {
    margin-bottom: 0px;
  }
}
@media only screen and (max-width: 440px) {
  .addAchievementDetail .getAchievementBox label {
    width: 80%;
  }
  .addAchievementDetail .getAchievementBox label {
    width: 100%;
  }
  .addAchievementDetail .buttons {
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 5px;
  }
  .addAchievementDetail .btn {
    font-size: 12px;
  }
}
