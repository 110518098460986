.chatUserMsgBox {
  width: auto;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 3px;
  max-width: 85%;
}
.chatUserMsgBox label {
  font-size: 9.5px;
  color: #797979;
  width: 100%;
}

.chatUserMsgBox.receivedUserMsgBox {
  margin-right: auto;
}

.chatUserMsgBox.sendUserMsgBox label {
  text-align: left;
  padding-left: 8px;
}

.chatUserMsgBox.receivedUserMsgBox label {
  text-align: right;
  padding-right: 8px;
}

.chatUserMsgBox .firstLine {
  width: auto;
  display: flex;
  gap: 5px;
}

.chatUserMsgBox.sendUserMsgBox .firstLine {
  flex-direction: row-reverse;
}

.chatUserMsgBox .firstLine .userMsgPic {
  min-width: 24px;
  width: 24px;
  height: 24px;
  overflow: hidden;
  border-radius: 50%;
  background-color: #dcdcdc;
  color: var(--bg-plus-txt-color);
}

.chatUserMsgBox .firstLine .userMsgPic img {
  width: 100%;
  height: 100%;
  background-size: cover;
  object-fit: cover;
}

.chatUserMsgBox .firstLine .userMsgPic .initials {
  width: 24px;
  min-width: 24px;
  height: 24px;
  background-color: #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.chatUserMsgBox .firstLine .userMsgPic .initials svg {
  width: 20px;
  height: 20px;
}

.chatUserMsgBox .firstLine .sent,
.chatUserMsgBox .firstLine .received {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 9px 12px;
  width: 100%;
  font-weight: 400;
  font-size: 13px;
  word-break: break-all;
  overflow: hidden;
  box-shadow: 0 1px 1px rgb(0, 0, 0, 0.1);
  align-self: flex-end;
}

.chatUserMsgBox .firstLine .sent {
  box-shadow: 0 2px 2px rgb(0, 0, 0, 0.2);
}

.chatUserMsgBox .firstLine .sent pre,
.chatUserMsgBox .firstLine .received pre {
  height: 100%;
  white-space: break-spaces;
  font-family: inherit;
  font-size: 11px;
}

.chatUserMsgBox .firstLine .msgStyleSet1,
.chatUserMsgBox .firstLine .msgStyleSet2 {
  border-radius: 14px !important;
  padding: 0 !important;
}

.chatUserMsgBox .firstLine .msgStyleSet1 pre {
  width: 100%;
  padding: 10px;
}

.chatUserMsgBox .firstLine .msgStyleSet2 {
  padding: 0;
  background: none !important;
}

.chatUserMsgBox .firstLine .msgStyleSet2 pre {
  display: none;
}

.chatUserMsgBox .firstLine .msgStyleSet3 {
  width: 170px;
}

.chatUserMsgBox .firstLine .imageSend {
  background-color: #dcdcdc;
  width: 170px;
  height: 160px;
  min-width: 170px;
  min-height: 160px;
}

.chatUserMsgBox .firstLine .sent img,
.chatUserMsgBox .firstLine .received img {
  width: 100%;
  height: 100%;
  background-size: cover;
  object-fit: cover;
}

.chatUserMsgBox .firstLine .sent {
  background: var(--bg-plus-txt-color);
  border-radius: 16px 16px 0px 16px;
  color: #ffffff;
}

.chatUserMsgBox .firstLine .received {
  background: #ffffff;
  color: #797979;
  border-radius: 16px 16px 16px 0px;
}

.chatUserMsgBox .react-pdf__Document,
.chatUserMsgBox .react-pdf__Page,
.chatUserMsgBox .react-pdf__Page .pdf-page {
  width: 100%;
  height: 100%;
}

.chatUserMsgBox .react-pdf__Page__textContent,
.chatUserMsgBox .react-pdf__Page__textContent.textLayer,
.chatUserMsgBox .react-pdf__Page__annotations,
.chatUserMsgBox .react-pdf__Page__annotations.annotationLayer,
.chatUserMsgBox
  .react-pdf__Page__annotations.annotationLayer
  + .react-pdf__Page {
  display: none !important;
}

.chatUserMsgBox .react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

@media only screen and (max-width: 768px) {
}
