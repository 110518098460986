.searchBarBox {
  position: relative;
  border-radius: 5px;
  margin: 4px auto 12px;
  width: 90%;
  transition: 0.12s all;
}

.searchBarBox input {
  height: 100%;
  border: 0;
  color: #2f2f2f;
  font-size: 0.8rem;
  border: 0.75px solid #8d8d8d;
  box-shadow: none;
}

.searchBarBox input[type="search"] {
  outline: 0;
  width: 100%;
  height: 36px;
  background: var(--fields-color);
  padding: 0 1.9rem;
  font-size: 13px;
  border-radius: 5px;
  appearance: none;
  transition-property: width, border-radius;
  z-index: 1;
  position: relative;
  box-shadow: none;
  transition: 0.12s all;
}

.searchBarBox input::placeholder {
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.8px;
  color: #8d8d8d;
}

.searchBarBox .searchIcon {
  position: absolute;
  z-index: 2;
  top: 11px;
  left: 10px;
  width: auto;
}

.searchBarBox .searchIcon svg {
  width: 15px;
  height: 15px;
  position: absolute;
}

@media only screen and (max-width: 768px) {
  .searchBarBox {
    width: 92%;
  }
  .sbfm {
    position: fixed;
    width: 96%;
    margin: 10px 0;
    top: 45px;
  }
  .searchBarBox input[type="search"] {
    height: 34px;
    padding: 0 1.3rem 0 1.6rem;
    font-size: 12.7px;
  }
  .searchBarBox .searchIcon {
    top: 10px;
    left: 8.5px;
  }
  .searchBarBox .searchIcon svg {
    width: 13px;
    height: 13px;
  }
}

@media only screen and (max-width: 540px) {
  .searchBarBox input[type="search"] {
    height: 31px;
    font-size: 12px;
  }
}
