.contacts {
  width: 100%;
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.contacts .contactsBox {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 20px;
  height: 100%;
}

.contacts .contactsBox .header {
  width: 100%;
  display: flex;
  justify-content: right;
  background-color: var(--component-bg-color);
}

.contacts .contactsBox .header button {
  background-color: var(--bg-plus-txt-color);
  color: var(--txt-color);
  padding: 8px 15px;
  border-radius: 5px;
  opacity: 0.9;
  border: none;
}

.contacts .contactsBox .header button:hover {
  opacity: 1;
  transform: scale(0.98);
}

.contacts .contactsBox .cntsbc {
  background-color: var(--component-bg-color);
  padding-top: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 222;
}

.contacts .contactsBox .searchBarBox {
  width: 100%;
  margin: 8px 0;
}

.contacts .contactsBox .contactsCountLine {
  padding: 0 0 4px 2px;
  height: 20px;
  display: flex;
  align-items: center;
}

.contacts .contactsBox .contactsCountLine span {
  color: #444746;
  font-weight: 500;
  font-size: 13px;
  line-height: normal;
}

.contacts .contactsBox .contactList {
  padding: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  overflow-y: auto;
}

.contacts .contactsBox .contactList .contactListHeader {
  display: flex;
  padding: 0px;
  width: 100%;
  background-color: #ffffff;
  border-bottom: 1px solid #c4c7c5;
}

.contacts .contactsBox .contactList .contactListHeader span {
  height: 20px;
  padding: 25px 15px;
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 13.7812px;
  line-height: 20px;
  color: #444746;
  border-left: 2px solid #d2d2d2;
}

.contacts .contactsBox .contactList .contactListHeader span:first-child {
  border: none;
}

.contacts .contactsBox .contactList .contactListHeader span:last-child {
  flex: 2;
}

.contactList .msgforcontacts {
  display: flex;
  justify-content: center;
  padding: 12px 10px;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.contactList .msgforcontacts span {
  font-size: clamp(18px, 3vw, 23px);
  font-weight: 400;
  color: #797979;
}

.contactList .ldtxt {
  width: 100%;
  padding: 30px 0;
  font-size: 1.15rem;
  display: flex;
  justify-content: center;
}

.contacts .contactsBox .addedContact {
  padding: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.contacts .contactsBox .addedContact .contact-box {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  width: 100%;
  background-color: #ffffff;
  border-bottom: 1px solid #c4c7c5;
  cursor: pointer;
  transition: 0.3s all;
  overflow: hidden;
}

.contacts .contactsBox .addedContact .contact-box:hover {
  background-color: #dcdcdc;
}

.contacts .contactsBox .addedContact .contact-box:last-child {
  margin-bottom: 25px;
}

.contacts .contactsBox .addedContact .contact-box .profile-pic {
  min-width: 36px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  background-color: #dcdcdc;
}
.contacts .contactsBox .addedContact .contact-box .profile-pic img {
  width: 100%;
  height: 100%;
  border-radius: 18px;
  background-size: cover;
  object-fit: cover;
}
.contacts .contactsBox .addedContact .contact-box .initials {
  min-width: 36px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background-color: #ccc;
  color: #fff;
  border-radius: 50%;
}

.contacts .contactsBox .addedContact .contact-box .contact-details {
  display: flex;

  width: 100%;
}

.contacts .contactsBox .addedContact .contact-box .contact-details span {
  height: 20px;
  padding: 18px 0px;
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 13.7812px;
  line-height: 20px;
  color: #444746;
}

.contacts
  .contactsBox
  .addedContact
  .contact-box
  .contact-details
  span:last-child {
  flex: 2;
  margin-left: 2%;
}

@media only screen and (max-width: 1000px) {
  .contacts .contactsBox .contactList .contactListHeader {
    display: none;
  }
  .contacts
    .contactsBox
    .addedContact
    .contact-box
    .contact-details
    span:last-child {
    flex: 1;
  }
  .contacts .contactsBox .addedContact .contact-box .contact-details span {
    flex: 1;
  }
}

@media only screen and (max-width: 768px) {
  .contactList .msgforcontacts {
    padding: 10px;
  }
  .contacts .contactsBox .addedContact .contact-box {
    padding: 4px 12px;
    /* box-shadow: 0 0 2px rgba(0, 0, 0, 0.1); */
  }
  .contacts .contactsBox .addedContact .contact-box .contact-details span {
    flex: 1;
    padding: 0;
    font-size: 12px;
    line-height: normal;
  }
  .contacts .contactsBox .addedContact .contact-box .contact-details {
    flex-direction: column;
  }
  .contacts .contactsBox {
    padding: 0px 10px 15px;
  }
  .contacts .contactsBox .header {
    padding: 0;
    margin: 0;
  }
  .contacts .contactsBox .header button {
    font-size: 12px;
    padding: 6px 10px;
  }
  .contacts .contactsBox .searchBarBox {
    margin-bottom: 3px;
  }
  .contacts .contactsBox .cntsbc {
    position: sticky;
    top: 40px;
    background-color: var(--component-bg-color);
    padding: 8px 0 2px;
  }
  .contacts {
    height: auto;
    overflow: unset;
  }
  .contacts .contactsBox {
    height: auto;
  }
  .contacts .contactsBox .contactList {
    overflow-y: unset;
    height: auto;
  }
  .contacts
    .contactsBox
    .addedContact
    .contact-box
    .contact-details
    span:last-child {
    margin: 0;
  }
}

@media only screen and (max-width: 540px) {
  .contacts .contactsBox .header > div {
    display: none;
  }
  .contacts .contactsBox .header span {
    font-size: 21px;
  }
  .contacts .contactsBox .header button {
    padding: 6px 13px;
    top: 6px;
  }
  .contacts .contactsBox .addedContact .contact-box .profile-pic {
    min-width: 40px;
    width: 40px;
    height: 40px;
  }
  .contacts .contactsBox .addedContact .contact-box .initials {
    min-width: 40px;
    width: 40px;
    height: 40px;
  }
}

@media only screen and (max-width: 270px) {
  .contacts .contactsBox .header {
    flex-wrap: wrap;
  }
  .contacts .contactsBox .addedContact .contact-box {
    align-items: flex-start;
    flex-direction: column;
  }
}
