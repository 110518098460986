.topSet {
  padding-top: 12px;
  padding-right: 10px;
  overflow-y: auto;
  height: 94vh;
}

.OtherUserProfileHeader {
  position: sticky;
  top: 0;
  z-index: 800;
  padding: 9.5px 15px;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.OtherUserProfileHeader .moveback {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 3px;
  border-radius: 5px;
  width: 22px;
  height: 22px;
}

.OtherUserProfileHeader .moveback:hover {
  outline: 2px solid var(--bg-plus-txt-color);
}

.OtherUserProfileHeader .chatTextIcon {
  width: 23px;
  height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.OtherUserProfileHeader svg {
  width: 22px;
  height: 22px;
}

.OtherUserProfileHeader svg path {
  fill: var(--bg-plus-txt-color);
}

.profileMain .profilePersonalInfo .profileBlock .socialLinks {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
}

.profileMain .profilePersonalInfo .profileBlock .socialLinks .link {
  width: 24px;
  height: 24px;
  background: var(--bg-dark-color);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
  border-radius: 3.8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: 16px;
}
.profileMain .profilePersonalInfo .profileBlock .socialLinks .link svg {
  width: 12px;
  height: 12px;
}

.profileMain .profilePersonalInfo .profileBlock .socialLinks .link:hover {
  transform: scale(1.15);
}

.profileMain .profilePersonalInfo .profileBlock .socialLinks .link a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profileMain
  .profilePersonalInfo
  .profileBlock
  .socialLinks
  .link.Youtube:last-child {
  background-color: #ffffff;
}

.profileMain
  .profilePersonalInfo
  .profileBlock
  .socialLinks
  .link.Youtube:last-child
  svg {
  fill: var(--bg-dark-color);
  width: 24px;
  height: 24px;
}

.viewProfile .achievementTileImage img {
  width: 100%;
  height: auto;
}

.viewProfile .modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

.viewProfile .modalContent {
  position: relative;
  /* background-color: rgba(101, 101, 101, 0.8); */
  width: 550px;
  height: 550px;
  display: flex;
  justify-content: center;
}

.viewProfile .modalContent img {
  border-radius: 0;
  max-width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.viewProfile .closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  font-size: 14px;
  padding: 5px 10px;
  cursor: pointer;
  background-color: var(--bg-plus-txt-color);
  color: #ffffff;
  cursor: pointer;
  line-height: unset;
}

.viewProfile .react-transform-wrapper,
.viewProfile .transform-component-module_wrapper__SPB86,
.viewProfile .react-transform-component,
.viewProfile .transform-component-module_content__FBWxo {
  width: 100%;
  height: 100%;
  justify-content: center;
}

.viewProfile .react-pdf__Document,
.viewProfile.react-pdf__Page,
.viewProfile .react-pdf__Page .pdf-page {
  width: 100%;
  height: 100%;
}

.viewProfile .react-pdf__Page__textContent,
.viewProfile .react-pdf__Page__textContent.textLayer,
.viewProfile .react-pdf__Page__annotations,
.viewProfile .react-pdf__Page__annotations.annotationLayer {
  display: none !important;
}

.viewProfile .react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
  /* object-fit: contain; */
}

@media only screen and (max-width: 768px) {
  .topSet {
    height: 100%;
    overflow-y: unset;
    padding-right: 0;
  }
  .OtherUserProfileHeader {
    top: 40px;
    background: var(--component-bg-color);
    z-index: 700;
  }

  /* .profileMain .header {
    padding: 0px;
    width: auto;
    position: fixed;
    top: 10px;
    left: unset;
    right: 15px !important;
    z-index: 800;
  } */
  .OtherUserProfileHeader .moveback {
    width: 21px;
    height: 21px;
  }
  /* .profileMain .header span {
    font-size: 22px;
    line-height: normal;
  } */
  .profileMain.topSet {
    position: relative;
  }
  /* .profileMain .header .moveback {
    position: fixed;
    top: 10px;
    left: unset;
    right: 15px !important;
    z-index: 800;
  } */
  .profileMain .profilePersonalInfo .profileBlock .socialLinks .link {
    width: 22px;
    height: 22px;
  }
  .viewProfile .modalContent {
    width: 80%;
    height: 80%;
  }
}

@media only screen and (max-width: 540px) {
  .viewProfile .modalContent {
    width: 100%;
    height: 78%;
  }
}

@media only screen and (max-width: 300px) {
  .profileMain .profilePersonalInfo .profileBlock .socialLinks {
    flex-wrap: wrap;
  }
}
