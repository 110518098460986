.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive,
svg.leaflet-image-layer.leaflet-interactive path {
  border-radius: 50% !important;
}

.mapActionsRU {
  position: absolute;
  z-index: 1000;
  right: 20px;
  bottom: 75px !important;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 4px;
}

.mapActionsRU .moveToUserCurrentPosition {
  width: 40px;
  height: 40px;
  z-index: 10001;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: var(--bg-dark-color);
}

.leaflet-control-attribution {
  display: none !important;
}
.leaflet-top {
  top: unset !important;
  bottom: 25px !important;
  left: 10px;
}

.leaflet-control {
  top: unset !important;
  bottom: 0px !important;
}

.online-offline-icon,
.open-house-flag-icon,
.fire-icon {
  pointer-events: none !important;
}

.leaflet-container .leaflet-marker-pane img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 1000px) {
  .mapActionsRU {
    position: fixed;
  }
}

@media only screen and (max-width: 768px) {
  .leaflet-container {
    position: fixed !important;
  }
  .leaflet-top,
  .leaflet-control {
    bottom: 20px !important;
    position: fixed;
  }
  .goLiveRU,
  .moveToUserCurrentPosition {
    width: 30px;
    height: 30px;
  }
  .goLiveRU {
    font-size: 9.5px;
  }
}
