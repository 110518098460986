/* Catalog page layout */
.catalogpage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 0px;
  gap: 12px;
  /* animation: show 0.15s ease; */
}

@keyframes show {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.catalogpage .header {
  width: 100%;
  background-color: var(--component-bg-color);
  display: flex;
  justify-content: left;
  align-items: center;
  position: relative;
  padding: 0 22px;
}

.catalogpage .header .moveback {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
  border-radius: 5px;
}

.catalogpage .header .moveback:hover {
  outline: 2px solid var(--bg-plus-txt-color);
}

.catalogpage .header span {
  display: none;
}

.catalogpage .header button {
  position: absolute;
  right: 4px;
}

.catalogpage .catalogStatusTag {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  padding: 8px 50px;
  gap: 20px;
  width: 95%;
  margin: 0 auto;
  background: #ffffff;
  position: relative;
}

.catalogpage .catalogStatusTag input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.catalogpage .catalogStatusTag label {
  cursor: pointer;
  text-indent: -9999px;
  width: 28px;
  height: 14px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
}

.catalogpage .catalogStatusTag label:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  top: calc(50% - 12px / 2);
  right: 1px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 90px;
  transition: 0.3s;
}

.catalogpage .catalogStatusTag input:checked + label {
  background: var(--bg-plus-txt-color);
}

.catalogpage .catalogStatusTag input:checked + label:after {
  right: calc(100% - 2px);
  transform: translateX(100%);
}

.catalogpage .catalogStatusTag label:active:after {
  width: 18px;
}

.catalogpage .catalogStatusTag .onactive {
  padding: 0 2px;
}

.catalogpage .catalogStatusTag .share-button {
  border: none;
  background: transparent;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  overflow: hidden;
  cursor: pointer;
}

.catalogpage .catalogStatusTag .share-button img {
  width: 100%;
  height: 100%;
  background-size: cover;
}

.catalogpage .catalogStatusTag .onactive span {
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  text-transform: capitalize;
  color: #292929;
}

.catalogpage .catalogStatusTag .onactive .selectMode {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;
}
.catalogpage .catalogStatusTag .onactive .selectMode > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.catalogpage .catalogStatusTag .onactive .selectMode span {
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  text-transform: capitalize;
}

.catalogpage .catalogStatusTag .onactive .selectMode span.on,
.catalogpage .catalogStatusTag .onactive .selectMode.check span.off {
  color: rgba(0, 0, 0, 0.29);
}

.catalogpage .catalogStatusTag .onactive .selectMode span.off,
.catalogpage .catalogStatusTag .onactive .selectMode.check span.on {
  color: #000000;
}
.catalogpage .buttons {
  display: flex;
  justify-content: right;
  width: 98%;
}

.catalogpage .btn {
  margin: 0px 16px;
  padding: 3px 13px;
  border-radius: 5px;
  align-self: flex-end;
  background: none;
  color: var(--bg-plus-txt-color);
  border: 1px solid var(--bg-plus-txt-color);
}

.catalogpage .btn:hover {
  background-color: var(--bg-plus-txt-color);
  color: var(--txt-color);
}

.catalogpage .titleSection,
.catalogpage .descriptionSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 20px;
  gap: 10px;
  width: 100%;
}

.catalogpage .titleSection label,
.catalogpage .descriptionSection label {
  font-weight: 500;
  font-size: 16px;
  line-height: normal;
  text-transform: capitalize;
  color: var(--bg-plus-txt-color);
}

.catalogpage .titleSection input {
  width: 100%;
  height: 35px;
  background: #ffffff;
  border: 1px solid var(--bg-plus-txt-color);
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  padding: 0 10px;
  color: #797979;
  box-shadow: none;
  outline: none;
}

.catalogpage .titleSection .title {
  width: 100%;
  height: 35px;
  background: #ffffff;
  border: 1px solid var(--bg-plus-txt-color);
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  color: #797979;
  overflow-x: auto;
}

/* Description section */
.catalogpage .descriptionSection textarea {
  width: 100%;
  min-height: 120px;
  background: #ffffff;
  border: 1px solid var(--bg-plus-txt-color);
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  padding: 10px;
  color: #797979;
  box-shadow: none;
  outline: none;
  resize: none;
  /* overflow-y: auto; */
  font-family: inherit;
  white-space: break-spaces;
}

.catalogpage .descriptionSection .description {
  width: 100%;
  min-height: 120px;
  background: #ffffff;
  border: 1px solid var(--bg-plus-txt-color);
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  padding: 10px;
  color: #797979;
  box-shadow: none;
  /* overflow-y: auto; */
  white-space: break-spaces;
}

/* Images section */
.catalogpage .imagesSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  padding: 10px 40px;
  gap: 20px;
  width: 100%;
  height: auto;
}

.imageSecFullmsg {
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.catalogpage .flfimgbt {
  width: 100%;
  display: flex;
  align-items: center;
}

.catalogpage .imagesSection label {
  overflow: hidden;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: var(--bg-plus-txt-color);
  border: 2px solid var(--bg-plus-txt-color);
  color: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  align-self: center;
}

.catalogpage .imagesSection label .plusBox {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  padding: 2px 12px;
}

.catalogpage .imagesSection label:hover {
  background-color: #ffffff;
  color: var(--bg-plus-txt-color);
}

.catalogpage .imagesSection label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
}

.catalogpage .imagesSection label input:valid + .plusBox {
  color: #ffffff;
}

.catalogpage .imagesSection .catalogImage {
  width: 110px;
  height: 110px;
  background: #e6e6e6;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.catalogpage .imagesSection .catalogImage::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.catalogpage .imagesSection .catalogImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.catalogpage .imagesSection .catalogImage .deleteSign {
  position: absolute;
  top: 2px;
  right: 2px;
  padding: 2px;
  width: 22px;
  height: 22px;
  background-color: rgba(0, 0, 0, 0.6);
  background-color: var(--bg-plus-txt-color);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 210;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  cursor: pointer;
}

.catalogpage .imagesSection .catalogImage .deleteSign svg {
  width: 22px;
  height: 22px;
}

.catalogpage .imagesSection .catalogImage .deleteSign svg path {
  fill: #fff;
}

.catalogpage .image-slider {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1250;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 2000;
}

.catalogpage .image-gallery {
  max-width: 80% !important;
  margin: 0 auto !important;
}

/* Style for individual gallery items */
.catalogpage .image-gallery-content img {
  max-width: 100% !important;
  /* height: 80% !important; */
}

.catalogpage .image-gallery-slide .image-gallery-image {
  /* object-fit: cover !important; */
  height: 100% !important;
}

.catalogpage .image-gallery-thumbnail .image-gallery-thumbnail-image {
  height: 70px !important;
  object-fit: cover !important;
}

.catalogpage .close-slider {
  position: absolute;
  top: 10px;
  right: 25px;
  cursor: pointer;
  background-color: var(--bg-plus-txt-color);
  color: var(--txt-color);
  font-size: 16px;
  border-radius: 5px;
  padding: 5px 12px;
}

/* for responsive */
@media only screen and (max-width: 768px) {
  .catalogpage {
    position: fixed;
    top: 0;
    z-index: 9999;
    padding: 0 0 8px 0px;
    background-color: var(--component-bg-color);
    gap: 6px;
    height: 100%;
    overflow-y: auto;
  }
  .catalogpage .header {
    position: sticky;
    top: 0;
    z-index: 1250;
    padding: 9.5px 0;
    margin-bottom: 5px;
    background-color: var(--component-bg-color);
    box-shadow: 0 0 5px rgb(0, 0, 0, 0.3);
  }
  .catalogpage .header .moveback {
    position: absolute;
    padding: 0;
    width: 18px;
    height: 18px;
    left: 15px;
  }
  .catalogpage .header .moveback svg {
    width: 16px;
  }
  .catalogpage .header span {
    display: block;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    text-transform: capitalize;
    color: var(--bg-plus-txt-color);
    margin: auto;
  }
  .catalogpage .btn {
    margin: 0px 12px;
    padding: 3px 10px;
  }
  .catalogpage .titleSection,
  .catalogpage .descriptionSection {
    padding: 0px 14px;
    gap: 6px;
  }
  .catalogpage .titleSection label,
  .catalogpage .descriptionSection label {
    font-size: 16px;
    line-height: 22px;
  }
  .catalogpage .titleSection input,
  .catalogpage .titleSection .title {
    height: 32px;
    font-size: 13px;
  }
  .catalogpage .descriptionSection textarea,
  .catalogpage .descriptionSection .description {
    /* height: 370px; */
    font-size: 13px;
    padding: 8px;
  }
  .catalogpage .imagesSection {
    padding: 8px;
    gap: 8px;
  }
  .catalogpage .imagesSection .catalogImage {
    width: 90px;
    height: 90px;
  }
  .catalogpage .image-gallery {
    max-width: 100% !important;
  }
  .catalogpage .image-gallery-slide {
    aspect-ratio: 4/3;
  }
}

@media only screen and (max-width: 540px) {
  .catalogpage .titleSection input,
  .catalogpage .titleSection .title {
    height: 28px;
    font-size: 12px;
  }
  .catalogpage .descriptionSection textarea,
  .catalogpage .descriptionSection .description {
    font-size: 12px;
    padding: 6px;
  }
  .catalogpage .titleSection label,
  .catalogpage .descriptionSection label {
    font-size: 14px;
    line-height: normal;
  }
}

@media only screen and (max-width: 440px) {
  .catalogpage .catalogStatusTag {
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 8px 12px;
    gap: 0px;
  }
  .catalogpage .catalogStatusTag .share-button:last-child {
    margin-left: auto;
  }
  .catalogpage .buttons {
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 5px;
  }
  .catalogpage .btn {
    justify-self: right;
    font-size: 12px;
  }
}
