.createNotification {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 100%;
  padding: 0 8px 8px;
  animation: show1 0.5s cubic-bezier(0.58, -0.75, 0.17, 2.1) forwards;
  transition: 0.2s all;
}

@keyframes show1 {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.createNotification .titleSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 5px;
}

.createNotification .titleSection span {
  width: 100%;
  font-weight: 500;
  font-size: 15px;
  line-height: normal;
  text-transform: capitalize;
  color: #000000;
}

.createNotification input {
  width: 100%;
  background: #ffffff;
  font-weight: 500;
  font-size: 14px;
  /* line-height: 19px; */
  letter-spacing: 0.8px;
  color: #575757;
  border-radius: 0;
  box-shadow: none;
}

.createNotification .titleSection input {
  /* height: 29px; */
  align-self: stretch;
  padding: 5px 2px;
  color: #696969;
}

.createNotification .secondLine {
  display: flex;
  background-color: #ffffff;
  width: 100%;
}

.createNotification .secondLine > div {
  padding: 0px 10px 0 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 5px;
}

.createNotification .secondLine > div span {
  font-weight: 500;
  font-size: 15px;
  line-height: normal;
  color: #000000;
}

.createNotification .secondLine > div input {
  padding: 0px 4px 0 0;
  height: 25px;
  outline: none;
  color: #696969;
}

.createNotification .secondLine > div input:focus {
  border-bottom: 1px solid var(--bg-plus-txt-color);
}

.createNotification .para {
  width: 100%;
}

.createNotification .para textarea {
  width: 100%;
  height: 70px;
  background: #ffffff;
  border: 1px solid var(--bg-plus-txt-color);
  border-radius: 5px;
  font-weight: 400;
  padding: 4px 10px;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.8px;
  color: #797979;
  box-shadow: none;
  outline: none;
  resize: none;
  overflow-y: auto;
}

.createNotification .para span {
  font-size: 15px;
  color: var(--bg-plus-txt-color);
  height: 22px;
  display: flex;
  align-items: center;
  padding-left: 2px;
}

.createNotification .contactsTag {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: auto;
  position: relative;
}

.createNotification .contactsTag .contacts-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  max-height: 320px;
  overflow-x: auto;
  width: 280px;
  position: absolute;
  bottom: 80%;
  left: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 4px rgb(0, 0, 0, 0.4);
}

.createNotification .contactsTag .contacts-container::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.createNotification .contactsTag .contacts-container::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Background color of the track */
}

.createNotification .contactsTag .contacts-container::-webkit-scrollbar-thumb {
  background-color: #888888; /* Color of the scrollbar thumb */
  border-radius: 5px; /* Rounded corners of the thumb */
}

.createNotification
  .contactsTag
  .contacts-container::-webkit-scrollbar-thumb:hover {
  background-color: #555555; /* Color of the thumb on hover */
}

.createNotification .contactsTag .contacts-container .contact-item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  cursor: pointer;
  border-bottom: 1px solid #dcdcdc;
}
.createNotification .contactsTag .contacts-container .contact-item:hover {
  background-color: #dcdcdc;
}

/* .createNotification
  .contactsTag
  .contacts-container
  .contact-item:nth-child(even) {
  border-bottom: 1px solid #dcdcdc;
} */

.createNotification
  .contactsTag
  .contacts-container
  .contact-item
  .contactrofilePic {
  min-width: 35px;
  width: 35px;
  height: 35px;
  background-color: #dcdcdc;
  border-radius: 50%;
  box-shadow: 0 1px 1px rgb(0, 0, 0, 0.2);
  overflow: hidden;
}

.createNotification .contactsTag .contacts-container .contact-item img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.createNotification .contactsTag .contacts-container .contact-item .initials {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.createNotification .contactsTag .contacts-container .contact-item span {
  font-weight: 500;
  font-size: 13.4px;
  height: auto;
  line-height: normal;
  text-transform: capitalize;
  color: var(--bg-plus-txt-color);
}

.createNotification .contactsTag .contacts-container .contact-item p {
  font-weight: 400;
  font-size: 11.5px;
  color: #797979;
}

.createNotification button {
  text-align: center;
  padding: 6px 15px;
  font-size: 12px;
  border-radius: 5px;
  align-self: flex-end;
}

.createNotification .delChildNoti {
  margin: 8px 4px;
  align-self: center;
}

@media only screen and (max-width: 768px) {
  .createNotification .titleSection span,
  .createNotification .secondLine > div span {
    font-size: 15px;
  }
  .createNotification button {
    padding: 6px 16px;
    font-size: 13px;
  }
}
@media only screen and (max-width: 540px) {
  .createNotification {
    gap: 4px;
  }
  .createNotification button {
    padding: 5px 12px;
    font-size: 12px;
  }
  .createNotification .titleSection {
    gap: 0px;
  }
  .createNotification .titleSection input {
    height: 25px;
    padding: 4px 7px;
    font-size: 12px;
  }
  .createNotification .secondLine > div {
    padding: 0px 6.5px;
  }
  .createNotification .titleSection span {
    padding-left: 6.5px;
  }
  .createNotification .titleSection span,
  .createNotification .secondLine > div span {
    font-weight: 600;
    font-size: 13px;
  }
  .createNotification .secondLine > div input {
    font-size: 12px;
    line-height: 14px;
    height: 20px;
  }
  .para textarea {
    padding: 5px;
  }
  .createNotification .para span {
    font-size: 13px;
  }
}

@media only screen and (max-width: 440px) {
  .createNotification .secondLine {
    flex-direction: column;
    padding: 4px 0;
  }
  .createNotification .secondLine > div {
    width: 100%;
    flex: none;
    flex-grow: 1;
    padding: 0px 5px;
    gap: 0;
  }
  .createNotification .contactsTag .contacts-container {
    max-height: 300px;
    width: 100%;
    left: 0;
  }
}
