:root {
  --component-bg-color: #f5f6f7;
  --bg-plus-txt-color: #4668a8;
  --bg-dark-color: #3c5688;
  --txt-color: #ffffff;
  --hover-color: #575757;
  --fields-color: #ffffff;
  --fields2-color: #e8e8e8;
}

button {
  cursor: pointer;
  background-color: var(--bg-plus-txt-color);
  color: var(--txt-color);
  border: none;
}

input {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.11);
  border: none;
  outline: none;
}

pre {
  font-family: inherit;
}
