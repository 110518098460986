.propertReport {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  align-content: center;
  padding: 50px 25px 40px;
  gap: 14px;
  isolation: isolate;
  animation: show 0.19s ease;
  height: 100vh;
  overflow-y: auto;
}

.propertReport::-webkit-scrollbar {
  width: 8px;
}

.propertReport::-webkit-scrollbar-track {
  display: none;
}

.propertReport::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.propertReport {
  -ms-overflow-style: none;
}

.propertReportr::-ms-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

@keyframes show {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.headersp {
  width: 100%;
  /* max-width: 35rem; */
  display: flex;
  align-items: center;
  padding: 8.5px 25px;
  margin: 0px auto;
  position: absolute;
  top: 0;
  z-index: 1000;
  background-color: var(--component-bg-color);
  box-shadow: 0 1px 2px rgb(0, 0, 0, 0.2);
}

.headersp .addNew {
  cursor: pointer;
  padding: 3px 8px;
  border-radius: 5px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  background: none;
  border: 1px solid var(--bg-plus-txt-color);
  color: var(--bg-plus-txt-color);
  transition: 0.12s;
}

.headersp .addNew:hover {
  background-color: var(--bg-plus-txt-color);
  color: var(--txt-color);
}

.headersp .moveback {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
  border-radius: 5px;
}

.headersp .moveback svg {
  width: 17px;
  height: 17px;
}

.headersp .moveback:hover {
  outline: 2px solid var(--bg-plus-txt-color);
  cursor: pointer;
}

/* .propertReport .headersp .span {
  height: auto !important;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  text-transform: capitalize;
  color: var(--bg-plus-txt-color);
  width: 75%;
  margin: auto;
} */

.propertReport .content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.propertReport .content-container .editButtonForSnagging {
  text-align: right;
  margin: 5px;
  cursor: pointer;
}

.propertReport .content-container .editButtonForSnagging svg path {
  fill: var(--bg-plus-txt-color);
}

.propertReport .content-block {
  display: flex;
  align-items: center;
  padding: 0px;
  gap: clamp(18px, 6vw, 50px);
  width: 100%;
  margin-bottom: 12px;
}

.propertReport .content-block .content-image {
  width: clamp(200px, 35vw, 280px);
  height: clamp(135px, 25vw, 190px);
  background: #dcdcdc;
  /* box-shadow: 0 1px 1px rgb(0, 0, 0, 0.1); */
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}

.propertReport .content-image img {
  width: 100%;
  height: 100%;
  background-size: cover;
  object-fit: cover;
}

.propertReport .content-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0;
  gap: 5px;
  width: 70%;
  /* max-height: 280px; */
  overflow: hidden;
  /* word-break: break-all; */
}

.propertReport .content-text pre {
  font-size: 13.7px;
  line-height: normal;
  text-transform: capitalize;
  color: #797979;
  /* max-height: clamp(230px, 30vw, 280px); */
  height: auto;
  /* overflow-y: auto; */
  white-space: break-spaces;
  /* word-break: break-all; */
}

.propertReport .even {
  flex-direction: row;
}

.propertReport .odd {
  flex-direction: row-reverse;
}

.pictureModal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

.pictureModal .modalContent {
  position: relative;
  /* background-color: rgba(101, 101, 101, 0.8); */
  width: 550px;
  height: 550px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.pictureModal .modalContent img {
  border-radius: 0;
  max-width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.pictureModal .closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  font-size: 17px;
  cursor: pointer;
  color: #ffffff;
  cursor: pointer;
}

.pictureModal .react-transform-wrapper,
.pictureModal .transform-component-module_wrapper__SPB86,
.pictureModal .react-transform-component,
.pictureModal .transform-component-module_content__FBWxo {
  width: 100%;
  height: 100%;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .propertReport {
    padding: 20px 12px;
    gap: 10px;
  }
  .propertReport .headersp .span {
    font-size: 18px !important;
    line-height: normal;
  }
  .propertReport .content-text pre {
    font-weight: 400;
    font-size: 12.3px;
  }
  .headersp {
    position: sticky;
    top: 40px;
    z-index: 10;
    padding: 8.5px 20px;
  }
  .pictureModal .modalContent {
    width: 80%;
    height: 80%;
  }
}

@media only screen and (max-width: 540px) {
  /* .propertReport .header .span {
    font-size: 17px !important;
  } */
  .propertReport .content-text pre {
    font-size: 11.4px;
  }
  .propertReport .content-block .content-image {
    width: 133px;
    min-width: 133px;
    height: 122px;
  }
  .pictureModal .modalContent {
    width: 100%;
    height: 78%;
  }
}

@media only screen and (max-width: 340px) {
  .propertReport .even,
  .propertReport .odd {
    flex-direction: column;
  }
  .propertReport .content-block .content-image {
    width: 155px;
    min-width: 155px;
    height: 144px;
  }
  .propertReport .content-text {
    width: 100%;
    height: auto;
    max-height: unset;
  }
}
