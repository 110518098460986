@import url(../auth/Css/authFLowCSSConstants.css);
.popupScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.4);
  z-index: 12111;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popupScreen .popup {
  max-width: 300px;
  width: 300px;
  min-height: 100px;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 20px;
  outline: 3px solid var(--bg-plus-txt-color);
  border: 5px solid rgba(0, 0, 0, 0.4);
}

.popup .closePopup {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  outline: 2px solid var(--bg-plus-txt-color);
  background-color: var(--bg-plus-txt-color);
  transition: 0.12s all;
}

.popup .closePopup:hover {
  background: none;
}

.popup .closePopup svg {
  width: 20px;
  height: 20px;
}

.popup .closePopup svg path {
  fill: #ffffff;
}

.popup .closePopup:hover svg path {
  fill: var(--bg-plus-txt-color);
}

.popup button {
  align-self: flex-end;
  background-color: var(--bg-plus-txt-color);
  color: var(--txt-color);
  padding: 4px 12px;
  outline: 2px solid var(--bg-plus-txt-color);
  border-radius: 5px;
  transition: 0.2s all;
}

.popup button:hover {
  background: none;
  color: var(--bg-plus-txt-color);
}

.popupScreen .logoutConfirmationPopup {
  gap: 8px;
  align-items: center;
}

.popupScreen .logoutConfirmationPopup .boxContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.popupScreen .logoutConfirmationPopup .buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* ============================================= */
/* For sliding pop up */
.slidingPopup {
  position: fixed;
  top: -260px;
  left: 47%;
  z-index: 12111;
  margin: auto;
  max-width: 300px;
  width: 300px;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  outline: 2px solid var(--bg-plus-txt-color);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  animation: toggleSlide 3s ease-in-out;
}

@keyframes toggleSlide {
  0%,
  100% {
    top: -260px;
  }

  15%,
  85% {
    top: 20px;
  }
}

.slidingPopup p {
  font-size: 14px;
}

.slidingPopup button {
  padding: 4px 8px;
  font-size: 12px;
}

.slidingPopupOneline {
  position: fixed;
  left: 47%;
  margin: auto;
  max-width: 280px;
  width: 280px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
}

@media screen and (max-width: 768px) {
  .slidingPopup,
  .slidingPopupOneline {
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @keyframes toggleSlide {
    0%,
    100% {
      top: -260px;
    }

    15%,
    85% {
      top: 70px;
    }
  }
  /* .popupScreen {
    padding-bottom: 130px;
  } */
  .popupScreen .popup {
    margin: auto 10px;
  }
}

@media screen and (max-width: 340px) {
  /* .popupScreen .popup {
    max-width: unset;
    width: 100%;
  } */
  .slidingPopup,
  .slidingPopupOneline {
    max-width: unset;
    width: 100%;
    left: unset;
    transform: unset;
  }

  .popupScreen .popup button {
    padding: 4px 10px;
  }
  /* .popupScreen .logoutConfirmationPopup .buttons button:first-child {
    margin-left: 50px;
  }
  .popupScreen .logoutConfirmationPopup .buttons button:last-child {
    margin-right: 50px;
  } */
}

@media screen and (max-width: 340px) {
  /* .popupScreen .logoutConfirmationPopup .buttons button {
    margin: 0 !important;
  } */
}
