.contact-form {
  width: 100%;
  padding: 15px 20px;
  position: relative;
}

.contact-form .form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contact-form .form .header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
  position: relative;
}

.contact-form .form .header .moveback {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
  border-radius: 5px;
}

.contact-form .form .header .moveback:hover {
  outline: 2px solid var(--bg-plus-txt-color);
}

.contact-form .form .header span {
  display: none;
}

.contact-form .form .header .contactFormButtons {
  position: absolute;
  right: 20px;
  top: 0px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 5px;
}

.contact-form .form .header button {
  background-color: var(--bg-plus-txt-color);
  color: var(--txt-color);
  padding: 5px 18px;
  border-radius: 5px;
  opacity: 0.8;
  border: none;
  /* position: absolute; */
  right: 30px;
}

.contact-form .form .header button:hover {
  opacity: 1;
  /* transform: scale(0.98); */
}

/* .contact-form .form .header button:nth-child(2) {
  top: 40px;
}

.contact-form .form .header button:nth-child(1) {
  top: 0px;
} */

.contact-form .form .profile-pic-container {
  width: 160px;
  height: 160px;
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  background-color: var(--fields2-color);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  margin: 15px 0;
}

.contact-form .form .profile-pic-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
}

.contact-form .form .profile-pic-container .profile-pic-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-form .form .profile-pic-container input[type="file"] {
  display: none;
}

.contact-form .form .profile-pic-container svg {
  width: 34px;
  height: 34px;
  position: absolute;
  bottom: 10px;
  right: 0px;
}

.contact-form .form .inputGroupLine {
  width: 88%;
  margin: 0 auto 15px auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contact-form .form .inputGroupLine .inputGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 3px;
  width: 45%;
}

.contact-form .form .inputGroupLine .inputGroup label {
  display: block;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #373737;
}

.contact-form .form .inputGroupLine .inputGroup input {
  width: 100%;
  padding: 10px 12px;
  background-color: var(--fields2-color);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  border: none;
}

.contact-form .form .inputGroupLine .inputGroup input:focus {
  outline: 1px solid var(--bg-plus-txt-color);
}

.contact-form .form .inputGroupLine .inputGroup .labelline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.contact-form .form .textArea {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 5px;
  width: 88%;
}

.contact-form .form .textArea label {
  display: block;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #373737;
}

.contact-form .form .textArea textarea {
  width: 100%;
  height: 124px;
  padding: 8px 10px;
  background: #f0f0f0;
  border: none;
  outline: 1px solid var(--bg-plus-txt-color);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  resize: none;
}

.contact-form .error {
  outline: 1px solid #ff0000 !important;
}
.contact-form .error-message {
  color: #ff0000;
}

.contact-form .reminderBox {
  width: 88%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--fields-color);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.49);
  border-radius: 10px;
  padding: 17px 20px;
  margin: 22px auto 0 auto;
}

.contact-form .reminderBox span {
  font-weight: 700;
  font-size: 18px;
  line-height: normal;
  text-transform: capitalize;
  color: var(--bg-plus-txt-color);
}

.contact-form .reminderBox button {
  cursor: pointer;
  border: none;
  width: 24px;
  height: 24px;
}
.contact-form .reminderBox button svg {
  width: 24px;
  height: 24px;
}

@media only screen and (max-width: 1010px) {
  .contact-form .form .inputGroupLine,
  .contact-form .form .textArea,
  .contact-form .reminderBox {
    width: 92%;
  }
  .contact-form .reminderBox {
    margin: 12px auto;
    padding: 15px;
  }
  .contact-form .form .inputGroupLine .inputGroup {
    width: 48%;
  }
  .contact-form .form .textArea textarea {
    height: 115px;
  }
}
@media only screen and (max-width: 768px) {
  .contact-form {
    padding: 0 0 12px;
    animation: slideFromBottomToTop 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55)
      forwards;
    position: fixed;
    top: 0;
    z-index: 2000;
    height: 100%;
    background: #f5f6f7;
    overflow-y: auto;
  }
  @keyframes slideFromBottomToTop {
    0% {
      transform: translatey(100%);
    }
    100% {
      transform: translatey(0);
    }
  }
  .contact-form .form .header {
    display: flex;
    justify-content: center;
    padding: 9.5px 0;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.11);
  }
  .contact-form .form .header span {
    display: block;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: normal;
    text-transform: capitalize;
    color: var(--bg-plus-txt-color);
  }
  .contact-form .form .header .moveback {
    position: absolute;
    z-index: 750;
    left: 15px;
  }
  .contact-form .form .header .moveback svg {
    width: 16px;
    height: 16px;
  }
  .contact-form .form .header .contactFormButtons {
    top: 9px;
    gap: 20px;
    right: 12px;
  }
  .contact-form .form .header button:nth-child(2) {
    top: 44px;
  }
  .contact-form .form .profile-pic-container {
    width: 150px;
    height: 150px;
  }
  .contact-form .form .inputGroupLine .inputGroup input {
    padding: 7px 12px;
    font-size: 13px;
  }
  .contact-form .error-message {
    font-size: 13px;
  }
  .contact-form .form .textArea textarea {
    height: 105px;
  }
  .contact-form .form .header button {
    padding: 5px 18px;
  }
}
@media only screen and (max-width: 540px) {
  .contact-form .form .profile-pic-container {
    width: 140px;
    height: 140px;
  }
  .contact-form .form .profile-pic-container svg {
    width: 30px;
    height: 30px;
  }
  .contact-form .form .inputGroupLine .inputGroup {
    gap: 2px;
  }
  .contact-form .form .inputGroupLine .inputGroup .labelline,
  .contact-form .form .inputGroupLine .inputGroup label,
  .contact-form .form .textArea label {
    font-size: 13.5px;
  }
  .contact-form .form .textArea label {
    font-size: 13.5px;
    line-height: normal;
  }
  .contact-form .form .inputGroupLine .inputGroup input {
    padding: 6.5px 11px;
    font-size: 12.5px;
  }
  .contact-form .form .inputGroupLine {
    width: 92%;
    margin: 0 0 12px 0;
    gap: 10px;
    justify-content: center;
  }
  .contact-form .form .inputGroupLine .inputGroup {
    width: 75%;
  }
  .contact-form .form .textArea textarea {
    height: 95px;
    padding: 5px 8px;
    font-size: 12.5px;
  }
  .contact-form .reminderBox {
    padding: 12px 10px;
    margin: 10px auto 0 auto;
  }
  .contact-form .reminderBox span {
    font-size: 16px;
  }
  .contact-form .reminderBox button {
    width: 18px;
    height: 18px;
  }
  .contact-form .reminderBox button svg {
    width: 18px;
    height: 18px;
  }
  .contact-form .form .header button {
    padding: 5px 15px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 440px) {
  .contact-form .form .header button {
    padding: 5px 15px;
  }
  .contact-form .form .profile-pic-container {
    width: 120px;
    height: 120px;
  }
  .contact-form .form .profile-pic-container svg {
    width: 25px;
    height: 25px;
    right: 2px;
  }
  .contact-form .form .inputGroupLine {
    margin: 0 0 6px 0;
    gap: 4px;
  }
  .contact-form .form .inputGroupLine .inputGroup {
    width: 100%;
  }
  .contact-form .form .textArea textarea {
    height: 88px;
  }
  .contact-form .reminderBox span {
    font-size: 14px;
  }
  .contact-form .error-message {
    margin-bottom: -3px;
  }
}
