.contactsTileToSelectForChats {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 394px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 5px 8px;
  background-color: #ffffff;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  animation: slideFromBottomToTop 0.15s ease;
}

@keyframes slideFromBottomToTop {
  0% {
    transform: translatey(100%);
  }
  100% {
    transform: translatey(0);
  }
}

.contactsTileToSelectForChats .contactsCountLine {
  padding: 0 8px 3px;
}

.contactsTileToSelectForChats .contactsCountLine span {
  color: #444746;
  font-weight: 500;
  font-size: 12px;
  line-height: normal;
}

.contactsTileToSelectForChats .addedContact {
  padding: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  border-block: 1px solid var(--bg-plus-txt-color);
  padding-bottom: 2px;
}

.contactsTileToSelectForChats .addedContact::-webkit-scrollbar {
  width: 8px;
}

.contactsTileToSelectForChats .addedContact::-webkit-scrollbar-thumb {
  background-color: #7f7f7f;
  border-radius: 6px;
}

.contactsTileToSelectForChats .addedContact::-webkit-scrollbar-thumb:hover {
  background-color: #5e5e5e;
}

.contactsTileToSelectForChats .addedContact .contact-box {
  display: flex;
  align-items: center;
  padding: 5px 2px;
  width: 100%;
  min-height: 48px;
  background-color: #ffffff;
  border-bottom: 1px solid #c4c7c5;
  cursor: pointer;
  transition: 0.3s all;
}

.contactsTileToSelectForChats .addedContact .contact-box:hover {
  background-color: #dcdcdc;
}

.contactsTileToSelectForChats .addedContact .contact-box:last-child {
  margin-bottom: 55px;
}

.contactsTileToSelectForChats .addedContact .contact-box .profile-pic {
  min-width: 35px;
  width: 35px;
  height: 35px;
  border-radius: 18px;
  overflow: hidden;
  margin-right: 10px;
  background-color: #dcdcdc;
}

.contactsTileToSelectForChats .addedContact .contact-box .profile-pic img {
  width: 100%;
  height: 100%;
  border-radius: 18px;
  background-size: cover;
  object-fit: cover;
}

.contactsTileToSelectForChats .addedContact .contact-box .initials {
  min-width: 35px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background-color: #ccc;
  color: #fff;
}

.contactsTileToSelectForChats .addedContact .contact-box .contact-details {
  display: flex;
  flex-direction: column;
  gap: 0;
  width: 100%;
  overflow: hidden;
}

.contactsTileToSelectForChats .addedContact .contact-box .contact-details span {
  height: unset;
  padding: 0px;
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
  color: #444746;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.contactsTileToSelectForChats .addedContact .msgforcontacts {
  display: flex;
  justify-content: center;
  padding: 12px 10px;
  /* background-color: #ffffff; */
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); */
}

.contactsTileToSelectForChats .addedContact .msgforcontacts span {
  font-size: 15px;
  font-weight: 400;
  color: #797979;
}

.contactsTileToSelectForChats .addedContact .ldtxt {
  width: 100%;
  font-size: 1.15rem;
  display: flex;
  justify-content: center;
  margin: auto;
}

@media only screen and (max-width: 768px) {
  .contactsTileToSelectForChats {
    top: 85px;
    height: 90%;
  }
}

@media only screen and (max-width: 540px) {
  .contactsTileToSelectForChats
    .addedContact
    .contact-box
    .contact-details
    span {
    line-height: normal;
    font-size: 12px;
  }
}
