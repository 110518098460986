@import url(../../../auth/Css/authFLowCSSConstants.css);

.changePasswordContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 15px 25px;
  overflow: hidden;
  overflow-y: auto;
}

.changePasswordContainer .headerLine {
  width: 96%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--component-bg-color);
  padding: 9.5px 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 auto 10px;
  position: sticky;
  top: 0px;
  z-index: 25;
}

.changePasswordContainer .headerLine .moveback {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 3px;
  border-radius: 5px;
  width: 22px;
  height: 22px;
  position: absolute;
}

.changePasswordContainer .headerLine .moveback:hover {
  outline: 2px solid var(--bg-plus-txt-color);
}

.changePasswordContainer .headerLine span {
  display: block;
  width: auto;
  height: auto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: normal;
  text-align: center;
  text-transform: capitalize;
  color: var(--bg-plus-txt-color);
  margin: auto;
}

.changePasswordContainer .cpContainerBox,
.changePasswordContainer .artboard,
.changePasswordContainer .box,
.changePasswordContainer .box form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.changePasswordContainer .cpContainerBox {
  gap: 30px;
}

.changePasswordContainer .artboard .artboardPic {
  width: 80px;
  height: 80px;
  margin: 10px;
}

.changePasswordContainer .artboard .artboardPic img {
  width: 100%;
}

.changePasswordContainer .artboard span {
  font-size: 14px;
  line-height: normal;
  color: #000000;
}

.changePasswordContainer .box {
  background-color: #ffffff;
  padding: 10px 12px;
  border-radius: 10px;
  color: var(--box-txt-color);
  width: 350px;
}

/* Form styling */
.changePasswordContainer .box form {
  padding: 8px;
  width: 100%;
}

.changePasswordContainer .box form label {
  font-size: 14px;
  font-weight: 500;
}

/* Input fields styling */
.changePasswordContainer .box .input-group {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.changePasswordContainer .box input {
  padding: 7px 8px;
  margin: 5px 0;
  font-size: 14px;
  width: 100%;
  background: #f3f3f3;
  outline: none;
}

.changePasswordContainer .box input::placeholder {
  font-size: 12px;
  color: #797979;
}

.changePasswordContainer .box input:focus {
  outline: 2px solid var(--bg-plus-txt-color);
}

.changePasswordContainer .box .pos-rel {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.changePasswordContainer .box .pos-rel .password-toggle {
  position: absolute;
  background: #f3f3f3;
  right: 4px;
  top: 9px;
  width: 40px;
  height: 25px;
  /* padding: 2px 8px 0; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.changePasswordContainer .box .error {
  color: var(--error-color);
  padding: 4px;
  font-size: 14px;
  line-height: normal;
}

/* Button styling */
.changePasswordContainer .box button {
  padding: 5px 18px;
  border: none;
  cursor: pointer;
  font-size: 15px;
  border-radius: 6px;
  border: 2px solid var(--bg-plus-txt-color);
  transition: 0.12s all;
  margin-top: 8px;
}

.changePasswordContainer .box button:hover {
  background-color: #ffffff;
  color: var(--bg-plus-txt-color);
}

/* Links styling */
.changePasswordContainer .box a {
  text-decoration: none;
  color: var(--box-txt-color);
  font-weight: 600;
  width: 130px;
  margin-left: 5px;
}

.changePasswordContainer .box a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .changePasswordContainer {
    height: 88%;
    padding: 0 0 25px;
  }
  .changePasswordContainer .headerLine {
    width: 100%;
  }
  .changePasswordContainer .box {
    width: 350px;
    box-shadow: none;
    background: none;
  }

  .changePasswordContainer .artboard span {
    font-size: 0.9rem;
  }
  .changePasswordContainer .box input {
    padding: 8px;
    margin-bottom: 8px;
    font-size: 13px;
  }
  .changePasswordContainer .box form label {
    font-weight: 700;
  }
}

@media screen and (max-width: 540px) {
  .changePasswordContainer .artboard .artboardPic {
    width: 75px;
    height: 75px;
  }
  .changePasswordContainer .artboard span {
    font-size: 0.8rem;
  }
  .changePasswordContainer .box button {
    font-size: 14px;
  }
}

@media screen and (max-width: 340px) {
  .changePasswordContainer .box {
    width: 100%;
  }
}
