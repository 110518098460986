.containerfRAI {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  overflow: hidden;
  overflow-y: auto;
}

.containerfRAI .headerLine {
  width: 96%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--component-bg-color);
  padding: 9.5px 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 auto 10px;
  position: sticky;
  top: 0px;
  z-index: 25;
}

.containerfRAI .headerLine .moveback {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 3px;
  border-radius: 5px;
  width: 22px;
  height: 22px;
  position: absolute;
}

.containerfRAI .headerLine .moveback:hover {
  outline: 2px solid var(--bg-plus-txt-color);
}

.containerfRAI .headerLine span {
  display: block;
  width: auto;
  height: auto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: normal;
  text-align: center;
  text-transform: capitalize;
  color: var(--bg-plus-txt-color);
  margin: auto;
}

.containerfRAI .cfsais {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  border-radius: 5px;
}

.containerfRAI label {
  display: block;
  margin-bottom: 15px;
  color: var(--bg-plus-txt-color);
  font-size: 14px;
  font-weight: 500;
}

.containerfRAI input,
.containerfRAI textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--bg-plus-txt-color);
  background-color: #ffffff;
  border-radius: 6px;
  box-sizing: border-box;
  box-shadow: none;
  outline: none;
  margin-top: 4px;
}

.containerfRAI textarea {
  height: 145px;
  resize: none;
  font-size: inherit;
  font-family: inherit;
}

.containerfRAI input[type="submit"] {
  width: auto;
  align-self: center;
  padding: 4.5px 14px;
  background-color: var(--bg-plus-txt-color);
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  outline: 2px solid var(--bg-plus-txt-color);
  font-size: 13.5px;
}

.containerfRAI input[type="submit"]:hover {
  background-color: var(--component-bg-color);
  color: var(--bg-plus-txt-color);
}

.containerfRAI .error {
  color: #ff0000;
  font-weight: 400;
  margin-left: 4px;
}

@media screen and (max-width: 768px) {
  .containerfRAI {
    height: 88%;
    padding: 0 0 25px;
  }
  .containerfRAI .headerLine {
    width: 100%;
  }
}
