.chat-overlay {
  width: 320px;
  min-height: 480px;
  height: 480px;
  animation: chat 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards;
  transition: 0.2s all;
  overflow: hidden;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--component-bg-color);
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.45);
  border-radius: 6px;
  z-index: 1300;
}

@keyframes chat {
  0% {
    transform: translateY(100%); /* Start off-screen at the bottom */
  }
  100% {
    transform: translateY(0%); /* Move to the final position at the top */
  }
}

.chat-overlay .header {
  width: 100%;
  min-height: 40px;
  background-color: var(--component-bg-color);
  /* box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.11); */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 10px;
  box-shadow: 0 0 5px rgb(0, 0, 0, 0.3);
  margin-bottom: 10px;
}

.chat-overlay .header .moveback {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  position: absolute;
  right: 15px;
  outline: 1px solid var(--bg-plus-txt-color);
}

.chat-overlay .header .contactshow {
  width: 18px;
  height: 18px;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  position: absolute;
  left: 15px;
}

.chat-overlay .header .contactshow:hover,
.chat-overlay .header .moveback:hover {
  outline: 2px solid var(--bg-plus-txt-color);
}

.chat-overlay .header .moveback svg {
  width: 20px;
  height: 20px;
}

/* .chat-overlay .header .contactshow svg {
  width: 20px;
  height: 20px;
} */

.chat-overlay .header .moveback svg path {
  fill: var(--bg-plus-txt-color);
}

.chat-overlay .header span {
  /* height: 32px; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: normal;
  text-align: center;
  text-transform: capitalize;
  color: var(--bg-plus-txt-color);
  margin: auto;
}

.chat-overlay .searchBarBox {
  margin: 4px auto 10px;
}

.chat-overlay .searchBarBox input[type="search"] {
  height: 32px !important;
  padding: 0 1.6rem;
  font-size: 12px;
  border: 1px solid var(--bg-plus-txt-color);
}

.chat-overlay .searchBarBox .searchIcon svg {
  width: 11px;
  height: 11px;
}

.chat-overlay .searchBarBox .searchIcon {
  top: 10.5px;
  left: 8px;
}

.mtsfngc {
  margin: 40px auto;
  font-size: 14px;
  width: max-content;
}
.chat-overlay .plusSignBoxButton {
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.2));
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
}

.chat-overlay .plusSignBoxButton svg {
  width: 30px;
  height: 30px;
}

.chatPictureModal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

.chatPictureModal .modalContent {
  position: relative;
  /* background-color: rgba(101, 101, 101, 0.8); */
  width: 550px;
  height: 550px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.chatPictureModal .modalContent img {
  border-radius: 0;
  max-width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.chatPictureModal .closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10000;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  color: #ffffff;
  background-color: var(--bg-plus-txt-color);
  cursor: pointer;
  line-height: normal;
  border-radius: 5px;
}

.chatPictureModal .react-transform-wrapper,
.chatPictureModal .transform-component-module_wrapper__SPB86,
.chatPictureModal .react-transform-component,
.chatPictureModal .transform-component-module_content__FBWxo {
  width: 100%;
  height: 100%;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .chat-overlay {
    width: 100%;
    min-height: unset;
    height: 100%;
    bottom: unset;
    right: unset;
    /* left: 0; */
    border-radius: 0px;
    /* animation: onFullPage 0.12s forwards; */
    animation: none;
    transition: none;
  }
  /* @keyframes onFullPage {
    0% {
      width: 90%;
      height: 90%;
      bottom: 15px;
      right: 15px;
    }
    50% {
      bottom: 10px;
      right: 10px;
    }
    100% {
      width: 100%;
      height: 100%;
      bottom: 0;
      right: 0;
    }
  } */
  .chat-overlay .header {
    padding: 9.5px 10px;
  }
  .chat-overlay .plusSignBoxButton {
    right: 25px;
    width: 35px;
    height: 35px;
  }
  .chat-overlay .plusSignBoxButton svg {
    width: 35px;
    height: 35px;
  }
  .chatPictureModal .modalContent {
    width: 100%;
    height: 80%;
  }
}
