@import url(./authFLowCSSConstants.css);

.container .bx {
  padding: 28px;
  margin: 20px;
}

.container .bx form .input-group {
  flex-direction: row;
  width: auto;
  justify-content: center;
}

.container .bx form .input-group input {
  width: 35px;
  text-align: center;
}

.container .bx form .btn-nbg {
  background: none;
  padding: 4px;
  margin: 0.4rem auto;
  color: var(--box-txt-color);
  transform: scale(1);
}

.container .bx form .btn-nbg:hover {
  text-decoration: underline;
}

.container .bx form button {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 540px) {
  .container .bx .input-group {
    flex-wrap: wrap;
  }

  .container .bx form .btn-nbg {
    font-size: 14px;
  }
  .container .box button {
    padding: 7px 18px;
    /* width: 110px; */
  }
}
