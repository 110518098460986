.profileMain {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding: 20px 0;
}

.profileMain .header {
  width: 100%;
  background-color: var(--component-bg-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 10px 0 10px;
}

.profileMain .header span {
  height: 32px;
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 32px;
  text-align: center;
  text-transform: capitalize;
  color: var(--bg-plus-txt-color);
  margin: auto;
}

.profileMain .availableOfferTag {
  width: 100%;
  min-height: 55px;
  padding: 7px;
  background-color: var(--bg-plus-txt-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.profileMain .availableOfferTag p {
  width: 70%;
  margin: auto;
  font-size: 14.5px;
  opacity: 1;
  color: var(--txt-color);
  text-align: center;
  animation: blink 2.5s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

.editButton {
  cursor: pointer;
}

.profileMain .availableOfferTag .tagbtn {
  position: absolute;
  top: 5px;
  right: 20px;
  padding: 2px;
}

.profileMain .availableOfferTag .tagbtn.saveButton {
  border: 1px solid #ffffff;
  padding: 2px 10px;
  right: 5px;
}

.profileMain .availableOfferTag textarea {
  width: 72%;
  resize: none;
  overflow-y: auto;
  background-color: var(--component-bg-color);
  color: #797979;
  border: none;
  outline: none;
  padding: 7px;
  font-size: 14px;
  font-family: inherit;
}

.profileMain .activeStatusTag {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px 25px;
  gap: 15px;
  width: 100%;
  background: #ffffff;
  position: relative;
}

.profileMain .activeStatusTag input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.profileMain .activeStatusTag label {
  cursor: pointer;
  text-indent: -9999px;
  width: 28px;
  height: 14px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
}

.profileMain .activeStatusTag label:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  top: calc(50% - 12px / 2);
  right: 1px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 90px;
  transition: 0.3s;
}

.profileMain .activeStatusTag input:checked + label {
  background: var(--bg-plus-txt-color);
}

.profileMain .activeStatusTag input:checked + label:after {
  right: calc(100% - 2px);
  transform: translateX(100%);
}

.profileMain .activeStatusTag label:active:after {
  width: 18px;
}

.profileMain .activeStatusTag .onactive,
.profileMain .activeStatusTag .houseActive {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 280px;
}

.profileMain .activeStatusTag .onactive span,
.profileMain .activeStatusTag .houseActive span {
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  text-transform: capitalize;
  color: #292929;
}

.profileMain .activeStatusTag .onactive .selectMode,
.profileMain .activeStatusTag .houseActive .selectMode {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;
}
.profileMain .activeStatusTag .onactive .selectMode > div,
.profileMain .activeStatusTag .houseActive .selectMode > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profileMain .activeStatusTag .onactive .selectMode span,
.profileMain .activeStatusTag .houseActive .selectMode span {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  text-transform: capitalize;
}

.profileMain .activeStatusTag .onactive .selectMode span.on,
.profileMain .activeStatusTag .houseActive .selectMode span.on {
  color: rgba(0, 0, 0, 0.29);
}

.profileMain .activeStatusTag .onactive .selectMode span.off,
.profileMain .activeStatusTag .houseActive .selectMode span.off {
  color: #000000;
}

.profileMain .activeStatusTag .onactive .selectMode.check span.on,
.profileMain .activeStatusTag .houseActive .selectMode.check span.on {
  color: #000000;
}

.profileMain .activeStatusTag .onactive .selectMode.check span.off,
.profileMain .activeStatusTag .houseActive .selectMode.check span.off {
  color: rgba(0, 0, 0, 0.29);
}

.profileMain .activeStatusTag .line {
  width: 35px;
  height: 0px;
  border: 1px solid #d2d2d2;
  transform: rotate(-90deg);
}

/*profile & PersonalInfo  */
.profileMain .profilePersonalInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  width: 100%;
}

.profileMain .profilePersonalInfo .profileBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 40px;
  gap: 10px;
  /* width: 240px; */
  height: auto;
  flex-grow: 1;
  position: relative;
}

.profileMain .profilePersonalInfo .profileBlock .profilePic {
  min-width: 142px;
  width: 142px;
  height: 142px;
  border-radius: 50%;
  background-color: #cccccc;
  position: relative;
}

.profileMain .profilePersonalInfo .profileBlock .profilePic img {
  width: 100%;
  height: 100%;
  background-size: cover;
  object-fit: cover;
  background-position: center;
  border-radius: 50%;
}

.profileMain .profilePersonalInfo .profileBlock .profilePic svg {
  position: absolute;
  right: 8px;
  bottom: 2px;
  width: 30px;
}

.profileMain .profilePersonalInfo .profileBlock .profilePic .initials {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  color: #292929;
}

.profileMain .profilePersonalInfo .personalInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
}

.profileMain .profilePersonalInfo .personalInfo .headerLine {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
}

.profileMain .profilePersonalInfo .personalInfo .headerLine span {
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  text-transform: capitalize;
  color: var(--bg-plus-txt-color);
}
.profileMain
  .profilePersonalInfo
  .personalInfo
  .headerLine
  .editButton
  svg
  path {
  fill: var(--bg-plus-txt-color);
}

.profileMain .profilePersonalInfo .personalInfo .firstLinePersonalInfo,
.profileMain .profilePersonalInfo .personalInfo .secondLinePersonalInfo,
.profileMain .socialLinksTag .socialLinks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 20px;
  gap: 6px;
  background-color: #ffffff;
  width: 100%;
}

.profileMain .profilePersonalInfo .personalInfo .firstLinePersonalInfo .row,
.profileMain .profilePersonalInfo .personalInfo .secondLinePersonalInfo .row,
.profileMain .socialLinksTag .socialLinks .row {
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  width: 100%;
}

.saveButton {
  padding: 2px 10px;
  background: none;
  color: var(--bg-plus-txt-color);
  border: 1px solid var(--bg-plus-txt-color);
  border-radius: 5px;
}
.saveButton:hover {
  background-color: var(--bg-plus-txt-color);
  color: var(--txt-color);
}

.profileMain .availableOfferTag button {
  color: #ffffff;
}

.profileMain
  .profilePersonalInfo
  .personalInfo
  .firstLinePersonalInfo
  .row
  span,
.profileMain
  .profilePersonalInfo
  .personalInfo
  .secondLinePersonalInfo
  .row
  span,
.profileMain .socialLinksTag .socialLinks .row span {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  text-transform: capitalize;
  color: #292929;
  min-width: 155px;
}
.profileMain .profilePersonalInfo .personalInfo .firstLinePersonalInfo .row p,
.profileMain .profilePersonalInfo .personalInfo .secondLinePersonalInfo .row p,
.profileMain .socialLinksTag .socialLinks .row p {
  font-weight: 400;
  font-size: 13.5px;
  line-height: 19px;
  /* height: 19px; */
  text-transform: capitalize;
  color: #797979;
}

.profileMain .socialLinksTag .socialLinks .row p {
  text-transform: none;
}

/* make personal profile edit able*/
.profileMain
  .profilePersonalInfo
  .personalInfo
  .firstLinePersonalInfo
  .row
  input,
.profileMain
  .profilePersonalInfo
  .personalInfo
  .secondLinePersonalInfo
  .row
  input,
.profileMain .socialLinksTag .socialLinks .row input {
  background-color: var(--input-field-color);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.11);
  border: none;
  outline: none;
  border-radius: 10px;
  padding: 2px 10px;
  box-shadow: none;
  border-radius: 0;
  flex-grow: 1;
}

.profileMain .socialLinksTag .socialLinks .row {
  position: relative;
}

.profileMain .socialLinksTag .socialLinks .row .invalidInputIcon {
  position: absolute;
  left: 90px;
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profileMain .socialLinksTag .socialLinks .row .invalidInputIcon svg {
  width: 14px;
  height: 14px;
}

.profileMain .socialLinksTag .socialLinks .row .invalidInputIcon svg path {
  fill: #ff0000;
}

/* (Social Links + About Info + Catalog Content + Broker Services Details + achievement Details + reviews) headerline style*/
.profileMain .socialLinksTag,
.profileMain .aboutmeInfo,
.profileMain .catalogInfo,
.profileMain .brokerServicesDetails,
.profileMain .achievementTag,
.profileMain .reviewsTag {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* padding: 10px 0px; */
  gap: 12px;
  width: 100%;
  height: auto;
}

.profileMain .socialLinksTag .headerInfoLine,
.profileMain .aboutmeInfo .headerInfoLine,
.profileMain .catalogInfo .headerInfoLine,
.profileMain .brokerServicesDetails .headerInfoLine,
.profileMain .achievementTag .headerInfoLine,
.profileMain .reviewsTag .headerInfoLine {
  width: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profileMain .socialLinksTag .headerInfoLine .heading,
.profileMain .aboutmeInfo .headerInfoLine .heading,
.profileMain .catalogInfo .headerInfoLine .heading,
.profileMain .brokerServicesDetails .headerInfoLine .heading,
.profileMain .achievementTag .headerInfoLine .heading,
.profileMain .reviewsTag .headerInfoLine .heading {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.profileMain .aboutmeInfo .headerInfoLine .heading .infoIcon,
.profileMain .catalogInfo .headerInfoLine .heading .catalogIcon,
.profileMain .brokerServicesDetails .headerInfoLine .heading .homeIcon,
.profileMain .achievementTag .headerInfoLine .heading .achievementIcon,
.profileMain .reviewsTag .headerInfoLine .heading .starIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profileMain .aboutmeInfo .headerInfoLine .heading .infoIcon svg,
.profileMain .catalogInfo .headerInfoLine .heading .catalogIcon svg,
.profileMain .brokerServicesDetails .headerInfoLine .heading .homeIcon svg,
.profileMain .achievementTag .headerInfoLine .heading .achievementIcon svg,
.profileMain .reviewsTag .headerInfoLine .heading .starIcon svg {
  width: 20px;
  height: 20px;
}

.profileMain .aboutmeInfo .headerInfoLine .heading .infoIcon svg path,
.profileMain .catalogInfo .headerInfoLine .heading .catalogIcon svg path,
.profileMain .brokerServicesDetails .headerInfoLine .heading .homeIcon svg path,
.profileMain
  .achievementTag
  .headerInfoLine
  .heading
  .achievementIcon
  svg
  path {
  fill: var(--bg-plus-txt-color);
}

.profileMain .reviewsTag .headerInfoLine .heading .starIcon svg path {
  stroke: var(--bg-plus-txt-color);
}

.profileMain .socialLinksTag .headerInfoLine .heading span,
.profileMain .aboutmeInfo .headerInfoLine .heading span,
.profileMain .catalogInfo .headerInfoLine .heading span,
.profileMain .brokerServicesDetails .headerInfoLine .heading span,
.profileMain .achievementTag .headerInfoLine .heading span,
.profileMain .reviewsTag .headerInfoLine .heading span {
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  text-transform: capitalize;
  color: var(--bg-plus-txt-color);
}

.profileMain .socialLinksTag .headerInfoLine .editButton,
.profileMain .aboutmeInfo .headerInfoLine .editButton,
.profileMain .catalogInfo .headerInfoLine .editButton,
.profileMain .brokerServicesDetails .headerInfoLine .editButton,
.profileMain .reviewsTag .headerInfoLine .editButton {
  height: 20px;
}

.profileMain .socialLinksTag .headerInfoLine .editButton svg path,
.profileMain .aboutmeInfo .headerInfoLine .editButton svg path,
.profileMain .catalogInfo .headerInfoLine .editButton svg path,
.profileMain .brokerServicesDetails .headerInfoLine .editButton svg path {
  fill: var(--bg-plus-txt-color);
}

.profileMain .aboutmeInfo .aboutInfoPara,
.profileMain .brokerServicesDetails .brokerServicesPara {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 20px;
  gap: 10px;
  width: 100%;
  min-height: 60px;
  background: #ffffff;
}

.profileMain .aboutmeInfo .aboutInfoPara p,
.profileMain .brokerServicesDetails .brokerServicesPara p {
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-transform: capitalize;
  color: #797979;
  white-space: break-spaces;
  /* line-break: anywhere; */
  /* word-break: break-all; */
}

/* about editable */
.profileMain .aboutmeInfo .aboutInfoPara textarea,
.profileMain .brokerServicesDetails .brokerServicesPara textarea {
  width: 100%;
  resize: none;
  overflow-y: auto;
  background-color: var(--component-bg-color);
  color: #797979;
  border: none;
  outline: none;
  padding: 7px 12px;
  font-size: 14px;
  font-family: inherit;
}

/* catalog content */
.profileMain .catalogInfo .catalogs {
  width: 100%;
  display: flex;
  background-color: #ffffff;
  position: relative;
}

.profileMain .catalogInfo .catalogs .catalogsImages {
  width: 100%;
  min-height: 70px;
  display: flex;
  gap: 10px;
  padding: 6px 20px;
  transition: all 0.3s ease-in-out;
  scroll-behavior: smooth;
  overflow: hidden;
  overflow-x: auto;
}
.profileMain .catalogInfo .catalogs .catalogsImages .catalogsImage {
  padding: 0px;
  min-width: 112px;
  width: 112px;
  height: 112px;
  background-color: rgba(0, 0, 0, 0.12);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.profileMain .catalogInfo .catalogs .catalogsImages .catalogsImage img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-size: cover;
  object-fit: cover;
}

.profileMain .catalogInfo .catalogs .catalogsImages .catalogsImage .plusBox {
  width: 130px;
  height: 130px;
  background-color: rgba(185, 185, 185, 0.16);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 40px;
  color: var(--bg-plus-txt-color);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}
/* 
.profileMain .catalogInfo .catalogs .moveLeft,
.profileMain .catalogInfo .catalogs .moveRight {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 50px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

.profileMain .catalogInfo .catalogs .moveLeft {
  left: 0;
}

.profileMain .catalogInfo .catalogs .moveRight {
  right: 0;
} */

.profileMain .catalogInfo .headerInfoLine .editButton.savebtn,
.profileMain .achievementTag .headerInfoLine .editButton.savebtn,
.profileMain .reviewsTag .headerInfoLine .editButton.savebtn {
  width: 48px;
  height: 21px;
  color: var(--bg-plus-txt-color);
  border: 1px solid var(--bg-plus-txt-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 13.5px;
}

.profileMain .catalogInfo .headerInfoLine .editButton.savebtn:hover,
.profileMain .achievementTag .headerInfoLine .editButton.savebtn:hover,
.profileMain .reviewsTag .headerInfoLine .editButton.savebtn:hover {
  background-color: var(--bg-plus-txt-color);
  color: var(--txt-color);
}

.profileMain .achievementTag .achievementTilesBox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 20px;
  gap: 12px;
  width: 100%;
  min-height: 70px;
  height: auto;
  overflow-x: auto;
  /* background-color: #ffffff; */
}

.profileMain .achievementTag .achievementTilesBox .achievementTile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 2px;
  width: 120px;
  min-width: 120px;
  height: 125px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
}

.profileMain
  .achievementTag
  .achievementTilesBox
  .achievementTile
  .achievementTileImage {
  width: 100%;
  height: 90px;
  min-height: 90px;
  background: #e6e6e6;
  border-radius: 8px 8px 0px 0px;
  overflow: hidden;
  /* flex-grow: 1; */
}

img {
  background-size: cover;
  object-fit: contain;
}

.profileMain
  .achievementTag
  .achievementTilesBox
  .achievementTile
  .achievementTileImage
  img {
  width: 100%;
  height: 100%;
  background-size: cover;
  object-fit: cover;
}

.profileMain
  .achievementTag
  .achievementTilesBox
  .achievementTile
  .achievementTileImage
  .fileIconHandle {
  object-fit: contain;
}

.profileMain
  .achievementTag
  .achievementTilesBox
  .achievementTile
  .achievementTileTitile {
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  margin: auto 0;
  padding: 3.5px 5px;
  background: #ffffff;
  border-radius: 0px 0px 8px 8px;
  overflow: hidden;
  text-align: center;
  text-transform: capitalize;
  color: #000000;
  font-weight: 400;
  font-size: 11.5px;
  line-height: 14px;
}

.profileMain .catalogInfo .catalogs .catalogsImages .catalogsImage::after,
.profileMain .achievementTag .achievementTilesBox .achievementTile::after,
.profileMain .reviewList .reviewCard::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  opacity: 0;
  transition: all 0.25s ease;
  border-radius: 6px;
}

.profileMain .catalogInfo .catalogs .catalogsImages .catalogsImage:hover::after,
.profileMain .achievementTag .achievementTilesBox .achievementTile:hover::after,
.profileMain .reviewList .reviewCard:hover::after {
  opacity: 1;
}

.profileMain .catalogInfo .catalogs .catalogsImages .catalogsImage:hover,
.profileMain .achievementTag .achievementTilesBox .achievementTile:hover {
  transform: scale(0.98);
}

.profileMain .achievementTag .achievementTilesBox .achievementTile .pdfDocs,
.profileMain .achievementTag .achievementTilesBox .achievementTile .pdf-page {
  display: block;
  width: 100%;
  height: 100%;
}

/* reviewsbox */
.profileMain .reviewList {
  display: flex;
  flex-direction: row;
  padding: 0px 20px 12px;
  gap: 12px;
  width: 100%;
  position: relative;
}

.profileMain .reviewList .reviewcardLine {
  width: 100%;
  display: flex;
  gap: 5px;
  padding: 0px 5px 10px;
  overflow-y: auto;
}

.profileMain .reviewList .reviewCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  gap: 6px;
  min-width: 180px;
  width: 180px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  cursor: pointer;
  position: relative;
}

.profileMain .reviewList .reviewCard .cardHeaderLine {
  display: flex;
  flex-direction: column;
}

.profileMain .reviewList .reviewCard .cardHeaderLine .cardFirstLine {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;
}

.profileMain
  .reviewList
  .reviewCard
  .cardHeaderLine
  .cardFirstLine
  .userProfilePic {
  width: 28px;
  height: 28px;
  background-color: #d2d2d2;
  border-radius: 50%;
  overflow: hidden;
}

.profileMain
  .reviewList
  .reviewCard
  .cardHeaderLine
  .cardFirstLine
  .userProfilePic
  img {
  width: 100%;
  height: 100%;
  background-size: cover;
  object-fit: cover;
}

.profileMain .reviewList .reviewCard .cardHeaderLine .cardFirstLine .userName {
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  text-transform: capitalize;
  color: #1b1b1b;
}

.profileMain .reviewList .reviewCard .cardHeaderLine .ratingStars {
  display: flex;
  flex-direction: row;
  gap: 2px;
}

.profileMain .reviewList .reviewCard .cardHeaderLine .ratingStars span {
  width: 12px;
  height: 12px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profileMain .reviewList .reviewCard .cardHeaderLine .ratingStars span svg {
  width: 12px;
  height: 12px;
}

.profileMain .reviewList .reviewCard .reviewContent {
  width: 100%;
  height: 100%;
  background: #ffffff;
  padding: 6px;
  overflow: hidden;
}
.profileMain .reviewList .reviewCard .reviewContent p {
  width: 100%;
  height: 100%;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  /* text-transform: capitalize;
  word-break: break-all; */
}

.profileMain .reviewList .seeMoreTag {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  min-width: 55px;
  cursor: pointer;
  height: 100%;
  background-color: var(--component-bg-color);
}

.profileMain .reviewList .seeMoreTag .chevronIcon,
.profileMain .reviewList .seeMoreTag .btntext {
  width: 100%;
  text-align: center;
}

.profileMain .reviewList .seeMoreTag .btntext {
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  text-transform: capitalize;
  color: #373737;
}

.profileMain .achievementTag .react-pdf__Document,
.profileMain .achievementTag .react-pdf__Page,
.profileMain .achievementTag .react-pdf__Page .pdf-page {
  width: 100%;
  height: 100%;
}

.profileMain .achievementTag .react-pdf__Page__textContent,
.profileMain .achievementTag .react-pdf__Page__textContent.textLayer,
.profileMain .achievementTag .react-pdf__Page__annotations,
.profileMain .achievementTag .react-pdf__Page__annotations.annotationLayer {
  display: none !important;
}

.profileMain .achievementTag .react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

/* For responsive */
@media only screen and (max-width: 1050px) {
  .profileMain .aboutmeInfo .aboutInfoPara textarea,
  .profileMain .brokerServicesDetails .brokerServicesPara textarea {
    height: 68px;
  }
}

@media only screen and (max-width: 940px) {
  .profileMain .activeStatusTag {
    flex-wrap: wrap;
    height: auto;
    gap: 2px;
  }
  .profileMain .activeStatusTag .onactive,
  .profileMain .activeStatusTag .houseActive {
    width: 100%;
    gap: 0;
    justify-content: space-between;
  }
  .profileMain .activeStatusTag .line {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .profileMain {
    gap: 12px;
  }
  .profileMain .header {
    padding: 0;
  }
  .profileMain .availableOfferTag p {
    font-size: 14px;
    width: 80%;
  }
  .profileMain .profilePersonalInfo .profileBlock .profilePic {
    min-width: 125px;
    width: 125px;
    height: 125px;
  }
  .profileMain .profilePersonalInfo .profileBlock .profilePic svg {
    right: 4px;
  }
  .profileMain .catalogInfo .catalogs .catalogsImages .catalogsImage {
    min-width: 90px;
    width: 90px;
    height: 90px;
  }
  .profileMain .catalogInfo .catalogs .catalogsImages .catalogsImage img {
    width: 90px;
  }
  .profileMain .catalogInfo .catalogs .moveLeft,
  .profileMain .catalogInfo .catalogs .moveRight {
    width: 35px;
  }
  .profileMain .catalogInfo .catalogs .moveLeft svg,
  .profileMain .catalogInfo .catalogs .moveRight svg {
    width: 13px;
  }
  .profileMain .aboutmeInfo .aboutInfoPara textarea,
  .profileMain .brokerServicesDetails .brokerServicesPara textarea {
    height: 78px;
  }
  .profileMain .achievementTag .achievementTilesBox .achievementTile {
    width: 110px;
    min-width: 110px;
    height: 110px;
  }
  .profileMain
    .achievementTag
    .achievementTilesBox
    .achievementTile
    .achievementTileImage {
    height: 78px;
    min-height: 78px;
  }
  .profileMain
    .achievementTag
    .achievementTilesBox
    .achievementTile
    .achievementTileTitile {
    padding: 2px 10px;
    line-height: 13px;
  }
  .profileMain
    .achievementTag
    .achievementTilesBox
    .achievementTile
    .achievementTileTitile
    p {
    width: 100%;
  }
  .profileMain .reviewList .reviewCard .cardHeaderLine .cardFirstLine {
    gap: 6px;
  }
  .profileMain
    .reviewList
    .reviewCard
    .cardHeaderLine
    .cardFirstLine
    .userProfilePic {
    width: 24px;
    height: 24px;
  }
  .profileMain
    .reviewList
    .reviewCard
    .cardHeaderLine
    .cardFirstLine
    .userName {
    font-size: 13px;
    line-height: 17px;
  }
  .profileMain .reviewList {
    gap: 8px;
    padding: 0 10px 5px;
  }
  .profileMain .reviewList .reviewCard {
    min-width: 150px;
  }
  .profileMain .reviewList .reviewCard .reviewContent p {
    font-size: 9px;
    line-height: 11px;
    padding: 0;
  }
  .profileMain .reviewList .seeMoreTag {
    min-width: 45px;
  }
  /* .profileMain .reviewList .seeMoreTag .btntext {
    font-size: 9px;
    line-height: 12px;
  } */
  .profileMain .profilePersonalInfo .personalInfo .headerLine span,
  .profileMain .socialLinksTag .headerInfoLine .heading span,
  .profileMain .aboutmeInfo .headerInfoLine .heading span,
  .profileMain .catalogInfo .headerInfoLine .heading span,
  .profileMain .brokerServicesDetails .headerInfoLine .heading span,
  .profileMain .achievementTag .headerInfoLine .heading span {
    font-size: 15px;
  }
}

@media only screen and (max-width: 540px) {
  .profileMain .activeStatusTag .onactive span,
  .profileMain .activeStatusTag .houseActive span {
    font-size: 14px;
  }
  .profileMain .socialLinksTag,
  .profileMain .aboutmeInfo,
  .profileMain .catalogInfo,
  .profileMain .brokerServicesDetails,
  .profileMain .achievementTag,
  .profileMain .reviewsTag {
    gap: 10px;
  }
  .profileMain .profilePersonalInfo .personalInfo {
    gap: 10px;
  }
  .profileMain .availableOfferTag {
    justify-content: flex-start;
  }
  .profileMain .availableOfferTag textarea {
    width: 80%;
  }
  .profileMain .availableOfferTag p {
    font-size: 13.4px;
  }
  .profileMain .availableOfferTag .editButton {
    top: 3px;
    right: 5px;
  }
  .profileMain .activeStatusTag .onactive .selectMode span,
  .profileMain .activeStatusTag .houseActive .selectMode span {
    font-size: 14px;
  }
  .profileMain .activeStatusTag {
    padding: 8px 12px;
  }
  /* profile & personelInfo set */
  .profileMain .profilePersonalInfo .profileBlock .profilePic {
    min-width: 90px;
    width: 90px;
    height: 90px;
  }
  .profileMain .profilePersonalInfo .profileBlock .profilePic svg {
    width: 22px;
    right: 0;
    bottom: 0;
  }
  .profileMain .profilePersonalInfo .personalInfo .firstLinePersonalInfo,
  .profileMain .profilePersonalInfo .personalInfo .secondLinePersonalInfo,
  .profileMain .socialLinksTag .socialLinks {
    padding: 10px 12px;
    gap: 2px;
  }
  .profileMain .profilePersonalInfo .personalInfo .firstLinePersonalInfo .row,
  .profileMain .profilePersonalInfo .personalInfo .secondLinePersonalInfo .row,
  .profileMain .socialLinksTag .socialLinks .row {
    flex-wrap: wrap;
    width: 100%;
  }
  .profileMain
    .profilePersonalInfo
    .personalInfo
    .firstLinePersonalInfo
    .row
    span,
  .profileMain
    .profilePersonalInfo
    .personalInfo
    .secondLinePersonalInfo
    .row
    span,
  .profileMain .socialLinksTag .socialLinks .row span {
    width: 100%;
    font-size: 16px;
  }
  .profileMain .profilePersonalInfo .personalInfo .firstLinePersonalInfo .row p,
  .profileMain
    .profilePersonalInfo
    .personalInfo
    .secondLinePersonalInfo
    .row
    p,
  .profileMain .socialLinksTag .socialLinks .row p {
    width: 100%;
  }
  /* .profileMain .profilePersonalInfo .personalInfo .headerLine span,
  .profileMain .socialLinksTag .headerLine span,
  .profileMain .aboutmeInfo .headerInfoLine .heading span,
  .profileMain .catalogInfo .headerInfoLine .heading span,
  .profileMain .brokerServicesDetails .headerInfoLine .heading span,
  .profileMain .achievementTag .headerInfoLine .heading span,
  .profileMain .reviewsTag .headerInfoLine .heading span {
    font-size: 17px;
  } */
  .profileMain .aboutmeInfo .headerInfoLine .heading .infoIcon svg,
  .profileMain .catalogInfo .headerInfoLine .heading .catalogIcon svg,
  .profileMain .brokerServicesDetails .headerInfoLine .heading .homeIcon svg,
  .profileMain .achievementTag .headerInfoLine .heading .achievementIcon svg,
  .profileMain .reviewsTag .headerInfoLine .headin .starIcon svg {
    width: 18px;
    height: 18px;
  }
  .profileMain .socialLinksTag .headerInfoLine .editButton svg,
  .profileMain .aboutmeInfo .headerInfoLine .editButton svg,
  .profileMain .catalogInfo .headerInfoLine .editButton svg,
  .profileMain .brokerServicesDetails .headerInfoLine .editButton svg,
  .profileMain .achievementTag .headerInfoLine .editButton svg {
    width: 18x;
    height: 18px;
  }
  .profileMain .aboutmeInfo .aboutInfoPara p,
  .profileMain .brokerServicesDetails .brokerServicesPara p {
    font-size: 14px;
  }
  .profileMain .aboutmeInfo .aboutInfoPara textarea,
  .profileMain .brokerServicesDetails .brokerServicesPara textarea {
    height: 88px;
  }
  .profileMain .socialLinksTag .socialLinks .row .invalidInputIcon {
    width: 13px;
    height: 13px;
    left: unset;
    right: 25px;
  }

  .profileMain .socialLinksTag .socialLinks .row .invalidInputIcon svg {
    width: 13px;
    height: 13px;
  }

  .profileMain .socialLinksTag .socialLinks .row .invalidInputIcon {
    top: 4.5px;
  }
}

@media only screen and (max-width: 440px) {
  .profileMain .aboutmeInfo .aboutInfoPara textarea,
  .profileMain .brokerServicesDetails .brokerServicesPara textarea {
    height: 110px;
  }
  .profileMain
    .profilePersonalInfo
    .personalInfo
    .firstLinePersonalInfo
    .row
    span,
  .profileMain
    .profilePersonalInfo
    .personalInfo
    .secondLinePersonalInfo
    .row
    span,
  .profileMain .socialLinksTag .socialLinks .row span {
    font-size: 14px;
  }
  .profileMain .profilePersonalInfo .personalInfo .firstLinePersonalInfo .row p,
  .profileMain
    .profilePersonalInfo
    .personalInfo
    .secondLinePersonalInfo
    .row
    p,
  .profileMain .socialLinksTag .socialLinks .row p {
    font-size: 13px;
  }
  .profileMain
    .profilePersonalInfo
    .personalInfo
    .firstLinePersonalInfo
    .row
    input,
  .profileMain
    .profilePersonalInfo
    .personalInfo
    .secondLinePersonalInfo
    .row
    input,
  .profileMain .socialLinksTag .socialLinks .row input {
    font-size: 12.5px;
  }
  .profileMain {
    gap: 8px;
  }
  .profileMain .profilePersonalInfo .personalInfo {
    gap: 10px;
  }
  .profileMain .socialLinksTag,
  .profileMain .aboutmeInfo,
  .profileMain .catalogInfo,
  .profileMain .brokerServicesDetails,
  .profileMain .achievementTag {
    padding: 7px 0px;
    gap: 10px;
  }
  .profileMain .profilePersonalInfo .personalInfo .headerLine span,
  .profileMain .socialLinksTag .headerInfoLine .heading span,
  .profileMain .aboutmeInfo .headerInfoLine .heading span,
  .profileMain .catalogInfo .headerInfoLine .heading span,
  .profileMain .brokerServicesDetails .headerInfoLine .heading span,
  .profileMain .achievementTag .headerInfoLine .heading span {
    font-size: 14px;
  }
  .profileMain .aboutmeInfo .aboutInfoPara p,
  .profileMain .brokerServicesDetails .brokerServicesPara p {
    font-size: 13px;
  }
}

@media only screen and (max-width: 300px) {
  .profileMain .activeStatusTag .onactive,
  .profileMain .activeStatusTag .houseActive {
    flex-wrap: wrap;
    justify-content: center;
  }
  p {
    word-break: break-all;
  }
}
