.propertyMain {
  width: 100%;
}

.propertyMain .header {
  width: 100%;
  position: sticky;
  top: 0;
  background-color: var(--component-bg-color);
  z-index: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  margin-bottom: 12px;
}

.propertyMain .header .moveback {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 3px;
  border-radius: 5px;
  cursor: pointer;
}

.propertyMain .header .moveback:hover {
  outline: 2px solid var(--bg-plus-txt-color);
}

.propertyMain .searchBarBox {
  margin: 10px auto 0;
}

.propertyMain .header span {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 32px;
  text-align: center;
  text-transform: capitalize;
  color: var(--bg-plus-txt-color);
  margin: auto;
}

.propertyMain .btnLine {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 90%;
  margin: 10px auto;
}

.propertyMain .btnLine .btnAdd {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 28px;
  background: var(--bg-plus-txt-color);
  color: var(--txt-color);
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.propertyMain .btn .btnAdd:hover {
  transform: scale(1.04);
}

.propertyMain .propertyCardsTile {
  display: flex;
  align-items: center;
  width: 90%;
  margin: auto;
  gap: 10px;
  flex-wrap: wrap;
  padding: 0 0 15px 8px;
}

.propertyMain .propertyCardsTile .propertyCard {
  /* background: #767676; */
  background-size: cover;
  /* background-position: center; */
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.75px solid #8d8d8d;
  padding: 12px 8px;
  width: 160px;
  height: 145px;
  cursor: pointer;
  position: relative;
  transition: all 0.15s;
}

.propertyMain .propertyCardsTile .propertyCard:hover {
  transform: scale(0.97);
}

.propertyMain .propertyCardsTile .loaderst {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.propertyMain .propertyCardsTile .loaderst .circle {
  position: absolute;
  width: 40px;
  height: 40px;
  border: 3px solid #ccc;
  border-radius: 50%;
  border-top-color: var(--bg-plus-txt-color);
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.propertyMain .propertyCardsTile .propertyCard .detailsTag {
  width: 100%;
  height: 100%;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.propertyMain .propertyCardsTile .propertyCard .detailsTag div {
  width: 100%;
}

.propertyMain .propertyCardsTile .propertyCard .detailsTag .reqInfo,
.propertyMain .propertyCardsTile .propertyCard .detailsTag .info {
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.8px;
}

@media only screen and (max-width: 1000px) {
  .propertyMain .propertyCardsTile .propertyCard {
    width: 155px;
    height: 140px;
  }
  .propertyMain .propertyCardsTile .propertyCard .detailsTag .reqInfo,
  .propertyMain .propertyCardsTile .propertyCard .detailsTag .info {
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.6px;
  }
}

@media only screen and (max-width: 768px) {
  .propertyMain .header {
    padding: 8px 15px;
    top: 38px;
  }
  .propertyMain .pstky {
    position: sticky;
    top: 50px;
    z-index: 710;
    padding-bottom: 6px;
    background-color: var(--component-bg-color);
  }
  .propertyMain .header .moveback svg {
    width: 15px;
    height: 15px;
  }
  .propertyMain .header span {
    font-size: 22px;
    line-height: normal;
  }
  .propertyMain .propertyCardsTile .propertyCard {
    width: 140px;
    height: 130px;
  }
  .propertyMain .btnLine .btnAdd {
    width: 64px;
    height: 26px;
  }
}

@media only screen and (max-width: 540px) {
  .propertyMain .header {
    margin-bottom: 0;
  }
  .propertyMain .header span {
    font-size: 19px;
  }
  .propertyMain .propertyCardsTile {
    padding: 0 0 25px 0;
  }
  .propertyMain .PropertyDetailSearch {
    margin: 5px auto 7px;
  }
  .propertyMain .btnLine {
    margin: 7px auto;
  }
  .propertyMain .btnLine .btnAdd {
    width: 60px;
    height: 25px;
    font-size: 12px;
  }
  .propertyMain .propertyCardsTile .propertyCard .detailsTag .reqInfo,
  .propertyMain .propertyCardsTile .propertyCard .detailsTag .info {
    font-weight: 600;
    font-size: 8.3px;
    line-height: 10px;
    letter-spacing: 0.4px;
  }
}

@media only screen and (max-width: 440px) {
  .propertyMain .propertyCardsTile {
    gap: 10px;
    width: 95%;
    justify-content: space-evenly;
  }
  .propertyMain .propertyCardsTile .propertyCard {
    width: 112px;
    height: 112px;
  }
}
