.navigate {
  width: 210px;
  /* overflow-y: auto; */
  margin-bottom: 50px;
}

.navigate .artboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
}

.navigate .artboard .artboardPic {
  width: 45px;
  height: 45px;
  margin: 8px 8px 8px 0;
}

.navigate .artboard .artboardPic img {
  width: 100%;
  background-size: cover;
  /* object-fit: cover; */
}

.navigate .artboard span {
  font-size: 0.75rem;
  color: #000000;
  line-height: normal;
}

.navigate .firstLine {
  margin: 0.9rem 0;
}

.navigate .secondLine {
  margin: 0.8rem 0;
}

.navigate .firstLine ul,
.navigate .secondLine ul {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4px;
}

.navigate .firstLine ul li,
.navigate .secondLine ul li {
  list-style: none;
  width: 166px;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 7px;
  gap: 15px;
  color: var(--bg-plus-txt-color);
  cursor: pointer;
  font-size: 12px;
}

.navigate .firstLine ul li.active,
.navigate .secondLine ul li.active {
  background-color: var(--bg-plus-txt-color);
  color: var(--txt-color);
}

.navigate .firstLine ul li.active svg path,
.navigate .secondLine ul li.active svg path {
  fill: var(--txt-color);
}

.navigate .firstLine ul li.false,
.navigate .secondLine ul li.false {
  background: none;
}

.navigate .firstLine ul li svg,
.navigate .secondLine ul li svg {
  margin-left: 10px;
}

.navigate .firstLine ul li svg path,
.navigate .secondLine ul li svg path {
  fill: #4668a8;
}

.navigate .line {
  width: 80%;
  background-color: var(--bg-plus-txt-color);
  height: 1px;
  margin: auto;
  opacity: 0.4;
}

.navigate .firstLine ul li.false:hover,
.navigate .secondLine ul li.false:hover {
  background-color: #c0c0c0;
  box-shadow: 0px 4px 6px rgba(226, 226, 226, 0.5);
}

.navigate .firstLine ul li svg,
.navigate .secondLine ul li svg {
  width: 20px;
  height: 20px;
}

.sidebar .profileBlock {
  width: 180px;
  min-height: 104px;
  background: #c3c3c3;
  border-radius: 10px;
  position: relative;
  /* left: calc(50% - 180px / 2); */
  bottom: 15px;
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  align-self: center;
}
.sidebar .profileBlock .profilePic {
  position: absolute;
  width: 50px;
  height: 50px;
  left: calc(50% - 50px / 2);
  top: -25px;
  background-color: #dcdcdc;
  border-radius: 50%;
}

.sidebar .profileBlock .profilePic img {
  width: 100%;
  height: 100%;
  background-size: cover;
  object-fit: cover;
  background-position: center;
  border-radius: 50%;
}
.sidebar .profileBlock .userDetail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 9px;
  margin-bottom: 12px;
}

.sidebar .profileBlock .userName {
  width: 112px;
  /* height: 20px; */
  font-family: "Outfit";
  font-style: normal;
  /* font-weight: 600; */
  font-size: 12.5px;
  text-align: center;
  text-transform: capitalize;
  color: #000000;
  margin: 25px 0 0;
  letter-spacing: 0.1px;
  /* line-break: anywhere; */
}

.sidebar .profileBlock .socialLinks {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0px;
  gap: 5px;
  width: 120px;
}

.sidebar .profileBlock .socialLinks .link {
  width: 20px;
  height: 20px;
  background: var(--bg-dark-color);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
  border-radius: 3.8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.sidebar .profileBlock .socialLinks .link:hover {
  transform: scale(1.15);
}

.sidebar .profileBlock .socialLinks .link a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar .profileBlock .socialLinks .link.Youtube:last-child {
  background-color: #ffffff;
}

.sidebar .profileBlock .socialLinks .link.Youtube:last-child svg {
  fill: var(--bg-dark-color);
  width: 24px;
}

@media only screen and (max-width: 540px) {
  .sidebar .profileBlock {
    /* bottom: unset; */
    margin-top: unset;
  }
}

@media only screen and (max-width: 440px) {
  .navigate {
    width: 100%;
    padding: 0 18px;
  }
  .navigate .fSearch {
    width: 100%;
  }
  .navigate .firstLine ul li,
  .navigate .secondLine ul li {
    width: 100%;
  }
}
