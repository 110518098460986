.overlayStyle {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow-y: hidden;
  background-color: var(--component-bg-color);
}

.overlayContentStyle {
  background-color: var(--component-bg-color);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 22px;
}

.overlayContentStyle .headerLine {
  width: 96%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--component-bg-color);
  padding: 9.5px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 auto 4px;
  position: sticky;
  top: 0px;
  z-index: 25;
}

.overlayContentStyle .headerLine .moveback {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 3px;
  border-radius: 5px;
  width: 22px;
  height: 22px;
  position: absolute;
}

.overlayContentStyle .headerLine .moveback:hover {
  outline: 2px solid var(--bg-plus-txt-color);
}

.overlayContentStyle .headerLine span {
  display: block;
  width: auto;
  height: auto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: normal;
  text-align: center;
  text-transform: capitalize;
  color: var(--bg-plus-txt-color);
  margin: auto;
}

.overlayContentStyle .contentBox {
  width: 96%;
  height: 100%;
  margin: 0 auto;
  overflow-y: auto;
  word-spacing: 2px;
  padding: 0px 15px;
}

.overlayContentStyle .react-pdf__Page__textContent,
.overlayContentStyle .react-pdf__Page__textContent.textLayer,
.overlayContentStyle .react-pdf__Page__annotations,
.overlayContentStyle .react-pdf__Page__annotations.annotationLayer {
  display: none !important;
}

.overlayContentStyle .react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}

@media only screen and (max-width: 768px) {
  .overlayContentStyle {
    padding: 0;
  }
  .overlayContentStyle .headerLine {
    width: 100%;
  }
  .overlayContentStyle .contentBox {
    width: 100%;
    padding: 0px 10px;
  }
}
