/* ReminderModal.css */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.reminder-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 20px;
  width: 392px;
  height: 300px;
  background: #ffffff;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  z-index: 1000;
  pointer-events: visible;
}

.reminder-modal .closeReminderBox {
  position: absolute;
  top: 11px;
  right: 15px;
  width: 22px;
  height: 22px;
  color: var(--bg-plus-txt-color);
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  border-radius: 3px;
  border: 1px solid var(--bg-plus-txt-color);
}

.reminder-modal .closeReminderBox svg {
  width: 22px;
  height: 22px;
}

.reminder-modal .closeReminderBox:hover {
  outline: 2px solid var(--bg-plus-txt-color);
}

.reminder-modal .reminderInputGroup {
  width: 100%;
}

.reminder-modal label {
  display: block;
  margin-bottom: 5px;
}

.reminder-modal input {
  width: 100%;
  padding: 10px;
  background-color: #f3f3f3;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.11);
}

.reminder-modal .txtarea {
  width: 343px;
  height: auto;
  /* border: 1px solid #4668a8; */
  border: 1px solid var(--bg-plus-txt-color);
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.11));
  border-radius: 10px;
  font-size: 12px;
  color: var(--bg-plus-txt-color);
  padding: 8px;
}

.reminder-modal textarea {
  width: 100%;
  height: 75px;
  font-weight: 500;
  line-height: 15px;
  text-transform: capitalize;
  padding: 0 8px 8px 8px;
  background: none;
  resize: none;
  border: none;
  outline: none;
}

.reminder-modal button {
  background-color: var(--bg-plus-txt-color);
  color: var(--txt-color);
  padding: 5px 20px;
  border-radius: 5px;
  opacity: 0.9;
  border: none;
}

.reminder-modal button:hover {
  opacity: 1;
}

@media only screen and (max-width: 440px) {
  .reminder-modal .txtarea {
    width: 100%;
  }
}
