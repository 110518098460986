/* catalogSharedView page layout */
.sharedViewOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 6px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.2);
  z-index: 11111;
}

.catalogSharedView {
  width: 60rem;
  max-width: 60rem;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 12px; */
  overflow: hidden;
  /* overflow-y: auto; */
  background-color: var(--component-bg-color);
  z-index: 11111;
}

.catalogSharedView .headerLine {
  width: 100%;
  min-height: 40px;
  background-color: var(--component-bg-color);
  display: flex;
  align-items: center;
  padding: 7px 10px;
  box-shadow: 0 0 5px rgb(0, 0, 0, 0.3);
  /* margin-bottom: 6px; */
  position: sticky;
  top: 0;
  z-index: 10;
}

.catalogSharedView .headerLine .movebackc {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-radius: 5px;
  width: 18px;
  height: 18px;
  position: absolute;
}

.catalogSharedView .headerLine .movebackc:hover {
  outline: 2px solid var(--bg-plus-txt-color);
}

.catalogSharedView .headerLine span {
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  text-transform: capitalize;
  color: var(--bg-plus-txt-color);
  margin: auto;
}

.catalogSharedView .ctldCntBox {
  width: 100%;
  padding: 10px 0 20px 0;
  overflow-y: auto;
}

.catalogSharedView .titleSection,
.catalogSharedView .descriptionSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 20px;
  gap: 4px;
  width: 100%;
}

.catalogSharedView .titleSection label,
.catalogSharedView .descriptionSection label {
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  text-transform: capitalize;
  color: var(--bg-plus-txt-color);
}

.catalogSharedView .titleSection .title {
  width: 100%;
  height: auto;
  min-height: 28px;
  background: #ffffff;
  border: 1px solid var(--bg-plus-txt-color);
  border-radius: 5px;
  font-weight: 400;
  font-size: 13px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  color: #797979;
  white-space: break-spaces;
}

/* Description section */
.catalogSharedView .descriptionSection .description {
  width: 100%;
  min-height: 120px;
  background: #ffffff;
  border: 1px solid var(--bg-plus-txt-color);
  border-radius: 5px;
  font-weight: 400;
  font-size: 13px;
  padding: 5px 7px;
  color: #797979;
  box-shadow: none;
  overflow: hidden;
  overflow-y: auto;
  scroll-snap-type: none;
  white-space: break-spaces;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.catalogSharedView .descriptionSection .description::-webkit-scrollbar {
  display: none;
}

/* Images section */
.catalogSharedView .imagesSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  padding: 10px;
  gap: 10px;
  width: 100%;
  height: auto;
}

.catalogSharedView .flfimgbt {
  width: 100%;
  display: flex;
  align-items: center;
}

.catalogSharedView .imagesSection .catalogImage {
  width: 80px;
  height: 80px;
  background: #e6e6e6;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.catalogSharedView .imagesSection .catalogImage::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.catalogSharedView .imagesSection .catalogImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.catalogSharedView .image-slider {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1250;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 2000;
}

.catalogSharedView .image-gallery {
  max-width: 70% !important;
  margin: 0 auto !important;
}

/* Style for individual gallery items */
.catalogSharedView .image-gallery-content img {
  max-width: 100% !important;
}

.catalogSharedView .image-gallery-slide .image-gallery-image {
  object-fit: cover !important;
  height: 100% !important;
}

.catalogSharedView .image-gallery-thumbnail .image-gallery-thumbnail-image {
  height: 60px !important;
}

.catalogSharedView .close-slider {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  background-color: var(--bg-plus-txt-color);
  color: var(--txt-color);
  font-size: 13px;
  border-radius: 5px;
  padding: 5px 12px;
}

/* for responsive */
@media only screen and (max-width: 1000px) {
  .catalogSharedView {
    width: 100%;
    max-width: unset;
    margin: 0;
  }
}

@media only screen and (max-width: 768px) {
  .catalogSharedView .headerLine {
    position: sticky;
    top: 0;
    z-index: 1250;
    padding: 9.5px 10px;
    margin-bottom: 5px;
    background-color: var(--component-bg-color);
    box-shadow: 0 0 5px rgb(0, 0, 0, 0.3);
  }
  .catalogSharedView .titleSection,
  .catalogSharedView .descriptionSection {
    padding: 0px 14px;
  }
  .catalogSharedView .titleSection label,
  .catalogSharedView .descriptionSection label {
    line-height: 20px;
  }
  .catalogSharedView .descriptionSection .description {
    padding: 8px;
  }
  .catalogSharedView .image-gallery {
    max-width: 100% !important;
  }
  .catalogSharedView .image-gallery-slide {
    aspect-ratio: 4/3;
  }
  .catalogSharedView .image-gallery-slide .image-gallery-image {
    height: 100% !important;
  }
}

@media only screen and (max-width: 540px) {
  .catalogSharedView .titleSection .title {
    font-size: 12px;
  }
  .catalogSharedView .descriptionSection .description {
    font-size: 12px;
    padding: 6px;
  }
  .catalogSharedView .titleSection label,
  .catalogSharedView .descriptionSection label {
    font-size: 14px;
    line-height: normal;
  }
}
