.addUserOverlay {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 15px 25px;
  /* position: absolute;
  top: 0;
  left: 0; */
  width: 100%;
  height: 100vh;
  background-color: var(--component-bg-color);
  overflow-y: auto;
}

.addUserOverlay .headerLine {
  width: 96%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--component-bg-color);
  padding: 9.5px 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 auto 10px;
  position: sticky;
  top: 0px;
  z-index: 25;
}

.addUserOverlay .headerLine .moveback {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 3px;
  border-radius: 5px;
  width: 22px;
  height: 22px;
  position: absolute;
}

.addUserOverlay .headerLine .moveback:hover {
  outline: 2px solid var(--bg-plus-txt-color);
}

.addUserOverlay .headerLine span {
  display: block;
  width: auto;
  height: auto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: normal;
  text-align: center;
  text-transform: capitalize;
  color: var(--bg-plus-txt-color);
  margin: auto;
}
.addUserOverlay .msgforUserAboutAddUser {
  width: 90%;
  margin: 12px auto;
  text-align: center;
  font-size: 14px;
}

.addUserOverlay .rowForadd {
  max-width: 22rem;
  width: 22rem;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 0 auto 6px;
}

.addUserOverlay .rowForadd input {
  width: 100%;
  padding: 8px 14px;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.addUserOverlay .rowForadd .removeUser {
  width: auto;
  background: none;
  color: #ff0000;
  align-self: flex-end;
  margin-right: 8px;
  font-size: 12px;
}

.addUserOverlay .addmember {
  margin: 0 auto;
  padding: 5px 12px;
  font-size: 12px;
  border-radius: 6px;
  transition: 0.2s all;
  border: 2px solid var(--bg-plus-txt-color);
}

.addUserOverlay .addmember:hover {
  background: none;
  color: var(--bg-plus-txt-color);
}

@media only screen and (max-width: 768px) {
  .addUserOverlay {
    padding: 0 0 25px;
    overflow: unset;
    height: 100%;
  }
  .addUserOverlay .headerLine {
    width: 100%;
    top: 40px;
  }
  .addUserOverlay .headerLine .moveback {
    padding: 1.5px 2px;
    width: 20px;
    height: 20px;
    position: absolute;
  }
  .addUserOverlay .headerLine span {
    font-size: 15px;
  }
  .addUserOverlay .rowForadd {
    max-width: unset;
  }
  .addUserOverlay .msgforUserAboutAddUser {
    font-size: 12.5px;
  }
}

@media only screen and (max-width: 540px) {
  .addUserOverlay .msgforUserAboutAddUser {
    font-size: 12px;
  }
  .addUserOverlay .rowForadd input {
    padding: 7px 10px;
    font-size: 12px;
  }
  .addUserOverlay .addmember {
    padding: 4px 10px;
    font-size: 11.5px;
  }
}

@media only screen and (max-width: 440px) {
  .addUserOverlay .rowForadd {
    width: 100%;
    padding: 0 20px;
  }
  .addUserOverlay .rowForadd input {
    width: 100%;
    padding: 8px 10px;
    border: 1px solid #dcdcdc;
    border-radius: 6px;
  }
  .addUserOverlay .rowForadd .removeUser {
    font-size: 11px;
  }
}
