.chatTileBox {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  padding: 0 10px 10px;
  overflow-y: auto;
}

.chatTileBox::-webkit-scrollbar {
  width: 8px;
}

.chatTileBox::-webkit-scrollbar-thumb {
  background-color: #7f7f7f;
  border-radius: 6px;
}

.chatTileBox::-webkit-scrollbar-thumb:hover {
  background-color: #5e5e5e;
}

.chatTileBox .headerText {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 3px 5px;
}

.chatTileBox .headerText span {
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
}

.chatTileBox .contactList {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  /* gap: 5px; */
}

.chatTileBox .contactList .contactBox {
  width: 100%;
  height: 100%;
  min-height: 50px;
  height: 50px;
  /* overflow: hidden; */
  background: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 0 6px 6px;
  gap: 8px;
  cursor: pointer;
  border-bottom: 1px solid #dcdcdc;
  /* border-radius: 6px; */
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25); */
  position: relative;
  overflow: hidden;
}

.chatTileBox .contactList .contactBox:hover {
  background: #d4d4d4;
}

.chatTileBox .contactList .contactBox .profile-pic {
  min-width: 35px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #dcdcdc;
}

.chatTileBox .contactList .contactBox .profile-pic img {
  width: 100%;
  height: 100%;
  background-size: cover;
  object-fit: cover;
  /* border-radius: 50%; */
}

.chatTileBox .contactList .contactBox .contact-details {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  /* gap: 5px; */
}

.chatTileBox .contactList .contactBox .contact-details span {
  font-weight: 600;
  font-size: 13px;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #292929;
  white-space: nowrap;
}

.chatTileBox .contactList .contactBox label {
  font-weight: 400;
  font-size: 11.5px;
  letter-spacing: 0.3px;
  color: #797979;
  white-space: nowrap;
}

.chatTileBox .contactList .contactBox .msgTime {
  width: 45px;
  min-width: 45px;
  text-align: right;
  font-size: 10px;
  margin-right: 2px;
}

.chatTileBox .contactList .chatTileUpperCover {
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
  background-color: #ffffff;
}

.chatTileBox .contactList .chatTileUpperCover .chatTileMore {
  background-color: #ffffff;
  height: 100%;
  display: flex;
  align-items: center;
}
.chatTileBox .contactList .chatTileUpperCover .chatTileMore button {
  padding: 0 !important;
}

.chatTileBox .contactList .chatTileUpperCover .chatTileMore button:hover {
  background: none !important;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  box-shadow: none !important;
}

.chatTileBox .contactList .contactBox .profile-pic .initials {
  min-width: 35px;
  width: 35px;
  height: 35px;
  background-color: #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
}

.chatTileBox .contactList .contactBox .profile-pic .initials svg {
  width: 25px;
  height: 25px;
}

.chatTileBox .contactList .chatTileUpperCover:last-child {
  margin-bottom: 80px;
}

.chatTileBox .contactList .chatTileUpperCover .moreOptionBox {
  min-width: 22px;
  width: 22px;
  height: 100%;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #dcdcdc;
  position: relative;
}

.chatTileBox .contactList .chatTileUpperCover .moreOptionInner {
  width: 90%;
  padding: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chatTileBox .contactList .contactBox .moreOption svg path {
  fill: #1e1e1e;
}

.chatTileBox .contactList .chatTileUpperCover .moreOptionsBlock {
  position: absolute;
  background-color: #ffffff;
  bottom: -10px;
  right: 0;
  z-index: 10;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
}

.chatTileBox
  .contactList
  .chatTileUpperCover
  .moreOptionsBlock
  .chatListmpItem {
  display: block;
  padding: 4.5px 12px;
  font-size: 14px;
  cursor: pointer;
}

.chatTileBox
  .contactList
  .chatTileUpperCover
  .moreOptionsBlock
  .chatListmpItem:hover {
  background: #d4d4d4;
}

@media only screen and (max-width: 768px) {
  .chatTileBox {
    height: 90%;
    padding: 0 10px 35px;
  }
  .chatTileBox .contactList {
    padding-bottom: 20px;
  }
}
